<template>
  <div>
    <v-badge
      v-if="showFilterBtn" bordered :value="anyFilterSelected ? 1 : 0"
      color="green" icon="filter_list"
      overlap
    >
      <v-btn
        small fab color="black"
        class="inside-drawer" @click="toggleSideDrawer"
      >
        <v-icon color="white" class="inside-drawer">
          tune
        </v-icon>
      </v-btn>
    </v-badge>
    <v-navigation-drawer
      v-model="drawer"
      mobile-breakpoint="0"
      color="#F4F6F8"
      width="300"
      :left="isRtl"
      :right="!isRtl"
      fixed
    >
      <v-row
        v-click-outside="onClickOutside" justify="start" align="start"
        class="mt-5"
      >
        <v-col v-if="departmentActive" cols="12" class="py-0">
          <account-department-select
            v-model="filters.accountDepartmentIds" :api-key="apiKey" :disabled="disabled"
            :menu-props="{ contentClass: 'inside-drawer' }" add-empty-department multiple
          />
        </v-col>
        <v-col v-if="shiftActive" cols="12" class="py-0">
          <account-job-shift-select
            v-model="filters.shifts" :multiple="true"
            :menu-props="{ contentClass: 'inside-drawer' }"
            :disabled="disabled"
          />
        </v-col>
        <v-col v-if="jobsActive" cols="12" class="py-0">
          <job-selection-search
            v-model="filters.accountJobIds" :disabled="disabled" :multiple="true"
            :api-key="apiKey"
            :enabled-department-ids="enabledDepartmentIds" :menu-props="{ contentClass: 'inside-drawer' }"
            :label="$t('job_op_schedule.choose_job')"
          />
        </v-col>
        <v-col cols="12" class="py-0">
          <v-select
            v-if="statusFilterActive"
            v-model="filters.status" clearable :items="statusOptions"
            :menu-props="{ contentClass: 'inside-drawer' }" :disabled="disabled"
            :label="$t('account_operations.status')"
          >
            <template #item="{ item }">
              <v-row
                align="center" justify="start" no-gutters
                :class="{ 'rtl': isRtl }"
              >
                {{ item.text }}
              </v-row>
            </template>
          </v-select>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-select
            v-if="imageFilterActive" v-model="filters.requireImage" clearable
            :items="imageOptions"
            :menu-props="{ contentClass: 'inside-drawer' }" :disabled="disabled"
            :label="$t('account_operations.image')"
          >
            <template #item="{ item }">
              <v-row
                align="center" justify="start" no-gutters
                :class="{ 'rtl': isRtl }"
              >
                {{ item.text }}
              </v-row>
            </template>
          </v-select>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-select
            v-if="checkListFilterActive" v-model="filters.checkList" clearable
            :disabled="disabled"
            :items="checklistOptions" :menu-props="{ contentClass: 'inside-drawer' }"
            :label="$t('account_operations.check_list')"
          >
            <template #item="{ item }">
              <v-row
                align="center" justify="start" no-gutters
                :class="{ 'rtl': isRtl }"
              >
                {{ item.text }}
              </v-row>
            </template>
          </v-select>
        </v-col>
        <v-col v-if="tagFilterActive" cols="12" class="py-0">
          <operation-report-tag v-model="filters.reportTags" multiple />
        </v-col>
        <v-col v-if="crossAccountActive" cols="12" class="d-flex justify-space-between align-start py-0">
          <v-checkbox
            v-model="filters.crossAccountOperations" :disabled="disabled" class="mt-0"
            :label="$t('account_operations.cross_account')"
          />
        </v-col>
        <v-col cols="12" class="py-0">
          <v-checkbox
            v-if="myTasksFilterActive" v-model="filters.myOperations" class="mt-0"
            :label="$t('cross_account_page.my_operations')"
          />
        </v-col>
        <v-col cols="12" class="d-flex justify-space-between mb-15">
          <v-col cols="6">
            <v-btn
              block outlined color="black"
              :disabled="disabled" :loading="isLoading" @click="resetFilters"
            >
              {{ $t('reset') }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              block color="black white--text" :disabled="disabled"
              :loading="isLoading" @click="onFilterChanged"
            >
              {{ $t('show') }}
            </v-btn>
          </v-col>
        </v-col>
      </v-row>
    </v-navigation-drawer>
  </div>
</template>
<script>
import AccountJobShiftSelect from '@/components/shared/AccountJobShiftSelect.vue'
import JobSelectionSearch from '@/components/job_selection_search/JobSelectionSearch.vue'
import AccountDepartmentSelect from '@/components/shared/account_department/AccountDepartmentSelect.vue'

import { isObject, isString, hasAncestorWithClass } from '@/helpers/Utils.js'

import { EventBus, TASKS_FILTER_CHANGED } from '@/EventBus.js'

import { i18n, isRtl } from '@/i18n.js'

import Vue, { ref, computed, onDeactivated } from 'vue'

const OP_STATUS_DONE = 'done'
const OP_STATUS_NOT_REPORTED = 'not_reported'
const SESSION_FILTER_KEY = 'account-operations-filters-session'
const STRINGS_AND_BOOLEAN_KEYS = ['status', 'requireImage', 'checkList', 'crossAccountOperations', 'myOperations']
const ARRAY_KEYS = ['shifts', 'accountJobIds', 'accountDepartmentIds', 'reportTags']
const EMPTY_DEPARTMENT_ID = 'empty_department'
const activeFilters = {
  crossAccount: 'crossAccount',
  shift: 'shift',
  department: 'department',
  jobs: 'jobs',
  myTasks: 'myTasks'
}
const sessionFilterKey = (apiKey) => `${SESSION_FILTER_KEY}-${apiKey}`

const setFilterFromSession = (apiKey, filters) => {
  const filtersFromSession = getSavedFilterFromSession(apiKey)
  if (isObject(filtersFromSession) !== true) return

  if ('account_job_ids' in filtersFromSession) {
    filters.accountJobIds = filtersFromSession.account_job_ids
  }
  if ('shifts' in filtersFromSession) {
    filters.shifts = filtersFromSession.shifts
  }
  if ('require_image' in filtersFromSession) {
    filters.requireImage = filtersFromSession.require_image
  }
  if ('status' in filtersFromSession) {
    filters.status = filtersFromSession.status
  }
  if ('with_list' in filtersFromSession) {
    filters.checkList = filtersFromSession.with_list
  }
  if ('cross_account_operations' in filtersFromSession) {
    filters.crossAccountOperations = filtersFromSession.cross_account_operations
  }
  if ('account_department_ids' in filtersFromSession) {
    filters.accountDepartmentIds = filtersFromSession.account_department_ids
  }
  if ('report_tags' in filtersFromSession) {
    filters.reportTags = filtersFromSession.report_tags
  }
  if ('my_operations' in filtersFromSession) {
    filters.myOperations = filtersFromSession.my_operations
  }
}

const getSavedFilterFromSession = (apiKey) => {
  const filter = localStorage.getItem(sessionFilterKey(apiKey))
  if (isString(filter) !== true) return
  try {
    return JSON.parse(filter)
  } catch (e) {
  }
}

const addJobIdsToFilter = (newFilters, accountJobIds) => {
  const jobsSelected = Array.isArray(accountJobIds) && accountJobIds.length > 0
  if (jobsSelected !== true) return

  newFilters.account_job_ids = accountJobIds.map((jobId) => parseInt(jobId))
}

const addShiftNamesToFilter = (newFilters, shifts) => {
  const shiftsSelected = Array.isArray(shifts) && shifts.length > 0
  if (shiftsSelected !== true) return

  newFilters.shifts = shifts
}

const addRequireImageToFilter = (newFilters, image) => {
  const imageSelected = image === true || image === false
  if (imageSelected !== true) return

  newFilters.require_image = image
}

const addOperationStatusToFilter = (newFilters, status) => {
  const statusSelected = status === OP_STATUS_DONE || status === OP_STATUS_NOT_REPORTED
  if (statusSelected !== true) return

  newFilters.status = status
}

const addCheckListToFilter = (newFilters, checkList) => {
  if (checkList !== true) return

  newFilters.with_list = true
}

const addCrossAccountToFilter = (newFilters, crossAccountOperations) => {
  if (crossAccountOperations !== true) return

  newFilters.cross_account_operations = true
}

const addJobDeprtmentsToFilter = (newFilters, accountDepartmentIds) => {
  const departmentSelected = Array.isArray(accountDepartmentIds) && accountDepartmentIds.length > 0
  if (departmentSelected !== true) return
  newFilters.account_department_ids = accountDepartmentIds
    .map((department) => {
      if (department === EMPTY_DEPARTMENT_ID) return department
      return parseInt(department)
    })
    .filter((departmentId) => departmentId === EMPTY_DEPARTMENT_ID ? departmentId : Number.isInteger(departmentId))
}

const addReportTagFilter = (newFilters, reportTags) => {
  const reportTagsSelected = Array.isArray(reportTags) && reportTags.length > 0
  if (reportTagsSelected !== true) return

  newFilters.report_tags = reportTags
}

const addMyTasksFilter = (newFilters, myOperations) => {
  if (myOperations !== true) return

  newFilters.my_operations = true
}

export default {
  components: {
    'account-job-shift-select': AccountJobShiftSelect,
    'job-selection-search': JobSelectionSearch,
    'account-department-select': AccountDepartmentSelect,
    'operation-report-tag': () => import('@/components/operation_schedule_dialog/OperationReportTagField.vue')
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    apiKey: {
      type: String,
      required: true
    },
    hiddenFilters: {
      type: Array,
      required: false,
      default: () => []
    },
    dateRangeScope: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const drawer = ref(false)
    const filters = ref({})

    const emitFilterChanged = () => EventBus.emit(TASKS_FILTER_CHANGED, { filters: buildFilter() })

    const statusOptions = ref([
      { text: i18n.t('account_operations.status_done'), value: OP_STATUS_DONE },
      { text: i18n.t('account_operations.status_not_reported'), value: OP_STATUS_NOT_REPORTED }])
    const imageOptions = ref([
      { text: i18n.t('account_operations.with_image'), value: true },
      { text: i18n.t('account_operations.without_image'), value: false }])
    const checklistOptions = ref([{ text: i18n.t('account_operations.with_checklist'), value: true }])
    const closeDrawer = () => (drawer.value = false)

    const saveFilter = () => {
      const currentFilter = buildFilter()
      localStorage.setItem(sessionFilterKey(props.apiKey), JSON.stringify(currentFilter))
    }

    const buildFilter = () => {
      const filterVal = filters.value
      const newFilters = {}
      if (tagFilterActive.value === true) {
        addReportTagFilter(newFilters, filterVal.reportTags)
      }
      if (jobsActive.value === true) {
        addJobIdsToFilter(newFilters, filterVal.accountJobIds)
      }
      if (shiftActive.value === true) {
        addShiftNamesToFilter(newFilters, filterVal.shifts)
      }
      if (imageFilterActive.value === true) {
        addRequireImageToFilter(newFilters, filterVal.requireImage)
      }
      if (statusFilterActive.value === true) {
        addOperationStatusToFilter(newFilters, filterVal.status)
      }
      if (checkListFilterActive.value === true) {
        addCheckListToFilter(newFilters, filterVal.checkList)
      }
      if (crossAccountActive.value === true) {
        addCrossAccountToFilter(newFilters, filterVal.crossAccountOperations)
      }
      if (departmentActive.value === true) {
        addJobDeprtmentsToFilter(newFilters, filterVal.accountDepartmentIds)
      }
      if (myTasksFilterActive.value === true) {
        addMyTasksFilter(newFilters, filterVal.myOperations)
      }
      return newFilters
    }

    // Methods
    const toggleSideDrawer = () => (drawer.value = !drawer.value)

    const onClickOutside = (e) => {
      const hasClass = hasAncestorWithClass(e.target, 'inside-drawer')
      if (hasClass) return

      drawer.value = false
    }
    const onFilterChanged = () => {
      saveFilter()
      emitFilterChanged()
      closeDrawer()
    }

    const resetFilters = () => {
      // Vue.delete could be replaced with "delete filters.value[key]" after upgrading to vue 3
      Object.keys(filters.value).forEach((key) => Vue.delete(filters.value, key))
      saveFilter()
      emitFilterChanged()
      closeDrawer()
    }

    // Computed
    const isDayDateRange = computed(() => props.dateRangeScope === 'day')
    const showFilterBtn = computed(() => {
      if (props.isLoading === true) return false
      return true
    })

    const crossAccountActive = computed(() => props.hiddenFilters.includes(activeFilters.crossAccount) !== true && isDayDateRange.value)
    const shiftActive = computed(() => props.hiddenFilters.includes(activeFilters.shift) !== true && isDayDateRange.value)
    const departmentActive = computed(() => props.hiddenFilters.includes(activeFilters.department) !== true && isDayDateRange.value)
    const jobsActive = computed(() => props.hiddenFilters.includes(activeFilters.jobs) !== true)
    const imageFilterActive = computed(() => isDayDateRange.value)
    const myTasksFilterActive = computed(() => props.hiddenFilters.includes(activeFilters.myTasks) !== true && isDayDateRange.value)
    const statusFilterActive = computed(() => isDayDateRange.value)
    const checkListFilterActive = computed(() => isDayDateRange.value)
    const tagFilterActive = computed(() => isDayDateRange.value)
    const anyFilterSelected = computed(() => {
      const filtersValue = filters.value

      return STRINGS_AND_BOOLEAN_KEYS.some((key) => key in filtersValue && filtersValue[key] !== null) ||
        ARRAY_KEYS.some((key) => Array.isArray(filtersValue[key]) && filtersValue[key].length > 0)
    })
    const enabledDepartmentIds = computed(() => {
      if (departmentActive.value !== true) return []
      const accountDepartmentIds = filters.value.accountDepartmentIds
      if (!Array.isArray(accountDepartmentIds)) return []
      return filters.value.accountDepartmentIds.map((department) => department)
    })

    // setInitialFilter()
    // emitFilterChanged()

    onDeactivated(saveFilter)

    setFilterFromSession(props.apiKey, filters.value)
    return {
      drawer,
      filters,
      showFilterBtn,
      statusOptions,
      imageOptions,
      checklistOptions,
      crossAccountActive,
      shiftActive,
      departmentActive,
      tagFilterActive,
      jobsActive,
      anyFilterSelected,
      imageFilterActive,
      myTasksFilterActive,
      statusFilterActive,
      checkListFilterActive,
      enabledDepartmentIds,
      isRtl,
      toggleSideDrawer,
      onClickOutside,
      onFilterChanged,
      resetFilters,
      emitFilterChanged
    }
  }
}
</script>
