<template>
  <v-row align="stretch" justify="start">
    <media-carousel-dialog ref="mediaCarouselRef" />
    <confirm-delete ref="deleteDialog" />
    <chat-dialog
      ref="chatDialog"
      record-type="BrokenItem"
      @mark-read-all="markReadAllChatMessages"
      @update-message-count="updateMessageCount"
    />
    <v-col
      v-for="brokenItem in brokenItemsList" :key="brokenItem.id" cols="12"
      sm="6" md="4"
    >
      <broken-item-layout
        :broken-item="brokenItem"
        :allow-edit="showEdit"
        :allow-delete="showDelete"
        :show-close-option="showClose"
        @open-chat="openChatDialog"
        @delete-broken-item="onDeleteBrokenItem"
        @edit-broken-item="editBrokenItem"
        @change-status="onChangeStatus"
        @media-click="onMediaClick"
      />
    </v-col>
  </v-row>
</template>

<script>
import ChatDialog from '@/components/chat_dialog/ChatDialog.vue'
import MediaCarouselDialog from '@/components/shared/MediaCarouselDialog.vue'
import BrokenItemLayout from '@/components/shared/single_broken_item/BrokenItemLayout.vue'
import ConfirmDelete from '@/components/shared/ConfirmDelete.vue'

import useAuth from '@/composables/useAuth.js'
import useCompanyBrokenItem from '@/composables/useCompanyBrokenItem.js'

import { statuses } from '@/store/modules/BrokenItem.js'
import { isObject, isString } from '@/helpers/Utils.js'

import { isRtl, i18n } from '@/i18n.js'

import { ref } from 'vue'
export default {
  components: {
    'chat-dialog': ChatDialog,
    'media-carousel-dialog': MediaCarouselDialog,
    'broken-item-layout': BrokenItemLayout,
    'confirm-delete': ConfirmDelete
  },
  props: {
    showDelete: {
      type: Boolean,
      default: false
    },
    showClose: {
      type: Boolean,
      default: false
    },
    showEdit: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    const chatDialog = ref(null)
    const deleteDialog = ref(null)
    const mediaCarouselRef = ref(null)
    const { apiKey } = useAuth()
    const { brokenItemsList, setBrokenItemStatusToClose, deleteBrokenItem, applyFilters } = useCompanyBrokenItem(apiKey.value)

    const setCloseDateForToday = async (brokenItem) => {
      const title = i18n.t('devices_faulty_device_dialog.close_ticket')
      const content = i18n.t('devices_faulty_device_dialog.close_ticket_approve')
      const deleteBtnText = i18n.t('devices_faulty_device_dialog.send')
      const shouldDelete = await deleteDialog.value.open({ title, content, deleteBtnText })
      if (!shouldDelete) return

      setBrokenItemStatusToClose(brokenItem)
        .finally(() => deleteDialog.value.close())
    }

    // Methods
    const editBrokenItem = (faultyDevice) => emit('edit-broken-item', faultyDevice)

    const onMediaClick = (mediaArray) => mediaCarouselRef.value.openDialog(mediaArray)
    const onDeleteBrokenItem = async (brokenItem) => {
      const shouldDelete = await deleteDialog.value.open()
      if (!shouldDelete) return
      deleteBrokenItem(brokenItem)
        .finally(() => deleteDialog.value.close())
    }
    const onChangeStatus = ({ brokenItem, status }) => {
      if (status === statuses.close) {
        return setCloseDateForToday(brokenItem)
      }
      brokenItem.updateStatus({ newStatus: status, apiKey: brokenItem.account_api_key })
        .then(applyFilters)
    }

    const openChatDialog = (brokenItem) => {
      let dialogTitle = brokenItem.item_name
      const description = brokenItem.description
      if (isString(description) && description.trim().length > 0) {
        dialogTitle = `${dialogTitle} - ${description}`
      }
      chatDialog.value.openDialog({ recordId: brokenItem.id, apiKey: brokenItem.account_api_key, dialogTitle })
    }

    const updateMessageCount = ({ recordId, messageCount }) => {
      const brokenItem = brokenItemsList.value.find((brokenItem) => brokenItem.id === recordId)
      if (isObject(brokenItem)) brokenItem.updateMessageCount(messageCount)
    }

    const markReadAllChatMessages = (recordId) => {
      const brokenItem = brokenItemsList.value.find((brokenItem) => brokenItem.id === recordId)
      if (isObject(brokenItem)) brokenItem.markAllMessagesAsRead()
    }

    return {
      chatDialog,
      mediaCarouselRef,
      deleteDialog,
      isRtl,
      brokenItemsList,
      onDeleteBrokenItem,
      onChangeStatus,
      editBrokenItem,
      openChatDialog,
      updateMessageCount,
      markReadAllChatMessages,
      onMediaClick
    }
  }
}
</script>
