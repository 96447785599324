<template>
  <v-form ref="operationForm" class="cross-account-new-op">
    <color-picker-dialog
      ref="colorPicker"
      v-model="newOperation.color"
      @clear="clearColor"
    />
    <item-operation-list-dialog
      ref="itemOperationListDialog"
      :api-key="staffAccountApiKey"
    />
    <operation-notification
      ref="operationNotificationDialog"
      :task-un-complete-disabled="!operationHasStartTime"
    />
    <v-row
      align="start" justify="start" no-gutters
      class="overflow-x-hidden scrollable"
    >
      <v-col cols="9" class="pt-3">
        <job-selection-search
          v-if="isDialogOpen"
          ref="jobSelect"
          v-model="currentJobSearchResult"
          search-for-operation
          :api-key="staffAccountApiKey"
          :menu-props="{ 'contentClass': isRtl ? 'rtl' : 'ltr' }"
          :hide-shift="true"
          :label="$t('schedule_new_op_dialog.choose_job')"
          @input="onJobSelect"
        />
      </v-col>
      <v-col cols="3" class="text-center pt-6">
        <v-btn
          fab dark small
          color="pink" @click="openColorPicker"
        >
          <v-icon>color_lens</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="9">
        <operation-description-field
          v-model="newOperation.description"
          :operation-type="newOperation.operation_type"
        />
      </v-col>
      <v-col cols="3" class="pt-6 text-center">
        <v-btn
          small icon :disabled="requireImage"
          @click="openOperationListItem"
        >
          <v-icon :color="hasListItem ? 'green' : 'black'">
            format_list_bulleted
          </v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12">
        <operation-frequency-field
          :frequency-model.sync="frequencyModel"
          :frequency-exect-date.sync="frequencyExectDate"
          :frequency-every-year.sync="frequencyYearDate"
          :end-date.sync="endDate"
          :single-every-year-date="isUpdateMode"
        />
      </v-col>
      <v-col cols="9">
        <time-picker-dialog v-model="startTime" />
      </v-col>
      <v-col class="text-center pt-1 pl-1" cols="3">
        <v-checkbox
          v-model="requireImage"
          color="black"
          class="d-inline-block"
          :disabled="hasListItem"
          off-icon="add_a_photo"
          on-icon="add_a_photo"
        />
      </v-col>
      <v-col cols="9">
        <operation-guidence-images
          ref="opImages"
          record-type="ItemOperation"
        />
      </v-col>
      <v-col v-if="!isUpdateMode" class="text-center pt-3" cols="3">
        <v-btn
          fab dark small
          color="green" @click="openNotificationDialog"
        >
          <v-icon>notifications</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="9">
        <file-uploader
          ref="opFile"
          record-type="ItemOperation"
        />
      </v-col>
      <v-col cols="9" class="mt-2">
        <operation-report-tag
          v-model="reportTag"
          :menu-props="{ top: true }"
        />
      </v-col>
      <v-col v-if="isFrequencyTypeEveryXWeek" cols="12" class="mt-6">
        <v-row align="start" justify="start" no-gutters>
          <v-col
            v-for="(day, index) in activeDays"
            :key="day.name" cols="3"
            class="mb-3"
          >
            <v-btn
              :input-value="day.isActive"
              active-class="teal darken-1"
              @click="toggleActiveDay({ index, withReset: isUpdateMode || isCopyMode })"
            >
              {{ day.translatedName }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row align="start" justify="start" no-gutters>
      <v-col cols="12">
        <v-card-actions class="pt-5 pr-0">
          <v-spacer />
          <v-btn
            color="blue darken-1" text :disabled="isLoading"
            @click="close"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            color="blue darken-1" depressed tile
            :loading="isLoading" :disabled="saveDisabled"
            @click="saveClick"
          >
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>

import useNewOperation from '@/composables/useNewOperation.js'
import useCompany from '@/composables/useCompany.js'

import JobSelectionSearch from '@/components/job_selection_search/JobSelectionSearch.vue'
import ColorPickerDialog from '@/components/operation_schedule_dialog/ColorPickerDialog.vue'
import ItemOperationListDialog from '@/components/operation_schedule_dialog/item_operation_list/ItemOperationListDialog.vue'
import OperationGuidenceImages from '@/components/operation_schedule_dialog/OperationGuidenceImages.vue'
import OperationDescriptionField from '@/components/operation_schedule_dialog/OperationDescriptionField.vue'
import OperationFrequencyField from '@/components/operation_schedule_dialog/OperationFrequencyField.vue'
import FileUploader from '@/components/shared/FileUploader.vue'
import TimePickerDialog from '@/components/shared/TimePickerDialog.vue'
import OperationNotification from '@/components/operation_schedule_dialog/OperationNotification.vue'

import useAuth from '@/composables/useAuth.js'

import { ROUTINE_OPERATION } from '@/composables/useOperationType.js'

import { EventBus, NEW_ACCOUNT_OPERATION, RESET_OPERATION_DIALOG_CONTENT } from '@/EventBus.js'

import { isRtl } from '@/i18n.js'

import { ref, computed, onUnmounted, nextTick } from 'vue'

const DialogMode = { create: 1, update: 2, copy: 3 }
export default {
  components: {
    'color-picker-dialog': ColorPickerDialog,
    'operation-description-field': OperationDescriptionField,
    'operation-frequency-field': OperationFrequencyField,
    'item-operation-list-dialog': ItemOperationListDialog,
    'operation-guidence-images': OperationGuidenceImages,
    'file-uploader': FileUploader,
    'time-picker-dialog': TimePickerDialog,
    'job-selection-search': JobSelectionSearch,
    'operation-report-tag': () => import('@/components/operation_schedule_dialog/OperationReportTagField.vue'),
    'operation-notification': OperationNotification
  },
  props: {
    isDialogOpen: {
      type: Boolean,
      required: true
    }
  },
  setup (props, { emit }) {
    const { apiKey } = useAuth()
    const { staffAccountApiKey } = useCompany(apiKey.value)
    const preExistingListItems = ref(false)
    const colorPicker = ref(null)
    const opImages = ref(null)
    const opFile = ref(null)
    const operationForm = ref(null)
    const itemOperationListDialog = ref(null)
    const operationNotificationDialog = ref(null)

    const dialogMode = ref(DialogMode.create)
    let operationSchedule = null
    let originalJobId = null

    const {
      isLoading, newOperation, requireImage, shiftPart,
      currentJobSearchResult, activeDays, opItemList,
      frequencyExectDate, frequencyYearDate, frequencyModel,
      saveDisabled, isFrequencyTypeEveryXWeek, hasListItem, startTime, endDate, reportTag,
      taskNotifications, operationHasStartTime,
      clearColor, save, setOperation,
      toggleActiveDay, onJobSelect,
      reset
    } = useNewOperation(staffAccountApiKey.value)
    newOperation.value.operation_type = ROUTINE_OPERATION
    shiftPart.value = { name: 'start' }

    // Methods
    const openColorPicker = () => colorPicker.value.openDialog()
    const openNotificationDialog = () =>
      operationNotificationDialog.value
        .openDialog({
          taskComplete: taskNotifications.value.taskComplete,
          taskUncomplete: taskNotifications.value.taskUncomplete
        }).then(({ saved, taskComplete, taskUncomplete }) => {
          if (!saved) return
          taskNotifications.value.taskComplete = taskComplete
          taskNotifications.value.taskUncomplete = taskUncomplete
        })

    const saveClick = () => {
      const uploadImageCount = opImages.value.uploadImageCount()
      const saveImageCall = opImages.value.saveImages
      const saveFileCall = opFile.value.saveFile
      const attachedFileName = opFile.value.attachedFileName()
      save({ uploadImageCount, saveImageCall, attachedFileName, saveFileCall })
        .then(() => {
          const jobId = Number.isInteger(originalJobId) ? originalJobId : currentJobSearchResult.value.id
          close()
          EventBus.emit(NEW_ACCOUNT_OPERATION, { jobId })
        })
    }

    const openForUpdate = async ({ operation, jobId, dayName, shiftPartName, hasItemList }) => {
      preExistingListItems.value = hasItemList
      dialogMode.value = DialogMode.update
      originalJobId = jobId
      operationSchedule = await setOperation({ operation, jobId, shiftPartName, hasItemList })
      nextTick(() => {
        opImages.value.setImages(operationSchedule.guidance_images)
        opFile.value.setFile(operationSchedule.guidance_file)
      })
    }

    const openForCopy = async ({ operation }) => {
      dialogMode.value = DialogMode.copy
      const hasItemList = Array.isArray(operation.item_operation_list)

      operationSchedule = await setOperation({
        operation,
        jobId: operation.account_job_id,
        shiftPartName: operation.shift_part,
        hasItemList,
        copyMode: true
      })
      if (!hasItemList) return

      opItemList.value = operation.item_operation_list
        .map(item => ({ description: item.description, item_type: item.item_type }))
    }

    const close = () => {
      preExistingListItems.value = false
      operationSchedule = null
      originalJobId = null
      dialogMode.value = DialogMode.create
      emit('close')
    }

    const openOperationListItem = () => {
      const newItemList = JSON.parse(JSON.stringify(opItemList.value))
      const itemOperation = JSON.parse(JSON.stringify(newOperation.value))
      if (dialogMode.value === DialogMode.update) {
        itemOperationListDialog.value.openDialog(operationSchedule.item_id, itemOperation.id)
          .then(({ hasListItems }) => (preExistingListItems.value = hasListItems))
      } else {
        itemOperationListDialog.value.openDialogWithoutPersist(itemOperation.id, newItemList)
          .then(({ isSaved, itemList }) => {
            if (!isSaved) return
            opItemList.value = itemList
          })
      }
    }

    const reserContent = () => {
      reset()
      operationForm.value.reset()
      opImages.value.reset()
      opFile.value.reset()
      newOperation.value.operation_type = ROUTINE_OPERATION
      preExistingListItems.value = false
      operationSchedule = null
      dialogMode.value = DialogMode.create
    }

    const isUpdateMode = computed(() => dialogMode.value === DialogMode.update)
    const isCopyMode = computed(() => dialogMode.value === DialogMode.copy)
    EventBus.on(RESET_OPERATION_DIALOG_CONTENT, reserContent)
    onUnmounted(() => EventBus.off(RESET_OPERATION_DIALOG_CONTENT, reserContent))
    return {
      newOperation,
      isLoading,
      staffAccountApiKey,
      currentJobSearchResult,
      isFrequencyTypeEveryXWeek,
      activeDays,
      requireImage,
      saveDisabled,
      hasListItem,
      frequencyExectDate,
      frequencyYearDate,
      frequencyModel,
      isUpdateMode,
      colorPicker,
      opImages,
      opFile,
      isCopyMode,
      operationForm,
      itemOperationListDialog,
      operationNotificationDialog,
      taskNotifications,
      operationHasStartTime,
      startTime,
      endDate,
      reportTag,
      isRtl,
      openForUpdate,
      close,
      onJobSelect,
      saveClick,
      toggleActiveDay,
      clearColor,
      openColorPicker,
      openOperationListItem,
      reserContent,
      openNotificationDialog,
      openForCopy
    }
  }
}
</script>
<style lang="scss">
@media only screen and (min-width: 600px) {
  .scrollable {
    overflow: auto;
    height: 100%;
    max-height: 60vh;
  }
}
</style>
