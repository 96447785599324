<template>
  <v-row
    align="center" justify="center"
    class="file-drop-zone"
    :class="{ 'file-over': isFileOver }"
    @dragover.prevent="onDragOver"
    @dragleave.prevent="onDragLeave"
    @drop.prevent="onDrop"
    @click="triggerFileInput"
  >
    <v-col cols="12" class="text-center">
      <input
        ref="fileInput" type="file" style="display: none"
        @change="onFileChange"
      >
      <span v-if="fileLoaded" class="color5 t-500-14">
        {{ value.name }}
      </span>
      <span v-else>
        <v-icon>attach_file</v-icon>
        <div class="mt-1 color5 t-500-14 text-decoration-underline">
          {{ $t('add_file') }}
        </div>
      </span>
    </v-col>

    <v-btn
      v-if="fileLoaded" class="delete-btn"
      icon @click.stop="onDeleteClick"
    >
      <v-icon color="black">
        delete
      </v-icon>
    </v-btn>
  </v-row>
</template>

<script>
import { isFile } from '@/helpers/Utils.js'

import { ref, computed } from 'vue'
export default {
  props: {
    value: {
      type: File,
      default: null
    }
  },
  setup (props, { emit }) {
    const isFileOver = ref(false)
    const fileInput = ref(null)

    // Methods
    const triggerFileInput = () => fileInput.value.click()
    const onDeleteClick = () => emit('input', null)
    const onDragOver = event => {
      event.preventDefault()
      isFileOver.value = true
    }
    const onDragLeave = () => (isFileOver.value = false)
    const onDrop = (event) => {
      const droppedFile = event.dataTransfer.files[0]
      if (isFile(droppedFile)) {
        emit('input', droppedFile)
      }
      isFileOver.value = false
    }
    const onFileChange = (event) => {
      const selectedFile = event.target.files[0]
      if (isFile(selectedFile)) {
        emit('input', selectedFile)
      }
    }

    // Computed
    const fileLoaded = computed(() => isFile(props.value))

    return {
      isFileOver,
      fileInput,
      fileLoaded,
      triggerFileInput,
      onDragOver,
      onDragLeave,
      onDrop,
      onFileChange,
      onDeleteClick
    }
  }
}
</script>

<style scoped>
.file-drop-zone {
  position: relative;
  width: 100%;
  height: 130px;
  border: 2px dashed #616365;
  border-radius: 12px;
  color: #666;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
  .file-over {
    cursor: copy;
    background-color: #e0f7fa;
    border-color: #00796b;
  }
  .delete-btn {
    position: absolute;
    bottom: 4px;
    right: 4px;
  }
}

.file-drop-zone:hover {
  background-color: #f0f0f0;
}

</style>
