<template>
  <v-dialog
    v-model="dialog" max-width="500" persistent
    content-class="rounded-xl"
  >
    <v-card
      class="edit-file-dialog"
      :class="{ 'rtl': isRtl, 'ltr': !isRtl }"
    >
      <v-card-title>
        <v-row align="center" justify="start">
          <v-col cols="2" class="texts-start">
            <v-btn
              fab small depressed
              text :disabled="isLoading"
              @click="close"
            >
              <v-icon>
                close
              </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="8" class="text-center t-800-17">
            {{ dialogTitle }}
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="pa-2 bg-color17">
        <v-row align="center" justify="start">
          <v-col cols="12" class="mt-6">
            <file-uploader-drag-and-drop v-model="fileToUpload" />
          </v-col>
          <v-col v-if="!isFromFolder" cols="12" class="pb-0 mt-1">
            <v-select
              v-model="file.roles"
              item-text="translatedName"
              item-value="name"
              class="role-select required"
              multiple
              single-line
              outlined
              background-color="white"
              color="#E8E8E8"
              :items="rolesOptions"
              :disabled="isLoading"
              :menu-props="{ contentClass: isRtl ? 'rtl' : '' }"
              :rules="[requiredField]"
              :label="$t('drive.roles_access')"
              @change="onRoleChange"
            />
          </v-col>
          <v-col v-if="!isFromFolder" cols="12" class="pt-0">
            <v-switch
              v-if="showAllAccountsSelect"
              v-model="file.allAccounts"
              :disabled="isLoading || isCompanyAdminFolder" class="d-inline-block ltr mt-0 pt-0"
              :label="$t('drive.all_accounts')"
              @change="onAllAccountsChange"
            />
            <account-select
              v-if="showAccountSelect"
              v-model="file.accounts"
              :api-key="apiKey"
              multiple
              :dense="false"
              background-color="white"
              color="#E8E8E8"
              :disabled="isLoading || file.allAccounts || isCompanyAdminFolder"
              :show-select-all="false"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="pt-2 pb-4 bg-color17">
        <v-row align="center" justify="center">
          <v-col cols="5" class="text-center">
            <v-progress-circular
              v-if="showUploadFileProgress"
              class="mb-1"
              :rotate="-90"
              :size="36"
              :width="3"
              :value="uploadProgress"
              color="primary"
            >
              {{ uploadProgress }}
            </v-progress-circular>
            <v-btn
              block
              class="save-btn"
              color="black white--text" depressed
              :disabled="saveDisabled"
              @click="save"
            >
              {{ $t('save') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import FileUploaderDragAndDrop from '@/components/shared/FileUploaderDragAndDrop.vue'
import AccountSelect from '@/components/shared/AccountSelect.vue'
import { requiredField } from '@/classes/InputValidators.js'
import { isFile, isObject } from '@/helpers/Utils.js'
import { roleAndBelowRoles } from '@/store/modules/EmployeeRole.js'

import useCompany from '@/composables/useCompany.js'
import useAuth from '@/composables/useAuth.js'
import useCompanyDriveFile from '@/composables/useCompanyDriveFile.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { uploadFile as uploadFileToRemoteServer } from '@/general_client/FileUploader.js'

import { isRtl, i18n } from '@/i18n.js'

import { ref, computed } from 'vue'
const DialogModes = { create: 1, update: 2 }
const defaultFileParams = () => ({ id: null, name: '', allAccounts: false, roles: [], accounts: [] })
export default {
  components: {
    'account-select': AccountSelect,
    'file-uploader-drag-and-drop': FileUploaderDragAndDrop
  },
  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const { isUserCompanyAdmin, currentUserRole } = useAuth()
    const { isMultipleMemberships } = useCompany(props.apiKey)
    const dialog = ref(false)
    const isLoading = ref(false)
    const uploadProgress = ref(0)
    const dialogMode = ref()
    const file = ref(defaultFileParams())
    const rolesOptions = roleAndBelowRoles(currentUserRole.value)
    const fileToUpload = ref(null)
    const parentFolderId = ref(null)

    const { createFile } = useCompanyDriveFile(props.apiKey)
    const apiParams = () => {
      const fileVal = file.value
      const result = {
        name: fileToUpload.value.name,
        file_size: fileToUpload.value.size
      }
      if (dialogMode.value === DialogModes.create && isFromFolder.value) {
        result.drive_folder_id = parentFolderId.value
      }
      if (!isFromFolder.value) {
        result.belongs_to_all_accounts = showAllAccountsSelect.value ? fileVal.allAccounts : false
        result.api_keys = showAccountSelect.value ? fileVal.accounts : props.apiKey
        result.roles = fileVal.roles
      }
      return result
    }

    const onFileUploadProgress = (progress) => {
      const percentCompleted = Math.round((progress.loaded * 100) / progress.total)
      uploadProgress.value = percentCompleted
    }

    const uploadFile = async (fileUploadData) => {
      if (!isObject(fileUploadData)) return
      return uploadFileToRemoteServer(fileToUpload.value, fileUploadData.file_url, onFileUploadProgress)
    }
    const loadArgsFromParentFolder = (parentFolder) => {
      if (!isObject(parentFolder) || !Number.isInteger(parentFolder.id)) return
      parentFolderId.value = parentFolder.id

      const fileVal = file.value
      if (parentFolder.belongs_to_all_roles) {
        fileVal.roles = rolesOptions.map(role => role.name)
      } else {
        fileVal.roles = parentFolder.roles
      }
      if (parentFolder.belongs_to_all_accounts) {
        fileVal.allAccounts = parentFolder.belongs_to_all_accounts
      } else {
        fileVal.accounts = parentFolder.accounts.map(account => account.api_key)
      }
    }

    // Methods
    const onRoleChange = () => {
      if (!isCompanyAdminFolder.value) return
      file.value.allAccounts = true
    }

    const openDialog = (parentFolderArg) => {
      dialog.value = true
      dialogMode.value = DialogModes.create
      loadArgsFromParentFolder(parentFolderArg)
    }

    const openForUpdate = async (parentFolderArg) => {
      dialog.value = true
      dialogMode.value = DialogModes.update
      parentFolderId.value = parentFolderArg
    }

    const onAllAccountsChange = (newAllAccounts) => {
      if (!newAllAccounts) return
      file.value.accounts = []
    }
    const save = () => {
      isLoading.value = true
      createFile(apiParams())
        .then(({ data: { file_upload_data: fileUploadData } }) => uploadFile(fileUploadData))
        .then(close)
        .catch(errHandler)
        .finally(() => {
          isLoading.value = false
          uploadProgress.value = 0
        })
    }

    const close = () => {
      dialog.value = false
      isLoading.value = false
      uploadProgress.value = 0
      file.value = defaultFileParams()
      fileToUpload.value = null
      parentFolderId.value = null
    }

    // Computed
    const dialogTitle = computed(() => dialogMode.value === DialogModes.create
      ? i18n.t('drive.upload_file')
      : i18n.t('drive.update_file')
    )
    const isCompanyAdminFolder = computed(() => isUserCompanyAdmin.value && !isFromFolder.value &&
      file.value.roles.length === 1 &&
      file.value.roles[0] === 'company_admin')

    const showAccountSelect = computed(() => isMultipleMemberships.value)
    const showAllAccountsSelect = computed(() => isUserCompanyAdmin.value)

    const saveDisabled = computed(() => {
      if (isLoading.value) return true
      if (!isFile(fileToUpload.value)) return true
      if (isFromFolder.value) return false
      const fileVal = file.value
      if (fileVal.roles.length === 0) return true
      if (showAccountSelect.value && fileVal.accounts.length === 0 && !fileVal.allAccounts) return true

      return false
    })

    const showUploadFileProgress = computed(() => uploadProgress.value > 0 && uploadProgress.value < 100)
    const isFromFolder = computed(() => Number.isInteger(parseInt(parentFolderId.value)))
    return {
      isRtl,
      dialog,
      dialogTitle,
      file,
      isLoading,
      saveDisabled,
      rolesOptions,
      isFromFolder,
      isCompanyAdminFolder,
      showAccountSelect,
      showAllAccountsSelect,
      showUploadFileProgress,
      uploadProgress,
      fileToUpload,
      requiredField,
      onRoleChange,
      openDialog,
      openForUpdate,
      onAllAccountsChange,
      save,
      close
    }
  }
}
</script>
<style lang="scss">
.edit-file-dialog {
  .role-select {
    .v-text-field__details {
      margin-bottom: 0;
    }
  }
  .save-btn.v-btn--disabled {
    color: white !important
  }
}
</style>
