<template>
  <span
    class="image-uploader pointer pb-0 d-flex align-center justify-center"
    :style="{ width: width }"
  >
    <v-row
      v-if="loading"
      align="center" justify="center" class="fill-height"
      no-gutters
    >
      <v-progress-circular indeterminate color="primary" />
    </v-row>
    <v-img
      v-else-if="image.hasImage"
      :src="image.url"
      width="100%"
      height="100%"
      contain
      class="rounded-lg grey darken-4"
      @click="previewImageClick"
      @load="onImageLoaded"
      @error="onImageLoadError"
    >
      <template #placeholder>
        <v-row
          align="center" justify="center" class="fill-height"
          no-gutters
        >
          <v-progress-circular indeterminate color="primary" />
        </v-row>
      </template>
    </v-img>
    <label v-else :for="`image_${randomId}`">
      <v-icon class="material-icons-outlined" color="black"> image </v-icon>
      <input
        v-if="capture" :id="`image_${randomId}`"
        capture="capture" type="file" accept="image/*"
        :disabled="isDisabled" @change="uploadImagePreview"
      >
      <input
        v-else :id="`image_${randomId}`" type="file"
        accept="image/*"
        :disabled="isDisabled" @change="uploadImagePreview"
      >
    </label>
    <v-btn
      v-if="showDeleteButton" class="delete-btn" icon
      small
      @click="removeImage"
    >
      <v-icon color="white">
        delete
      </v-icon>
    </v-btn>
  </span>
</template>
<script>

import { isString, isFile } from '@/helpers/Utils.js'

import { ref, computed } from 'vue'

export default {
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    width: {
      type: String,
      required: false,
      default: '100%'
    },
    url: {
      type: String,
      required: false,
      default: null
    },
    capture: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup (props, { emit }) {
    const defaultImage = () => {
      return {
        url: '',
        hasImage: false,
        hadImage: false
      }
    }
    const image = ref(defaultImage())
    if (isString(props.url) && props.url.length > 0) {
      image.value.url = props.url
      image.value.hasImage = true
      image.value.hadImage = true
    }
    const randomId = (Math.random() + 1).toString(36).substring(2)

    let uploadedImagePreviewUrl = null
    let originalUploadedFile = null

    const releaseImageMemory = () => {
      if (!uploadedImagePreviewUrl) return
      URL.revokeObjectURL(uploadedImagePreviewUrl)
      uploadedImagePreviewUrl = null
    }

    // Methods

    const removeImage = () => {
      releaseImageMemory()
      const imageVal = image.value
      imageVal.url = ''
      imageVal.hasImage = false
      originalUploadedFile = null
      emit('image-removed')
    }

    const previewImageClick = () => {
      const imageVal = image.value
      if (imageVal.hasImage !== true) return
      emit('image-preview-click')
    }

    const onImageLoaded = () => {
      const imageVal = image.value
      imageVal.hasImage = true
    }

    const onImageLoadError = () => {
      const imageVal = image.value
      imageVal.hasImage = false
    }

    const uploadImagePreview = (event) => {
      const file = event.target.files[0]
      if (!file) return

      releaseImageMemory()
      const imageVal = image.value
      uploadedImagePreviewUrl = URL.createObjectURL(file)
      originalUploadedFile = file
      imageVal.url = uploadedImagePreviewUrl
      imageVal.hasImage = true
      emit('image-uploaded', event)
    }

    const shouldAddImage = () => image.value.hasImage && isFile(originalUploadedFile)
    const shouldRemoveImage = () => {
      const imgaeVal = image.value
      return !imgaeVal.hasImage && imgaeVal.hadImage === true
    }

    const setImageUrl = (imageUrl) => {
      if (!isString(imageUrl) || imageUrl.length === 0) return

      const imgaeVal = image.value
      imgaeVal.hadImage = true
      imgaeVal.hasImage = true
      imgaeVal.url = imageUrl
    }

    const reset = () => {
      if (image.value.hasImage === true) removeImage()
      image.value = defaultImage()
      originalUploadedFile = null
      releaseImageMemory()
    }

    const getImageFile = () => originalUploadedFile

    // Computed
    const isDisabled = computed(() => props.disabled === true || props.loading === true)
    const showDeleteButton = computed(() => image.value.hasImage === true && isDisabled.value === false)
    return {
      randomId,
      image,
      isDisabled,
      showDeleteButton,
      removeImage,
      previewImageClick,
      onImageLoaded,
      onImageLoadError,
      uploadImagePreview,
      shouldRemoveImage,
      setImageUrl,
      shouldAddImage,
      reset,
      getImageFile
    }
  }
}
</script>
<style lang="scss">
.image-uploader {
  input[type="file"] {
      display: none;
  }
  aspect-ratio: 1;
  border-radius: 12px;
  border: 2px dashed #616365;
  position: relative;
  .delete-btn {
    position: absolute;
    bottom: 0px;
    right: 0px;
  }

}
</style>
