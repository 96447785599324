<template>
  <span>
    <hq-task-job-group-dialog ref="hqTaskJobGroupDialog" :api-key="apiKey" />
    <confirm-delete ref="deleteDialog" />
    <v-autocomplete
      :value="value"
      :items="jobsWithHeaders"
      :search-input.sync="search"
      :disabled="disabled"
      autocomplete="off"
      clearable
      item-text="name"
      :menu-props="{ contentClass: contentClass }"
      item-value="id"
      multiple
      :loading="isLoading"
      :placeholder="$t('schedule_new_op_dialog.choose_job')"
      return-object
      @change="emitChange"
      @click:clear="clear"
      @input="update"
    >
      <template v-if="showAddJobGroup" #prepend-item>
        <v-list-item
          @click="addNewJobGroup"
        >
          <v-list-item-content>
            <v-list-item-title>
              <v-icon color="#0E4AFD" class="mx-2">add_circle_outline</v-icon>
              {{ $t('hq_task_job_group.create_title') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template #item="{ item: job, attrs }">
        <v-list-item-action v-if="!job.isJobGroup" class="mme-4">
          <v-icon v-if="attrs.inputValue">check_box</v-icon>
          <v-icon v-else>check_box_outline_blank</v-icon>
        </v-list-item-action>
        <v-list-item-content v-if="!job.isJobGroup">
          <v-list-item-title>
            <span v-if="job.department_name" class="font-weight-bold pb-1">
              {{ job.department_name }}
              <v-icon>groups_2</v-icon>
            </span>
            <div class="font-weight-bold">
              {{ job.name }} - {{ translateShift(job.shift_name) }}
              <span v-if="showAccountName" class="black--text t-500-13">
                ({{ job.account_name }})
              </span>
            </div>
          </v-list-item-title>
        </v-list-item-content>
        <v-row
          v-else align="center" justify="start"
          class="font-weight-bold pb-1" @click.stop="onJobGroupClick(job)"
        >
          <v-col cols="10">
            {{ job.name }}
          </v-col>
          <v-col cols="2" class="texts-end">
            <v-menu close-on-content-click>
              <template #activator="{ on }">
                <v-icon color="black" v-on="on">more_vert</v-icon>
              </template>
              <v-list>
                <v-list-item ripple @click.stop="onEditGroup(job)">
                  <v-list-item-icon>
                    <v-icon small>edit</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ $t('edit') }}</v-list-item-title>
                </v-list-item>
                <v-list-item ripple @click.stop="onDeleteGroup(job)">
                  <v-list-item-icon>
                    <v-icon small>delete</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ $t('delete') }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </template>
      <template #selection="{ index }">
        <span v-if="index === 0">
          <span v-if="anyGroupSelected">
            <v-chip
              v-for="group in selectedJobGroups" :key="group.jobGroup.id"
              color="blue white--text"
            >
              {{ group.jobGroup.name }}
              <v-icon @click="removeJobGroupSelection(group.jobGroup.id)">
                cancel
              </v-icon>
            </v-chip>
          </span>
          <v-chip
            v-for="job in jobListToShow" :key="job.id"
            style="height: auto;"
          >
            <div class="select-content">
              <div> {{ job.name }}
                <v-icon @click="removeSelection(job.id)">
                  cancel
                </v-icon>
              </div>
              <div class="color5 t-400-12">{{ job.account_name }}</div>
            </div>
          </v-chip>
        </span>
      </template>
    </v-autocomplete>
  </span>
</template>
<script>
import HqTaskJobGroupDialog from '@/components/hq_task_job_group/HqTaskJobGroupDialog.vue'
import ConfirmDelete from '@/components/shared/ConfirmDelete.vue'

import { translateShift } from '@/store/modules/OrganizationJob.js'

import { companySearch as companyJobClientSearch } from '@/api_client/AccountJob.js'
import { operationAccountJobs } from '@/api_client/CrossAccountOperation.js'

import useHqTaskJobGroup from '@/composables/useHqTaskJobGroup.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'
import { isString } from '@/helpers/Utils.js'

import { isRtl, i18n } from '@/i18n'

import { ref, computed, watch } from 'vue'

const RESULTS_LIMIT = 400
export default {
  components: {
    'hq-task-job-group-dialog': HqTaskJobGroupDialog,
    'confirm-delete': ConfirmDelete
  },
  props: {
    apiKey: {
      type: String,
      required: true
    },
    value: {
      type: Array,
      required: false,
      default: () => {
        return []
      }
    },
    operationId: {
      type: Number,
      default: null
    },
    disabled: {
      type: Boolean,
      required: true
    },
    showAddJobGroup: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup (props, { emit }) {
    const isLoading = ref(false)
    const companyJobs = ref([])
    const hqTaskJobGroupDialog = ref(null)
    const deleteDialog = ref(null)
    const search = ref(null)
    const selectedJobGroups = ref([])
    const { hqTaskJobGroups, loadHqTaskJobGroups, deleteGroup, loadGroupJobs } = useHqTaskJobGroup(props.apiKey)

    // Methods
    const clear = () => emit('click:clear')
    const update = (newValue) => emit('input', newValue)
    const addNewJobGroup = () => hqTaskJobGroupDialog.value.openForCreate()
    const onEditGroup = (group) => hqTaskJobGroupDialog.value.openForUpdate(group)
    const onDeleteGroup = (group) => {
      const content = i18n.t('hq_task_job_group.delete_group', { groupName: group.name })
      deleteDialog.value.open({ content })
        .then((shouldDelete) => {
          if (!shouldDelete) return
          return deleteGroup(group.id)
        })
        .catch(errHandler)
        .finally(() => deleteDialog.value.close())
    }
    const reset = () => {
      isLoading.value = false
      companyJobs.value = []
      selectedJobGroups.value = []
      update([])
    }

    const emitChange = (newValue) => {
      if (newValue === null) return
      emit('change', newValue)
      if (!anyGroupSelected.value) return

      const newJobIds = new Set(newValue.map(job => job.id))
      selectedJobGroups.value = selectedJobGroups.value.filter((group) => group.jobIds.every((jobId) => newJobIds.has(jobId)))
    }
    const removeSelection = (jobId) => {
      const newValue = props.value.filter((job) => job.id !== jobId)
      if (allGroupsJobIdsSet.value[jobId]) {
        selectedJobGroups.value = selectedJobGroups.value.filter((group) => group.jobIds.includes(jobId))
      }
      update(newValue)
    }

    const removeJobGroupSelection = (groupId) => {
      const jobIdsToRemove = selectedJobGroups.value.find((group) => group.jobGroup.id === groupId).jobIds
      selectedJobGroups.value = selectedJobGroups.value.filter((group) => group.jobGroup.id !== groupId)
      if (Array.isArray(jobIdsToRemove) && jobIdsToRemove.length > 0) {
        const newValue = props.value.filter((job) => !jobIdsToRemove.includes(job.id))
        update(newValue)
      }
    }

    const loadCompanyJobsForOperation = () => {
      isLoading.value = true
      const promises = [operationAccountJobs(props.apiKey, props.operationId), loadCompanyJobs()]
      Promise.all(promises)
        .then((responses) => {
          const accountJobIds = new Set(responses[0].data.account_job_ids || [])
          const newValue = []
          companyJobs.value.forEach((job) => {
            if (accountJobIds.has(job.id)) {
              newValue.push(job)
            }
          })
          update(newValue)
        })
        .catch(errHandler)
        .finally(() => (isLoading.value = false))
    }

    const loadCompanyJobs = () => {
      isLoading.value = true
      return companyJobClientSearch({ apiKey: props.apiKey, query: search.value, limit: RESULTS_LIMIT })
        .then((response) => (companyJobs.value = response.data.data))
        .catch(errHandler)
        .finally(() => (isLoading.value = false))
    }

    const addToSelectedGroups = ({ jobGroup, jobIds }) => {
      const groupAlreadyExists = selectedJobGroups.value.some((group) => group.id === jobGroup.id)
      if (groupAlreadyExists) return
      selectedJobGroups.value.push({ jobGroup, jobIds })
    }

    const onJobGroupClick = (jobGroup) => {
      loadGroupJobs(jobGroup.id)
        .then((response) => {
          const currentValue = JSON.parse(JSON.stringify(props.value))
          const newJobs = response.data.data
          const combinedArray = [...newJobs, ...currentValue]
          const uniqueArray = Array.from(new Map(combinedArray.map(item => [item.id, item])).values())
          addToSelectedGroups({ jobGroup, jobIds: newJobs.map(job => job.id) })
          update(uniqueArray)
        })
        .catch(errHandler)
    }

    // Computed
    const showAccountName = computed(() => isString(search.value) && search.value.length > 0)
    const contentClass = computed(() => `company-job-select ${(isRtl.value ? 'rtl' : 'ltr')}`)
    const allGroupsJobIdsSet = computed(() => {
      const result = {}
      selectedJobGroups.value.forEach((group) => {
        group.jobIds.forEach((jobId) => (result[jobId] = true))
      })
      return result
    })
    const jobListToShow = computed(() => props.value.filter((job) => !allGroupsJobIdsSet.value[job.id]))
    const jobsWithHeaders = computed(() => {
      let result = []
      if (companyJobs.value.length === 0) return result
      const companyJobsVal = companyJobs.value
      let currentApiKey = null
      companyJobsVal.forEach((job) => {
        if (job.account_api_key !== currentApiKey) {
          result.push({ header: job.account_name })
          currentApiKey = job.account_api_key
        }
        result.push(job)
      })
      if (hqTaskJobGroups.value.length > 0 && result.length > 0 && props.showAddJobGroup) {
        const jobGroupsResult = [{ header: i18n.t('hq_task_job_group.job_groups') }]
        hqTaskJobGroups.value.forEach((jobGroup) => {
          const jobGroupItem = JSON.parse(JSON.stringify(jobGroup))
          jobGroupItem.isJobGroup = true
          jobGroupsResult.push(jobGroupItem)
        })
        result = jobGroupsResult.concat(result)
      }
      return result
    })

    const anyGroupSelected = computed(() => selectedJobGroups.value.length > 0)

    if (Number.isInteger(props.operationId) && props.operationId > 0) {
      loadCompanyJobsForOperation()
    } else {
      Promise.all([loadCompanyJobs(), loadHqTaskJobGroups()])
        .catch(errHandler)
    }

    watch(() => search.value, (query) => {
      if (isLoading.value === true) return
      if (isString(query) && companyJobs.value.some((job) => job.name === query)) return

      loadCompanyJobs()
    })
    return {
      isLoading,
      hqTaskJobGroupDialog,
      deleteDialog,
      search,
      jobsWithHeaders,
      showAccountName,
      contentClass,
      anyGroupSelected,
      selectedJobGroups,
      jobListToShow,
      clear,
      emitChange,
      update,
      translateShift,
      reset,
      removeSelection,
      removeJobGroupSelection,
      addNewJobGroup,
      onJobGroupClick,
      onEditGroup,
      onDeleteGroup
    }
  }
}
</script>
<style lang="scss">
.company-job-select {
  div.v-subheader {
    background-color: #E8E8E8;
    font-weight: 700;
  }

  .select-content {
    display: flex;
    flex-direction: column;
  }
}
</style>
