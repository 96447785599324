<template>
  <v-row align="start" justify="start" no-gutters>
    <v-col class="flex-grow-1 flex-shrink-0">
      <div v-if="showAccountInfo" class="d-flex align-start justify-start">
        <v-img
          :src="task.operationRef.account_image"
          contain height="40" width="40"
          class="pointer grey darken-4 account-image-preview flex-grow-0 flex-shrink-1"
        >
          <template #placeholder>
            <v-row
              align="center" justify="center" class="fill-height"
              no-gutters
            >
              <v-progress-circular indeterminate color="primary" />
            </v-row>
          </template>
        </v-img>
        <span class="t-700-16 color20 pps-2">
          {{ task.operationRef.account_name }}
        </span>

        <v-divider v-if="showAccountArea" vertical class="account-divider" />
        <span v-if="showAccountArea" class="t-700-16 color20">{{ task.operationRef.district_name }}</span>
      </div>
      <div v-if="showDescriptionAndType" class="t-500-16 color5" :class="{ 'text-decoration-line-through': isReported }">
        {{ descriptionAndType }}
      </div>
      <div>
        <span v-if="hqView" class="t-500-13 color5 mme-1">
          <v-icon class="material-icons-outlined" color="rgba(0, 0, 0, 0.54)">
            how_to_reg
          </v-icon>
          {{ jobName }} <v-divider vertical class="job-divider mx-1" /> {{ jobShift }} ({{ jobShiftPart }})
        </span>
        <single-task-tags v-if="!crossAccountView" :task="task" />
      </div>
      <div v-if="!crossAccountView">
        <v-icon color="#F5409B">
          schedule
        </v-icon>
        <span class="pps-1 color7 t-400-12">
          {{ frequencyText }}
        </span>
      </div>
      <div v-if="!crossAccountView && showEveryXWeeksStartPeriod" class="my-1">
        <v-icon color="#F5409B">
          calendar_today
        </v-icon>
        <span class="pps-1 color7 t-400-12">
          {{ startPeriodForEveryXWeeksFrequnecy }}
        </span>
      </div>
      <v-expand-transition>
        <single-task-additional-details
          v-if="showAdditionalDetails" :task="task" :cross-account-view="crossAccountView"
          :hq-view="hqView"
          :client-date="clientDate"
        />
      </v-expand-transition>

      <div v-if="crossAccountView" class="mt-1 color5 t-500-13">
        <div v-if="showJobDepartment">
          <v-icon class="material-icons-outlined" color="rgba(0, 0, 0, 0.54)">
            pin_drop
          </v-icon>
          <span>
            {{ task.operationRef.account_job_department_name }}
          </span>
        </div>
        <div>
          <v-icon color="rgba(0, 0, 0, 0.54)">
            person_outline
          </v-icon>
          <span>
            {{ jobName }} {{ jobShift }}
          </span>
        </div>
      </div>
      <div v-if="showImage" class="mt-1">
        <v-img
          :src="task.operationRef.image_url" contain height="65"
          width="65"
          class="pointer grey darken-4 image-preview"
          @click.stop="onImageClick"
        >
          <template #placeholder>
            <v-row
              align="center" justify="center" class="fill-height"
              no-gutters
            >
              <v-progress-circular indeterminate color="primary" />
            </v-row>
          </template>
        </v-img>
      </div>
      <div v-else-if="showImageUploading" class="mt-1">
        <v-row align="center" justify="start" no-gutters>
          <v-progress-circular indeterminate color="primary" />
        </v-row>
      </div>
    </v-col>
    <v-col class="flex-grow-0 flex-shrink-1 d-flex align-center">
      <span v-if="!crossAccountView">
        <span class="expand-text">
          {{ isExpanded ? $t('tasks_page.less_details') : $t('tasks_page.more_details') }}
        </span>
        <v-icon class="mme-3 expand-icon" :class="{ rotated: isExpanded }">
          keyboard_arrow_down
        </v-icon>
      </span>
      <v-icon
        v-if="showNotification"
        class="pointer mme-3"
        color="#0000008a"
        @click.stop="onNotifyClick"
      >
        notifications
      </v-icon>
      <span v-else class="spacer" />
      <v-badge
        class="mme-3"
        :color="messageCountColor"
        overlap
        :value="hasUnreadChatMessages"
        bordered
        :content="task.operationRef.total_messages_count"
      >
        <v-icon
          color="#0000008a"
          class="pointer material-icons-outlined"
          @click.stop="onChatClick"
        >
          {{ chatMessageIcon }}
        </v-icon>
      </v-badge>
      <single-task-options
        :task="task" :task-enabled-options="taskEnabledOptions" @delete-click="onDeleteClick"
        @copy-click="onCopyClick" @share-click="onShareClick" @edit-click="onEditClick"
        @notify-click="onNotifyClick"
      />
    </v-col>
  </v-row>
</template>
<script>
import useAccount from '@/composables/useAccount.js'

import SingleTaskOptions from '@/components/tasks/tasks_list/single_task/SingleTaskOptions.vue'
import SingleTaskTags from '@/components/tasks/tasks_list/single_task/SingleTaskTags.vue'
import SingleTaskAdditionalDetails from '@/components/tasks/tasks_list/single_task/SingleTaskAdditionalDetails.vue'
import { translateShift } from '@/store/modules/OrganizationJob.js'
import { frequencyTextFromTask, calculateCurrentStartPeriodForEveryXWeeksFrequency, FrequencyTypes } from '@/store/modules/OperationFrequency.js'
import { translateShiftPartName } from '@/store/modules/OperationSchedule.js'
import { ISO8601StringToDate, getClientDateFormatted, translateDayFromDate } from '@/helpers/DateTime.js'

import { i18n } from '@/i18n.js'

import { isString } from '@/helpers/Utils.js'

import {
  EventBus, TASK_DELETE_CLICK, TASK_COPY_CLICK, TASK_SHARE_CLICK, TASK_CHAT_CLICK,
  TASK_EDIT_CLICK, TASK_NOTIFICATION_CLICK, TASK_IMAGE_CLICK
} from '@/EventBus.js'

import { computed } from 'vue'

export default {
  components: {
    'single-task-options': SingleTaskOptions,
    'single-task-tags': SingleTaskTags,
    'single-task-additional-details': SingleTaskAdditionalDetails
  },
  props: {
    task: {
      type: Object,
      required: true
    },
    clientDate: {
      type: String,
      required: true
    },
    taskEnabledOptions: {
      type: Object,
      required: true
    },
    crossAccountView: {
      type: Boolean,
      default: false
    },
    isExpanded: {
      type: Boolean,
      default: false
    },
    hqView: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const { accountCountryCode } = useAccount()
    // Methods
    const onDeleteClick = () => EventBus.emit(TASK_DELETE_CLICK, props.task)
    const onCopyClick = () => EventBus.emit(TASK_COPY_CLICK, props.task)
    const onShareClick = () => EventBus.emit(TASK_SHARE_CLICK, props.task)
    const onEditClick = () => EventBus.emit(TASK_EDIT_CLICK, props.task)
    const onNotifyClick = () => EventBus.emit(TASK_NOTIFICATION_CLICK, props.task)
    const onImageClick = () => EventBus.emit(TASK_IMAGE_CLICK, props.task)
    const onChatClick = () => EventBus.emit(TASK_CHAT_CLICK, props.task)

    // Computed
    const opRef = computed(() => props.task.operationRef)
    const isReported = computed(() => props.task.isReported())
    const pendingStatusChange = computed(() => opRef.value.pendingStatusChange)
    const showImage = computed(() => isReported.value && props.task.requireImage() && isString(opRef.value.image_url))
    const showImageUploading = computed(() => isReported.value && props.task.requireImage() && props.task.uploading_image === true)
    const showAccountInfo = computed(() => props.crossAccountView && isString(opRef.value.account_name))
    const showAccountArea = computed(() => showAccountInfo.value && isString(opRef.value.district_name))
    const showDescriptionAndType = computed(() => !props.crossAccountView && descriptionAndType.value.length > 0)
    const messageCountColor = computed(() => Number.isInteger(opRef.value.unread_messages_count) && opRef.value.unread_messages_count > 0 ? '#EC2328' : 'green')
    const hasUnreadChatMessages = computed(() => Number.isInteger(props.task.operationRef.unread_messages_count) && props.task.operationRef.unread_messages_count > 0)
    const showAdditionalDetails = computed(() => props.isExpanded || props.crossAccountView)
    const chatMessageIcon = computed(() => {
      if (hasUnreadChatMessages.value) return 'chat_bubble_outline'
      const haveAnyMessages = Number.isInteger(props.task.operationRef.total_messages_count) && props.task.operationRef.total_messages_count > 0
      return haveAnyMessages ? 'mark_chat_read' : 'chat_bubble_outline'
    })
    const frequencyText = computed(() => {
      const task = opRef.value
      return frequencyTextFromTask({
        task,
        dateString: task.first_scheduled_date
      })
    })
    const showEveryXWeeksStartPeriod = computed(() => {
      const task = opRef.value
      if (task.frequency_type !== FrequencyTypes.EveryXWeek) return false

      const firstScheudled = ISO8601StringToDate(task.first_scheduled_date)
      const clientDate = ISO8601StringToDate(props.clientDate)
      const startPeriod = calculateCurrentStartPeriodForEveryXWeeksFrequency(task.frequency, firstScheudled, clientDate)
      return startPeriod.getTime() !== clientDate.getTime()
    })
    const startPeriodForEveryXWeeksFrequnecy = computed(() => {
      const task = opRef.value
      const firstScheudled = ISO8601StringToDate(task.first_scheduled_date)
      const clientDate = ISO8601StringToDate(props.clientDate)
      const startPeriod = calculateCurrentStartPeriodForEveryXWeeksFrequency(task.frequency, firstScheudled, clientDate)

      return i18n.t('operation_frequency.week_x_frequency_start_period', {
        date: getClientDateFormatted({ fromDate: startPeriod, countryCode: accountCountryCode.value }),
        day: translateDayFromDate(startPeriod)
      })
    })

    const showNotification = computed(() => opRef.value.mark_complete_notification || opRef.value.mark_uncomplete_notification)
    const showJobDepartment = computed(() => isString(opRef.value.account_job_department_name) && opRef.value.account_job_department_name.length > 0)

    const jobName = computed(() => opRef.value.account_job_name)
    const jobShift = computed(() => translateShift(opRef.value.account_job_shift))
    const jobShiftPart = computed(() => translateShiftPartName(opRef.value.shift_part))

    const descriptionAndType = computed(() => opRef.value.operation_description || '')

    return {
      isReported,
      showDescriptionAndType,
      showNotification,
      showJobDepartment,
      descriptionAndType,
      pendingStatusChange,
      showAccountInfo,
      showImageUploading,
      showAdditionalDetails,
      jobName,
      jobShift,
      jobShiftPart,
      showImage,
      messageCountColor,
      hasUnreadChatMessages,
      chatMessageIcon,
      frequencyText,
      showEveryXWeeksStartPeriod,
      startPeriodForEveryXWeeksFrequnecy,
      showAccountArea,
      onDeleteClick,
      onCopyClick,
      onShareClick,
      onEditClick,
      onNotifyClick,
      onImageClick,
      onChatClick
    }
  }
}
</script>
