import Client from '@/api_client/client.js'

const index = ({ apiKey, searchTerm = null }) => {
  const ACCOUNT_DEPARTMENT_INDEX_PATH = `/accounts/${apiKey}/account_departments`
  return Client.get(ACCOUNT_DEPARTMENT_INDEX_PATH, { params: { term: searchTerm } })
}

const create = ({ apiKey, name, withImage = false }) => {
  const ACCOUNT_DEPARTMENT_CREATE_PATH = `/accounts/${apiKey}/account_departments`
  return Client.post(ACCOUNT_DEPARTMENT_CREATE_PATH, { account_department: { name }, with_image: withImage })
}

const update = ({ apiKey, id, name, withImage = false }) => {
  const ACCOUNT_DEPARTMENT_UPDATE_PATH = `/accounts/${apiKey}/account_departments/${id}`
  return Client.put(ACCOUNT_DEPARTMENT_UPDATE_PATH, { account_department: { name }, with_image: withImage })
}

const destroy = ({ apiKey, id }) => {
  const ACCOUNT_DEPARTMENT_DELETE_PATH = `/accounts/${apiKey}/account_departments/${id}`
  return Client.delete(ACCOUNT_DEPARTMENT_DELETE_PATH)
}

const departmentWithJobExists = (apiKey) => {
  const ACCOUNT_DEPARTMENT_WITH_JOBS_EXISTS_PATH = `/accounts/${apiKey}/account_departments/department_with_jobs_exists`
  return Client.get(ACCOUNT_DEPARTMENT_WITH_JOBS_EXISTS_PATH)
}

const totalTasksPerDepartment = (apiKey, params) => {
  const ACCOUNT_DEPARTMENT_TOTAL_TASKS_PER_DEPARTMENT_PATH = `/accounts/${apiKey}/account_departments/total_tasks_per_department`
  return Client.put(ACCOUNT_DEPARTMENT_TOTAL_TASKS_PER_DEPARTMENT_PATH, params)
}

const addImage = ({ apiKey, id, imageName }) => {
  const ACCOUNT_DEPARTMENT_CREATE_IMAGE_PATH = `/accounts/${apiKey}/account_departments/${id}/add_image`
  return Client.post(ACCOUNT_DEPARTMENT_CREATE_IMAGE_PATH, { account_department: { image_name: imageName } })
}

const removeImage = ({ apiKey, id }) => {
  const ACCOUNT_DEPARTMENT_DELETE_IMAGE_PATH = `/accounts/${apiKey}/account_departments/${id}/delete_image`
  return Client.delete(ACCOUNT_DEPARTMENT_DELETE_IMAGE_PATH)
}

const updateOrder = ({ apiKey, params }) => {
  const ACCOUNT_DEPARTMENT_UPDATE_ORDER_PATH = `/accounts/${apiKey}/account_departments/update_order`
  return Client.put(ACCOUNT_DEPARTMENT_UPDATE_ORDER_PATH, params)
}

const companySearch = ({ apiKey, term, accountApiKeys }) => {
  const ACCOUNT_DEPARTMENT_COMPANY_SEARCH_PATH = `/accounts/${apiKey}/account_departments/company_search`
  return Client.get(ACCOUNT_DEPARTMENT_COMPANY_SEARCH_PATH, { params: { term, account_api_keys: accountApiKeys } })
}
export {
  index,
  create,
  update,
  destroy,
  departmentWithJobExists,
  totalTasksPerDepartment,
  addImage,
  removeImage,
  updateOrder,
  companySearch
}
