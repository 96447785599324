<template>
  <v-dialog
    v-model="dialog" max-width="540" scrollable
    persistent
    content-class="rounded-xl"
  >
    <date-picker-dialog
      ref="datePickerDialog"
      :disabled="isLoading"
      :value="deadlineDate"
      :min-date="minDeallineDate"
      @change="setDeadlineDate"
    />
    <media-carousel-dialog ref="mediaCarouselRef" />
    <v-card
      class="broken-item-dialog bg-color16"
      :class="{ 'rtl': isRtl, 'ltr': !isRtl }"
    >
      <v-card-title class="text-center py-3" :style="{ 'background-color': dialogTitleColor }">
        <v-row align="center" justify="start" no-gutters>
          <v-col cols="3" class="texts-start">
            <v-btn icon color="white" @click="close(false)">
              <v-icon>close</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="6" class="text-center">
            <span class="t-600-18 white--text full-width">
              {{ dialogTitle }}
            </span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="scrollable popup-card">
        <v-form ref="brokenItemForm">
          <v-container>
            <v-row align="start" justify="space-between" no-gutters>
              <v-col v-if="showAccountSelection" cols="12">
                <account-select
                  ref="accountSelectRef"
                  v-model="selectedAccount"
                  append-icon="keyboard_arrow_down"
                  background-color="white"
                  is-required
                  :api-key="apiKey"
                  :multiple="false"
                  :dense="false"
                  :disabled="isDialogModeUpdate"
                  @change="onAccountChange"
                />
              </v-col>
              <v-col v-if="showAccountSelection" cols="12" class="py-2 t-700-16 black--text">
                {{ $t('description') }}
              </v-col>
              <v-col v-if="dialog" cols="12">
                <item-search
                  v-model="deviceFromSearch"
                  :place-holder="$t('broken_item_dialog_dialog.what_broke')"
                  :api-key="selectedApiKey"
                  is-required
                  show-add-item
                  show-delete-button
                  show-edit-button
                  background-color="white"
                  outlined
                  load-all
                  :disabled="isLoading || isNoAccountSelected"
                />
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="newBrokenItem.description"
                  background-color="white"
                  outlined
                  auto-grow
                  rows="1"
                  :counter="MAX_DESCRIPTION_LENGTH"
                  :label="$t('broken_item_dialog_dialog.description')"
                />
              </v-col>
              <v-col cols="12">
                <user-search
                  v-model="assignedUsers"
                  :api-key="selectedApiKey"
                  with-hq-users
                  multiple
                  background-color="white"
                  outlined
                  chip-color="black white--text"
                  :disabled="isLoading || isNoAccountSelected"
                  :placeholder="$t('broken_item_dialog_dialog.assigned_user')"
                />
              </v-col>
              <v-col cols="12">
                <company-provider-search
                  v-model="newBrokenItem.related_providers"
                  multiple
                  background-color="white"
                  outlined
                  chip-color="black white--text"
                  :api-key="selectedApiKey"
                  :disabled="isLoading"
                  :placeholder="$t('broken_item_dialog_dialog.add_service_provider')"
                />
              </v-col>
              <v-col cols="5">
                <v-text-field
                  clearable
                  background-color="white"
                  outlined
                  :label="$t('broken_item_dialog_dialog.deadline_date')"
                  :value="deadlineString"
                  readonly
                  @click:clear="clearDeadline"
                  @click="openDatePicker"
                />
              </v-col>
              <v-col cols="5">
                <time-picker-dialog
                  :value="deadlineTimeString"
                  background-color="white"
                  outlined
                  :disabled="deadlineTimeDisabled"
                  :placeholder="$t('broken_item_dialog_dialog.deadline_time')"
                  @input="onTimePickerChange"
                />
              </v-col>
              <v-col cols="12" class="mt-2 black--text">
                <span class="mme-2">
                  <v-icon>
                    check_circle_outline
                  </v-icon>
                  {{ $t('broken_item_dialog_dialog.status') }}
                </span>
                <v-menu offset-y bottom>
                  <template #activator="{ on, attrs }">
                    <v-chip
                      v-bind="attrs" small class="status-chip"
                      :style="{'border-color': dialogTitleColor}"
                      v-on="on"
                    >
                      {{ statusName }}
                      <v-icon>keyboard_arrow_down</v-icon>
                    </v-chip>
                  </template>
                  <v-list :class="{ 'rtl': isRtl, 'ltr': !isRtl }">
                    <v-list-item
                      v-for="status in availableStatuses" :key="status.name"
                      @click="changeStatus(status.name)"
                    >
                      <v-list-item-title>{{ status.translatedName }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
              <v-col cols="12" class="py-2 black--text">
                <span class="t-700-16">{{ $t('photos') }}</span>&nbsp;<span lass="t-400-16">{{ $t('broken_item_dialog_dialog.max_photo') }}</span>
              </v-col>
              <v-col cols="12">
                <broken-item-images
                  ref="brokenItemImages" image-types="broken_item_images"
                  @media-click="onMediaClick"
                />
              </v-col>
              <v-col cols="12" class="py-2 black--text">
                <span class="t-700-16">{{ $t('video') }}</span>&nbsp;<span lass="t-400-16">{{ $t('broken_item_dialog_dialog.max_video') }}</span>
              </v-col>
              <v-col cols="12" class="my-2">
                <file-uploader
                  ref="brokenItemVideo"
                  :placeholder="$t('broken_item_dialog_dialog.add_video')"
                  record-type="BrokenItemVideo"
                />
              </v-col>
              <v-col cols="12">
                <v-btn
                  text color="#0040FF" class="px-0"
                  @click="toggleMoreDetails"
                >
                  <v-icon>{{ moreDetailsOpen ? 'expand_less' : 'expand_more' }}</v-icon>
                  {{ $t('show_more_details') }}
                </v-btn>
                <v-expand-transition>
                  <v-row
                    v-show="moreDetailsOpen" align="start" justify="space-between"
                    no-gutters
                  >
                    <v-col cols="12">
                      <div class="switch-div row no-gutters align-center justify-space-between pointer my-2">
                        <v-col class="flex-grow-1 flex-shrink-0 pps-2 t-400-16" @click="changePriority">
                          {{ $t('broken_item_dialog_dialog.priority') }}
                        </v-col>
                        <v-col class="flex-grow-0 flex-shrink-1">
                          <v-switch
                            v-model="newBrokenItem.priority"
                            class="px-2 d-inline-block ltr"
                            false-value="low"
                            true-value="high"
                            @change="onPriorityChange"
                          />
                        </v-col>
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <tag-chips-select
                        v-model="newBrokenItem.tags"
                        :max-tags="1"
                        :disabled="isLoading || isNoAccountSelected"
                        :api-key="selectedApiKey"
                        :dense="false"
                        context="broken_item_tags"
                        background-color="white"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        v-model="newBrokenItem.comment"
                        background-color="white"
                        outlined
                        auto-grow
                        rows="4"
                        :counter="MAX_DESCRIPTION_LENGTH"
                        :label="$t('broken_item_dialog_dialog.comment')"
                      />
                    </v-col>
                  </v-row>
                </v-expand-transition>
              </v-col>
              <v-col v-if="!isDialogModeCreate" cols="12" class="t-700-16 my-2 black--text">
                <v-icon color="black">
                  construction
                </v-icon>{{ $t('broken_item_dialog_dialog.repair') }}
              </v-col>
              <v-col v-if="!isDialogModeCreate" cols="12">
                <v-textarea
                  v-model="newBrokenItem.progress_description"
                  background-color="white"
                  outlined
                  auto-grow
                  :counter="MAX_DESCRIPTION_LENGTH"
                  rows="4"
                  :label="$t('broken_item_dialog_dialog.progress_description')"
                />
              </v-col>
              <v-col v-if="!isDialogModeCreate" cols="12" class="mt-2">
                <broken-item-images
                  ref="brokenItemFixImages"
                  image-types="broken_item_fix_images"
                  @media-click="onMediaClick"
                />
              </v-col>
              <v-col v-if="!isDialogModeCreate" cols="12">
                <v-text-field
                  v-model="newBrokenItem.price"
                  v-mask="'#########'"
                  background-color="white"
                  outlined
                  :label="$t('broken_item_dialog_dialog.price')"
                />
              </v-col>
              <v-col v-if="!isDialogModeCreate" cols="12" class="mt-2">
                <file-uploader
                  ref="brokenItemFile"
                  :placeholder="$t('broken_item_dialog_dialog.add_invoice')"
                  record-type="BrokenItem"
                  color="transparent"
                  underline
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-row
          no-gutters align="end" justify="center"
          class="pb-5 mt-3"
        >
          <v-col cols="6" class="text-center">
            <v-btn
              color="black" class="save-btn white--text" block
              depressed tile :loading="isLoading"
              :disabled="isSaveDisabled" @click="save"
            >
              {{ $t('save') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import BrokenItemImages from '@/components/broken_item_dialog/BrokenItemImages.vue'
import ItemSearch from '@/components/shared/item_search/ItemSearch.vue'
import UserSearch from '@/components/shared/user_search/UserSearch.vue'
import FileUploader from '@/components/shared/FileUploader.vue'
import CompanyProviderSearch from '@/components/shared/company_provider_search/CompanyProviderSearch.vue'
import MediaCarouselDialog from '@/components/shared/MediaCarouselDialog.vue'
import TimePickerDialog from '@/components/shared/TimePickerDialog.vue'
import AccountSelect from '@/components/shared/AccountSelect.vue'
import TagChipsSelect from '@/components/shared/tag_chips_select/TagChipsSelect.vue'
import DatePickerDialog from '@/components/shared/DatePickerDialog.vue'

import useBrokenItemDeadline from '@/composables/useBrokenItemDeadline.js'

import { newBrokenItemModel, getAllStatuses, statuses, statusSecondColors } from '@/store/modules/BrokenItem.js'
import {
  dateToISO8601String, getClientDateFormatted, isValidISO8601Date, ISO8601StringToDate, ISO8601TimeFromDate
} from '@/helpers/DateTime.js'
import useAuth from '@/composables/useAuth.js'
import useAccount from '@/composables/useAccount.js'

import { create as BrokenItemClientCreate, update as BrokenItemClientUpdate, show as BrokenItemClientShow } from '@/api_client/BrokenItem.js'

import { requiredField } from '@/classes/InputValidators.js'

import { isString, isDate, isObject } from '@/helpers/Utils.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { EventBus, UPDATE_TAGS_IF_NEEDED } from '@/EventBus.js'

import { mask } from 'vue-the-mask'

import Vue, { ref, computed, nextTick } from 'vue'

import { i18n, isRtl } from '@/i18n.js'

const DialogModeEnum = { update: 1, create: 2 }

const MAX_DESCRIPTION_LENGTH = 400
export default {
  components: {
    'broken-item-images': BrokenItemImages,
    'item-search': ItemSearch,
    'user-search': UserSearch,
    'file-uploader': FileUploader,
    'date-picker-dialog': DatePickerDialog,
    'tag-chips-select': TagChipsSelect,
    'account-select': AccountSelect,
    'media-carousel-dialog': MediaCarouselDialog,
    'company-provider-search': CompanyProviderSearch,
    'time-picker-dialog': TimePickerDialog
  },
  directives: {
    mask
  },
  props: {
    showCloseOption: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const { accountCountryCode, accountLocaleCode } = useAccount()
    const { apiKey, isCompanyAdminRegionOrAbove } = useAuth()
    const { brokenItemsDeadline, loadBrokenItemDeadlines } = useBrokenItemDeadline(apiKey.value)

    const countryCode = accountCountryCode.value
    const locale = accountLocaleCode.value
    let deviceId = null
    let originalStatus = null
    let resolveFunc = null
    let isItemIdChanged = null
    let userChangedDeadlineDateOrTime = false

    const dialog = ref(false)
    const isLoading = ref(false)
    const dialogMode = ref(null)
    const mediaCarouselRef = ref(null)
    const newBrokenItem = ref(newBrokenItemModel({ locale }))
    const deviceFromSearch = ref({})
    const assignedUsers = ref([])
    const closeTranslation = i18n.t('broken_item.close_ticket')
    const availableStatuses = ref(getAllStatuses({ addClosedStatus: props.showCloseOption, closeTranslation }))
    const brokenItemImages = ref(null)
    const brokenItemFixImages = ref(null)
    const brokenItemFile = ref(null)
    const brokenItemVideo = ref(null)
    const brokenItemForm = ref(null)
    const datePickerDialog = ref(null)
    const selectedAccount = ref(null)
    const accountSelectRef = ref(null)
    const minDeallineDate = dateToISO8601String(new Date())
    const moreDetailsOpen = ref(false)

    const setDefaultDeadlineForPriority = () => {
      if (dialogMode.value !== DialogModeEnum.create) return
      const deadlines = brokenItemsDeadline.value
      const priority = newBrokenItem.value.priority
      const foundDefaultDeadline = deadlines.find((deadline) => deadline.priority === priority &&
        deadline.deadline_type === 'on_time')

      if (isObject(foundDefaultDeadline) !== true) return
      const hours = foundDefaultDeadline.minutes / 60
      const designatedDeadline = new Date()
      designatedDeadline.setHours(designatedDeadline.getHours() + hours)
      newBrokenItem.value.deadline_date = designatedDeadline
      newBrokenItem.value.deadline_time = ISO8601TimeFromDate(designatedDeadline)
    }

    const openDialog = (deviceIdArg, brokenItem, dialogModeVal) => {
      dialog.value = true
      deviceId = deviceIdArg
      dialogMode.value = dialogModeVal
      newBrokenItem.value = newBrokenItemModel({ src: brokenItem, locale })
      originalStatus = newBrokenItem.value.status
      setDefaultDeadlineForPriority()
      return new Promise((resolve) => {
        resolveFunc = resolve
      })
    }

    const setDeviceForSearch = (brokenItem) => {
      const deviceFromSearchVal = deviceFromSearch.value
      Vue.set(deviceFromSearchVal, 'id', brokenItem.item_id)
      Vue.set(deviceFromSearchVal, 'name', brokenItem.item_name)
      Vue.set(deviceFromSearchVal, 'department_name', brokenItem.item_department)
    }

    const setAssignedUsers = (brokenItem) => {
      const shouldAddAssignedUsers = Array.isArray(brokenItem.assigned_users) && brokenItem.assigned_users.length > 0

      if (shouldAddAssignedUsers !== true) return
      assignedUsers.value = []
      const assignedUsersVal = assignedUsers.value
      brokenItem.assigned_users.forEach(user => assignedUsersVal.push({ id: user.user_id }))
    }

    const updateBrokenItem = ({ creationParams, newDeviceId, uploadImageCount, uploadImageCountForFix }) => {
      if (originalStatus === statuses.close && newBrokenItem.value.status !== statuses.close) {
        creationParams.close_date = ''
      }
      if (newDeviceId !== deviceId) {
        isItemIdChanged = true
        creationParams.item_id = newDeviceId
      }
      return BrokenItemClientUpdate(selectedApiKey.value, deviceId,
        newBrokenItem.value.id, { broken_item: creationParams, with_image: uploadImageCount, with_fix_image: uploadImageCountForFix })
    }

    const createBrokenItem = ({ creationParams, deviceId, uploadImageCount }) => {
      return BrokenItemClientCreate(selectedApiKey.value, deviceId, { broken_item: creationParams, with_image: uploadImageCount })
    }

    const updateOrCreateBrokenItem = async () => {
      if (isDialogModeUpdate.value && originalStatus !== statuses.close &&
        newBrokenItem.value.status === statuses.close) {
        newBrokenItem.value.setClosingTime()
      }
      const creationParams = newBrokenItem.value.serialize()
      creationParams.assigned_users = Array.isArray(assignedUsers.value) && assignedUsers.value.length > 0
        ? assignedUsers.value.map((user) => user.id)
        : null

      const deviceId = deviceFromSearch.value.id
      const uploadImageCount = brokenItemImages.value.uploadImageCount()

      const attachedVideoFileName = brokenItemVideo.value.attachedFileName()
      if (isString(attachedVideoFileName) === true) {
        creationParams.with_video = attachedVideoFileName
      }

      if (isDialogModeUpdate.value !== true) return createBrokenItem({ creationParams, deviceId, uploadImageCount })

      const attachedFileName = brokenItemFile.value.attachedFileName()
      if (isString(attachedFileName) === true) {
        creationParams.with_file = attachedFileName
      }
      const uploadImageCountForFix = brokenItemFixImages.value.uploadImageCount()
      return updateBrokenItem({ creationParams, newDeviceId: deviceId, uploadImageCount, uploadImageCountForFix })
    }

    const saveAdditionalFiles = async (updateOrCreateResponse) => {
      const responseData = updateOrCreateResponse.data.data
      const apiCalls = []
      const deviceId = deviceFromSearch.value.id
      const brokenItemId = responseData.id
      if (brokenItemImages.value.hasChanges() === true) {
        const imageUploadSignatures = responseData.image_upload_data
        apiCalls.push(brokenItemImages.value.saveImages({ apiKey: selectedApiKey.value, deviceId, brokenItemId, imageUploadSignatures }))
      }
      if (isDialogModeUpdate.value === true && brokenItemFixImages.value.hasChanges() === true) {
        const imageUploadSignatures = responseData.image_upload_data_fix
        apiCalls.push(brokenItemFixImages.value.saveImages({ apiKey: selectedApiKey.value, deviceId, brokenItemId, imageUploadSignatures }))
      }

      if (isDialogModeUpdate.value === true && brokenItemFile.value.hasChanges() === true) {
        const fileUploadSignature = responseData.file_upload_signature
        apiCalls.push(brokenItemFile.value.saveFile({ apiKey: selectedApiKey.value, itemId: deviceId, fileUploadSignature, brokenItemId }))
      }

      if (brokenItemVideo.value.hasChanges() === true) {
        const fileUploadSignature = responseData.video_upload_signature
        apiCalls.push(brokenItemVideo.value.saveFile({ apiKey: selectedApiKey.value, itemId: deviceId, fileUploadSignature, brokenItemId }))
      }

      return Promise.all(apiCalls).then(() => {
        if (apiCalls.length === 0) return updateOrCreateResponse
        return BrokenItemClientShow(selectedApiKey.value, deviceId, brokenItemId)
      })
    }

    const updateTags = () => {
      const tags = newBrokenItem.value.tags
      if (Array.isArray(tags) && tags.length > 0) EventBus.emit(UPDATE_TAGS_IF_NEEDED, { tags: tags[0], context: 'broken_item_tags', apiKey: selectedApiKey.value })
    }

    // Methods
    const openDatePicker = () => datePickerDialog.value.openDialog()
    const changeStatus = (status) => (newBrokenItem.value.status = status)
    const onMediaClick = (mediaArray) => mediaCarouselRef.value.openDialog(mediaArray)

    const changePriority = () => {
      newBrokenItem.value.priority = newBrokenItem.value.priority === 'high' ? 'low' : 'high'
      onPriorityChange()
    }
    const onPriorityChange = () => {
      if (userChangedDeadlineDateOrTime) return
      setDefaultDeadlineForPriority()
    }

    const onTimePickerChange = (time) => {
      newBrokenItem.value.deadline_time = time
      userChangedDeadlineDateOrTime = true
    }

    const clearDeadline = () => {
      newBrokenItem.value.deadline_date = null
      newBrokenItem.value.deadline_time = null
      userChangedDeadlineDateOrTime = true
    }
    const setDeadlineDate = (date) => {
      newBrokenItem.value.deadline_date = date
      userChangedDeadlineDateOrTime = true
    }
    const toggleMoreDetails = () => (moreDetailsOpen.value = !moreDetailsOpen.value)
    const onAccountChange = (newValue) => {
      selectedAccount.value = newValue
      deviceFromSearch.value = {}
      assignedUsers.value = []
      newBrokenItem.value.tags = []
    }
    const openForUpdate = (brokenItem) => {
      if (showAccountSelection.value === true) {
        selectedAccount.value = brokenItem.account_api_key
      }
      setDeviceForSearch(brokenItem)
      setAssignedUsers(brokenItem)
      if (brokenItemImages.value && brokenItemFixImages.value && brokenItemFile.value && brokenItemVideo.value) {
        brokenItemImages.value.setImages(brokenItem.images)
        brokenItemFixImages.value.setImages(brokenItem.fixed_images)
        brokenItemFile.value.setFile(brokenItem.attached_file_url)
        brokenItemVideo.value.setFile(brokenItem.attached_video_url)
      } else {
        nextTick(() => {
          brokenItemImages.value.setImages(brokenItem.images)
          brokenItemFixImages.value.setImages(brokenItem.fixed_images)
          brokenItemFile.value.setFile(brokenItem.attached_file_url)
          brokenItemVideo.value.setFile(brokenItem.attached_video_url)
        })
      }
      return openDialog(brokenItem.item_id, brokenItem, DialogModeEnum.update)
    }

    const openForCreateWithDeviceSearch = () => {
      return openDialog(null, null, DialogModeEnum.create)
    }

    const close = (resolveResult, response) => {
      const isItemIdChangedVal = isItemIdChanged
      userChangedDeadlineDateOrTime = false
      dialogMode.value = null
      dialog.value = false
      isItemIdChanged = false
      deviceId = null
      deviceFromSearch.value = {}
      assignedUsers.value = []
      selectedAccount.value = null
      moreDetailsOpen.value = false
      resolveFunc({ updatedOrCreated: resolveResult, response, isItemIdChanged: isItemIdChangedVal })
    }

    const save = () => {
      if (!brokenItemForm.value.validate()) return
      isLoading.value = true
      updateOrCreateBrokenItem()
        .then(saveAdditionalFiles)
        .then((response) => {
          updateTags()
          close(true, (response || { data: { data: {} } }).data.data)
        })
        .catch(errHandler)
        .finally(() => (isLoading.value = false))
    }

    // Computed
    const isDialogModeUpdate = computed(() => dialogMode.value === DialogModeEnum.update)
    const isDialogModeCreate = computed(() => dialogMode.value === DialogModeEnum.create)
    const dialogTitleColor = computed(() => statusSecondColors[newBrokenItem.value.status] || 'inherit')
    const showAccountSelection = computed(() => isCompanyAdminRegionOrAbove.value)
    const isAccountSelected = computed(() =>
      showAccountSelection.value && isString(selectedAccount.value) && selectedAccount.value.length > 0
    )
    const isNoAccountSelected = computed(() => showAccountSelection.value && !isAccountSelected.value)

    const statusName = computed(() => {
      const status = newBrokenItem.value.status
      if (status === statuses.close) return i18n.t('broken_item_dialog_dialog.status_close')
      return availableStatuses.value.find(statusRow => statusRow.name === status)?.translatedName
    })

    const selectedApiKey = computed(() => {
      if (isAccountSelected.value === true) return selectedAccount.value
      return apiKey.value
    })

    const dialogTitle = computed(() => {
      return isDialogModeUpdate.value === true
        ? i18n.t('broken_item_dialog_dialog.update_title')
        : i18n.t('broken_item_dialog_dialog.create_title')
    })
    const isSaveDisabled = computed(() => {
      if (isNoAccountSelected.value === true) return true
      if (!Number.isInteger((deviceFromSearch.value || {}).id)) return true
      const newBrokenItemVal = newBrokenItem.value

      if (isString(newBrokenItemVal.description) && newBrokenItemVal.description.length > MAX_DESCRIPTION_LENGTH) return true
      if (isString(newBrokenItemVal.comment) && newBrokenItemVal.comment.length > MAX_DESCRIPTION_LENGTH) return true
      if (isString(newBrokenItemVal.progress_description) && newBrokenItemVal.progress_description.length > MAX_DESCRIPTION_LENGTH) return true

      return isLoading.value
    })

    const deadlineDate = computed(() => {
      const deadline = newBrokenItem.value.deadline_date
      if (isValidISO8601Date(deadline) === true) return ISO8601StringToDate(deadline)
      if (isDate(deadline)) return deadline

      return null
    })
    const deadlineString = computed(() => {
      const deadline = deadlineDate.value
      if (!isDate(deadline)) return ''

      return getClientDateFormatted({ fromDate: deadline, countryCode })
    })

    const deadlineTimeDisabled = computed(() => deadlineDate.value === null)
    const deadlineTimeString = computed(() => deadlineTimeDisabled.value ? '' : newBrokenItem.value.deadline_time)

    loadBrokenItemDeadlines()
    return {
      MAX_DESCRIPTION_LENGTH,
      dialog,
      isLoading,
      apiKey,
      deviceFromSearch,
      mediaCarouselRef,
      assignedUsers,
      availableStatuses,
      dialogTitle,
      isDialogModeCreate,
      newBrokenItem,
      brokenItemImages,
      brokenItemFixImages,
      brokenItemFile,
      brokenItemVideo,
      brokenItemForm,
      datePickerDialog,
      isSaveDisabled,
      deadlineString,
      deadlineTimeString,
      deadlineTimeDisabled,
      minDeallineDate,
      deadlineDate,
      selectedAccount,
      isNoAccountSelected,
      selectedApiKey,
      isDialogModeUpdate,
      accountSelectRef,
      moreDetailsOpen,
      isRtl,
      statusName,
      dialogTitleColor,
      showAccountSelection,
      openForUpdate,
      openDatePicker,
      openForCreateWithDeviceSearch,
      clearDeadline,
      setDeadlineDate,
      toggleMoreDetails,
      changePriority,
      close,
      save,
      requiredField,
      onAccountChange,
      onPriorityChange,
      onTimePickerChange,
      onMediaClick,
      changeStatus
    }
  }
}
</script>

<style lang="scss">
.broken-item-dialog {
  .scrollable {
    overflow: auto;
    height: 100%;
    max-height: 80vh;
  }

  input[type="file"] {
    display: none;
  }
  .file-chip {
    padding: 0;
  }
  .status-chip {
      border: 1.5px #FF1744 solid;
  }
  .switch-div {
    height: 59px;
    border: 1px solid #E8E8E8;
    border-radius: 2px;
    background-color: white;
    color: rgba(0, 0, 0, 0.7);
  }
  .save-btn.v-btn--disabled {
    color: white !important
  }
}
</style>
