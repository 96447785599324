<template>
  <v-col
    cols="12"
    class="chat-message px-4 d-flex align-start"
    :class="{
      'justify-start': isMessageFromCurrentUser,
      'justify-end': !isMessageFromCurrentUser
    }"
  >
    <v-avatar v-if="isMessageFromCurrentUser" size="30" color="yellow darken-2">
      <v-img v-if="showUserAvatar" :src="message.avatar_url" />
      <span v-else class="white--text text-h5">
        {{ senderInitials }}
      </span>
    </v-avatar>
    <chat-message-content
      :class="{
        'user-message mms-1': isMessageFromCurrentUser,
        'other-message mme-1': !isMessageFromCurrentUser
      }"
      :message="message"
      :delete-in-progress="deleteInProgress"
      @image-click="onImageClick"
      @info-click="onInfoClick"
      @delete-click="onDeleteMessage"
      @copy-click="onCopyClick"
    />
    <v-avatar v-if="!isMessageFromCurrentUser" size="30" color="yellow darken-2">
      <v-img v-if="showUserAvatar" :src="message.avatar_url" />
      <span v-else class="white--text text-h5">
        {{ senderInitials }}
      </span>
    </v-avatar>
  </v-col>
</template>

<script>

import ChatMessageContent from '@/components/app_chat/chat_message/ChatMessageContent.vue'

import { isString } from '@/helpers/Utils.js'

import useAppChat from '@/composables/useAppChat.js'

import { computed, ref } from 'vue'

export default {
  components: {
    'chat-message-content': ChatMessageContent

  },
  props: {
    message: {
      type: Object,
      required: true
    },
    apiKey: {
      type: String,
      required: true
    }
  },
  setup (props, { emit }) {
    const deleteInProgress = ref(false)

    const { deleteMessage } = useAppChat(props.apiKey)

    // Methods
    const onImageClick = (args) => emit('image-click', args)
    const onInfoClick = () => emit('info-click', props.message)
    const onCopyClick = () => emit('copy-click', props.message)

    const onDeleteMessage = () => {
      deleteInProgress.value = true
      deleteMessage({ chatMessageId: props.message.id })
        .finally(() => (deleteInProgress.value = true))
    }

    // Computed
    const isMessageFromCurrentUser = computed(() => props.message.sent_by_current_user === true)
    const showUserAvatar = computed(() => isString(props.message.avatar_url) && props.message.avatar_url.length > 0)

    const senderInitials = computed(() => {
      const firstInitial = props.message.first_name.charAt(0)
      const lastInitial = props.message.last_name.charAt(0)
      return `${firstInitial}${lastInitial}`
    })

    return {
      isMessageFromCurrentUser,
      deleteInProgress,
      showUserAvatar,
      senderInitials,
      onInfoClick,
      onDeleteMessage,
      onImageClick,
      onCopyClick
    }
  }
}
</script>

<style lang="scss">
.rtl {
  .user-message {
    border-radius: 24px 0px 24px 24px !important;
  }

  .other-message {
    border-radius: 0px 24px 24px 24px !important;
  }
}

.user-message {
  border-radius: 0px 24px 24px 24px !important;
  background-color: white !important;
}

.other-message {
  background-color: #EEFFE0 !important;
  border-radius: 24px 0px 24px 24px !important;
}
</style>
