import { ROUTINE_OPERATION } from '@/composables/useOperationType.js'
import { isHexColor } from '@/helpers/Utils.js'

const filedList = ['id', 'operation_type', 'description', 'scheduled_operations_count',
  'has_list_items', 'color', 'guidance_images']

// TODO remove defaultColor after it changed in the api
const defaultColor = '#E0E0E0'
const newDefaultColor = '#CBD5F2'

function DeviceOperationModel (src) {
  const srcOrEmpty = src || { operation_type: ROUTINE_OPERATION }
  filedList.forEach((field) => {
    this[field] = srcOrEmpty[field]
  })
  if (!isHexColor(this.color)) {
    this.color = defaultColor
  }
}

DeviceOperationModel.prototype.serialize = function () {
  const creationParams = JSON.parse(JSON.stringify(this))
  const result = filedList.reduce((initValue, currentValue) => {
    initValue[currentValue] = creationParams[currentValue]
    return initValue
  }, {})
  delete result.scheduled_operations_count
  delete result.has_list_items
  delete result.guidance_images
  return result
}

DeviceOperationModel.prototype.updateTotalOperations = function (count) {
  this.scheduled_operations_count = count || 0
}

DeviceOperationModel.prototype.clearColor = function (boolValue) {
  this.color = defaultColor
}

function newDeviceOperationModel (src) {
  return new DeviceOperationModel(src)
}

export {
  newDeviceOperationModel,
  defaultColor,
  newDefaultColor
}
