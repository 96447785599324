<template>
  <v-row
    v-if="isLoading" align="start" justify="start"
    no-gutters
  >
    <v-col cols="12">
      <v-skeleton-loader
        v-for="i in 2" :key="i" class="mt-3"
        type="list-item-three-line"
      />
    </v-col>
  </v-row>
  <v-container
    v-else fluid

    class="ca-tasks-list pa-0"
  >
    <single-task-layout
      v-for="task in tasks"
      :key="task.id"
      :client-date="clientDateIso"
      :task="task"
      :task-enabled-options="optionsForTask(task)"
      :disabled="tasksDisabled"
      cross-account-view
    />
  </v-container>
</template>
<script>
import SingleTaskLayout from '@/components/tasks/tasks_list/single_task/SingleTaskLayout.vue'

import useHQCrossAccountDailyTasks from '@/composables/useHQCrossAccountDailyTasks.js'

import { ref } from 'vue'

export default {
  components: {
    'single-task-layout': SingleTaskLayout
  },
  props: {
    apiKey: {
      type: String,
      required: true
    },
    crossAccountOperationId: {
      type: Number,
      required: true
    }
  },
  setup (props) {
    const { clientDateIso, tasksDisabled, loadSingleCrossAccountTasks } = useHQCrossAccountDailyTasks(props.apiKey)
    const isLoading = ref(true)
    const tasks = ref([])

    loadSingleCrossAccountTasks(props.crossAccountOperationId)
      .then((result) => {
        isLoading.value = false
        tasks.value = result
      })

    // Methods

    const optionsForTask = (task) => {
      if (task.operationRef.hideOptions) {
        return {
          share: true,
          notification: true
        }
      }

      return {
        edit: true,
        delete: true,
        share: true,
        notification: true,
        copy: true
      }
    }
    return {
      isLoading,
      clientDateIso,
      tasksDisabled,
      tasks,
      optionsForTask
    }
  }
}
</script>
<style lang="scss">
.single-hq-task {
  .ca-tasks-list {
    .single-task {
      border: none!important;
      margin-bottom: 0px !important;
      border-radius: 0px;
    }
    .single-task:not(:last-child)::after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background-color: #DADADA;
      margin-top: 16px;
    }
    .single-task:last-of-type {
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
    }
  }
}
</style>
