<template>
  <v-row
    class="account-select" align="center" justify="start"
    no-gutters :class="{ 'rtl': isRtl, 'ltr': !isRtl }"
  >
    <account-edit-dialog ref="accountEditDialog" :api-key="apiKey" />
    <v-col cols="12" class="row align-end justify-center top-row mt-0">
      <v-img
        :src="companyImage" cover height="250px"
        width="100vw"
      />
      <span class="triangle" />
    </v-col>
    <v-col cols="12" class="row align-start justify-center stats-row">
      <v-col
        cols="10" lg="4" md="5"
        sm="8"
      >
        <company-stats
          :client-date="clientDate"
          @account-stat-click="accountStatClick"
          @hq-stat-click="hqStatClick"
          @broken-item-stat-click="brokenItemStatClick"
        />
      </v-col>
    </v-col>
    <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="12" class="text-center mt-8 mb-2">
      <v-btn-toggle
        v-model="uiMode"
        class="ltr" rounded mandatory
      >
        <v-btn small value="grid">
          <v-icon>grid_view</v-icon>
        </v-btn>
        <v-btn small value="list">
          <v-icon>list</v-icon>
        </v-btn>
      </v-btn-toggle>
    </v-col>
    <v-row align="end" justify="start">
      <v-col
        cols="8" offset="1" lg="2"
        offset-lg="4" sm="4" offset-sm="3"
        class="text-center"
      >
        <stores-filter :api-key="apiKey" class="store-filters" />
      </v-col>
    </v-row>
    <v-col v-if="tableIsLoading && isGridModeView" cols="12">
      <v-row>
        <v-col
          v-for="index in 3" :key="index" cols="12"
          md="6" lg="4"
        >
          <v-skeleton-loader type="card-avatar" elevation="2" />
        </v-col>
      </v-row>
    </v-col>
    <v-col v-else-if="isGridModeView" cols="12" class="accounts-row">
      <v-row align="stretch" class="justify-center justify-sm-start">
        <v-col
          v-for="(account, index) in accountsList"
          :key="index"
          lg="3" md="4" sm="6"
          cols="11" class="my-2 my-sm-4"
        >
          <account-grid-item
            class="mx-4"
            :account="account"
            @edit-account="editAccount"
            @account-click="accountClick"
            @broken-item-click="brokenItemClick"
          />
        </v-col>
      </v-row>
    </v-col>
    <v-col v-else cols="12">
      <company-table-view
        :api-key="apiKey"
        @edit-account="editAccount"
        @account-click="accountClick"
        @broken-item-click="brokenItemClick"
      />
    </v-col>
    <v-col v-if="showLoadMorePages" v-intersect.once="onIntersect" />
  </v-row>
</template>
<script>
import AccountEditDialog from '@/components/company_stores/AccountEditDialog.vue'
import StoresFilter from '@/components/company_stores/StoresFilter.vue'
import CompanyStats from '@/components/company_stores/CompanyStats.vue'
import CompanyTableView from '@/components/company_stores/CompanyTableView.vue'

import {
  getAccountList, getPagination, getClientDateComp, getIsLoading as tableIsLoading,
  loadAllAccounts, loadNextPage, isGridModeView, uiMode
} from '@/components/company_stores/AccountSelectionStore.js'

import useAuth from '@/composables/useAuth.js'
import useAccount from '@/composables/useAccount.js'
import useCompany from '@/composables/useCompany.js'

import { translateDayFromDate, getClientDateFormatted, dateToISO8601String } from '@/helpers/DateTime.js'
import { loadCompanyDistricts } from '@/models/CompanyDistrict.js'
import { isRtl, i18n } from '@/i18n.js'

import { handler } from '@/classes/ErrorHandler.js'

import { useRouter } from 'vue-router/composables'
import { ref, computed } from 'vue'

export default {
  components: {
    'account-edit-dialog': AccountEditDialog,
    'stores-filter': StoresFilter,
    'company-stats': CompanyStats,
    'company-table-view': CompanyTableView,
    'account-grid-item': () => import('@/components/company_stores/AccountGridItem.vue')
  },
  setup () {
    const accountEditDialog = ref(null)
    const router = useRouter()
    const { apiKey } = useAuth()
    const { companyImage, loadCompanyData } = useCompany(apiKey.value)
    const { accountCountryCode } = useAccount()

    // Methods
    const accountStatClick = () => router.push({ name: 'tasks', params: { tab: 'hqTasks', taskMode: 'accounts', clientDate: dateToISO8601String(getClientDateComp.value) } })
    const hqStatClick = () => router.push({ name: 'tasks', params: { tab: 'hqTasks', taskMode: 'crossAccount', clientDate: dateToISO8601String(getClientDateComp.value) } })
    const brokenItemStatClick = () => router.push({ name: 'staff/company_faulty_devices', params: { tab: 'brokenItems' } })

    const brokenItemClick = async (account) => router.push({ name: 'staff/company_faulty_devices', params: { apiKey: account.api_key, tab: 'brokenItems' } })
    const accountClick = async (account) => {
      router.push({ name: 'tasks', params: { tab: 'hqTasks', clientDate: dateToISO8601String(getClientDateComp.value), apiKey: account.api_key, taskMode: 'singleAccount' } })
    }

    const editAccount = (account) => {
      accountEditDialog.value.openDialog(account)
        .then(({ isUpdupdated, districtUpdated }) => {
          if (isUpdupdated !== true) return

          loadAllAccounts(apiKey.value, true)
            .catch(handler)

          if (districtUpdated !== true) return
          loadCompanyDistricts({ apiKey: apiKey.value, forceLoad: true })
        })
    }

    const onIntersect = () => loadNextPage(apiKey.value)

    // Computed
    const currentTranslatedDay = computed(() => i18n.t('currnent_day', { dayString: translateDayFromDate(getClientDateComp.value) }))
    const formmatedDate = computed(() => {
      const countryCode = accountCountryCode.value
      return getClientDateFormatted({ countryCode, fromDate: getClientDateComp.value })
    })
    const showLoadMorePages = computed(() => {
      if (tableIsLoading.value === true) return false

      const { page, totalPages } = getPagination.value
      return page < totalPages
    })

    loadCompanyData()
    loadCompanyDistricts({ apiKey: apiKey.value })
      .catch(handler)

    return {
      accountEditDialog,
      apiKey,
      formmatedDate,
      currentTranslatedDay,
      accountsList: getAccountList,
      pagination: getPagination,
      clientDate: getClientDateComp,
      showLoadMorePages,
      uiMode,
      companyImage,
      tableIsLoading,
      isRtl,
      isGridModeView,
      editAccount,
      onIntersect,
      brokenItemClick,
      accountClick,
      accountStatClick,
      hqStatClick,
      brokenItemStatClick
    }
  }
}
</script>
<style lang="scss">
.v-list.v-select-list {
  .v-list-item {
    padding-left: 4px;
    padding-right: 6px;
  }
}

.top-row {
  position: relative;
}

.triangle {
  position: absolute;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0 50vw 15px 50vw;
  border-color: transparent transparent white transparent;
  transform: rotate(0deg);
}

.account-select {
  position: relative;

  .stats-row {
    margin-top: -96px;
    z-index: 3;
  }

  .select-area {
    .v-text-field__details {
      display: none;
    }
  }

  @media only screen and (max-width: 600px) {

    .store-filters {
      top: 84px;
    }

    .accounts-row {
      margin-top: 70px;
    }
  }
}
</style>
