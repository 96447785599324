<template>
  <v-autocomplete
    :value="value"
    :items="jobsWithHeaders"
    :search-input.sync="search"
    :disabled="disabled"
    autocomplete="off"
    clearable
    item-text="name"
    :height="height"
    :menu-props="{ contentClass: contentClass }"
    item-value="id"
    multiple
    :loading="isLoading"
    :placeholder="$t('schedule_new_op_dialog.choose_job')"
    return-object
    @change="emitChange"
    @click:clear="clear"
    @input="update"
  >
    <template #item="{ item: job, attrs }">
      <v-list-item-action class="mme-4">
        <v-icon v-if="attrs.inputValue">
          check_box
        </v-icon>
        <v-icon v-else>
          check_box_outline_blank
        </v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>
          <span v-if="job.department_name" class="font-weight-bold pb-1">
            {{ job.department_name }}
            <v-icon>groups_2</v-icon>
          </span>
          <div class="font-weight-bold">
            {{ job.name }} - {{ translateShift(job.shift_name) }}
            <span v-if="showAccountName" class="black--text t-500-13">
              ({{ job.account_name }})
            </span>
          </div>
        </v-list-item-title>
      </v-list-item-content>
    </template>
    <template #selection="{ index }">
      <div v-if="index === 0" class="v-select__selection v-select__selection--comma">
        {{ $t('job_selection_search.x_jobs_selected', { job_count: value.length }) }}
      </div>
    </template>
  </v-autocomplete>
</template>
<script>
import { translateShift } from '@/store/modules/OrganizationJob.js'

import { companySearch as companyJobClientSearch } from '@/api_client/AccountJob.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'
import { isString } from '@/helpers/Utils.js'

import { isRtl } from '@/i18n'

import { ref, computed, watch } from 'vue'

const RESULTS_LIMIT = 400
export default {
  props: {
    apiKey: {
      type: String,
      required: true
    },
    value: {
      type: Array,
      required: false,
      default: () => {
        return []
      }
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    height: {
      type: [Number, String],
      required: false,
      default: undefined
    },
    accountApiKeys: {
      type: [Array, String],
      required: false,
      default: () => []
    }
  },
  setup (props, { emit }) {
    const isLoading = ref(false)
    const companyJobs = ref([])
    const search = ref(null)

    // Methods
    const clear = () => emit('click:clear')
    const update = (newValue) => emit('input', newValue)

    const emitChange = (newValue) => {
      if (newValue === null) return
      emit('change', newValue)
    }

    const loadCompanyJobs = () => {
      isLoading.value = true
      return companyJobClientSearch({ apiKey: props.apiKey, query: search.value, limit: RESULTS_LIMIT, accountApiKeys: props.accountApiKeys })
        .then((response) => (companyJobs.value = response.data.data))
        .catch(errHandler)
        .finally(() => (isLoading.value = false))
    }

    // Computed
    const showAccountName = computed(() => isString(search.value) && search.value.length > 0)
    const contentClass = computed(() => `company-job-select ${(isRtl.value ? 'rtl' : 'ltr')}`)
    const jobsWithHeaders = computed(() => {
      const result = []
      if (companyJobs.value.length === 0) return result
      const companyJobsVal = companyJobs.value
      let currentApiKey = null
      companyJobsVal.forEach((job) => {
        if (job.account_api_key !== currentApiKey) {
          result.push({ header: job.account_name })
          currentApiKey = job.account_api_key
        }
        result.push(job)
      })
      return result
    })

    loadCompanyJobs()

    watch(() => search.value, (query) => {
      if (isLoading.value === true) return
      if (isString(query) && companyJobs.value.some((job) => job.name === query)) return

      loadCompanyJobs()
    })
    watch(() => props.accountApiKeys, () => {
      loadCompanyJobs()
      const newValue = props.value.filter((job) => props.accountApiKeys.includes(job.account_api_key))
      update(newValue)
    }, { deep: true })
    return {
      isLoading,
      search,
      jobsWithHeaders,
      showAccountName,
      contentClass,
      clear,
      emitChange,
      update,
      translateShift
    }
  }
}
</script>
<style lang="scss">
.company-job-select {
  div.v-subheader {
    background-color: #E8E8E8;
    font-weight: 700;
  }
}
</style>
