<template>
  <v-expansion-panels v-model="expanded" flat>
    <v-expansion-panel>
      <v-expansion-panel-header class="elevation-0 bg-color17 px-0 sticky-collapse">
        <v-row
          align="center" justify="start" no-gutters
          class="t-700-16 color20"
        >
          <v-avatar size="36">
            <v-img :src="accountImage" />
          </v-avatar>
          <span class="pps-2">
            {{ account.name }}
          </span>
          <span v-if="showDistrictName">
            <v-divider
              class="mx-1 account-divider"
              vertical
            />

            {{ account.district_name }}
          </span>
          <span class="t-700-16 mms-5 totals px-2 py-1">
            <span class="color1">{{ account.finished_tasks_count }}</span> / <span class="color20"> {{ account.tasks_count }}</span>
          </span>
          <v-icon class="mms-2 collapse-icon" :class="{ rotated: isExpanded }">
            keyboard_arrow_down
          </v-icon>
        </v-row>
        <template #actions>
          &nbsp;
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="bg-color17">
        <hq-tasks-single-account-tasks-list :api-key="apiKey" :account="account" />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import HqTasksSingleAccountTasksList from '@/components/hq_tasks/hq_tasks_accounts/HqTasksSingleAccountTasksList.vue'

import { isString } from '@/helpers/Utils'
import { ref, computed } from 'vue'
export default {
  components: {
    'hq-tasks-single-account-tasks-list': HqTasksSingleAccountTasksList
  },
  props: {
    apiKey: {
      type: String,
      required: true
    },
    account: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const expanded = ref(null)

    const isExpanded = computed(() => expanded.value === 0)
    const accountImage = computed(() => isString(props.account.custom_store_image)
      ? props.account.custom_store_image
      : props.account.store_image)
    const showDistrictName = computed(() => isString(props.account.district_name))
    return {
      isExpanded,
      expanded,
      accountImage,
      showDistrictName
    }
  }
}
</script>
<style lang="scss">
.hq-task-account-list {
  .sticky-collapse {
    position: sticky;
    top: 0;
    z-index: 11;
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }
  .account-divider {
    height: 20px;
    background-color: #111;
  }
  .collapse-icon {
    transition: transform 0.3s ease;
  }
  .rotated {
    transform: rotate(180deg);
  }
  .totals{
    background-color: #EAEFFF;
    border-radius: 38px;
  }
}
</style>
