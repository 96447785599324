import useAccount from '@/composables/useAccount.js'

import { OperationStatuses } from '@/store/modules/OperationSchedule.js'
import { create as OpReportCreate, destroy as OpReportDestroy } from '@/api_client/OperationScheduleReport.js'
import { uploadImage as uploadImageToRemoteServer } from '@/general_client/CloudinaryImageUploader.js'
import { destroy as jobOpScheduleDestroy } from '@/api_client/JobOperationSchedule.js'
import {
  update as updateReportListItemApiCall, create as createReportListItem,
  batchUpsert as batchUpsertReportListItem
} from '@/api_client/OperationScheduleReportListItem.js'

import {
  getClientDate, clientDateToDate, isValidISO8601Date, getClientDateFormatted,
  timeStringToUserFormat, ISO8601StringToDate
} from '@/helpers/DateTime.js'
import { isString, isObject, isDate } from '@/helpers/Utils.js'
import Vue, { ref, nextTick } from 'vue'

const fieldsToResetAfterTaskUmark = ['operation_report_id', 'reporter_last_name', 'reporter_first_name', 'image_url', 'reportedDate', 'reportedTime']
const defaultReportDelay = 1500

export default class Operation {
  constructor (operation) {
    operation.processing = false
    operation.pendingStatusChange = false
    this.operationRef = ref(operation)
    nextTick(() => {
      const reportedDate = this.operation.report_date
      const reportedTime = this.operation.report_time
      const startTime = this.operation.start_time
      this.#setOperationReportTime({ reportedDate, reportedTime, startTime })
    })
  }

  get operation () {
    return this.operationRef
  }

  // Methods
  isReported = () => this.operation.status === OperationStatuses.done
  tasksList = () => this.operation.item_operation_list || []
  requireImage = () => this.operation.require_image === true
  markOperationAsCompleted = async (clientDate) => {
    this.#setProcessing(true)
    const status = OperationStatuses.done
    const response = await OpReportCreate(this.operation.account_api_key, this.operation.operation_id, status, getClientDate(clientDate), this.operation.shift_id)
    const operationResponse = response.data.data

    this.#setOperationStatus({ status, reportId: operationResponse.operation_report_id })
    this.#setOperationReporter({
      reporterFirstName: operationResponse.reporter_first_name,
      reporterLastName: operationResponse.reporter_last_name
    })
    this.#setOperationReportTime({ reportedDate: operationResponse.report_date, reportedTime: operationResponse.report_time })

    this.#setProcessing(false)
    return operationResponse
  }

  uploadImageForOperation = async ({ clientDate, file }) => {
    const response = await this.markOperationAsCompleted(clientDate)
    const { operation_report_id: reportId, ...params } = response || {}
    if (!reportId) return
    this.#setProperty('uploading_image', true)

    return uploadImageToRemoteServer(file, params)
      .then((imageUrl) => this.#setProperty('image_url', imageUrl))
      .catch((error) => {
        this.cancelOperationReport()
        throw error
      })
      .finally(() => this.#setProperty('uploading_image', false))
  }

  cancelOperationReport = async () => {
    this.#setProcessing(true)
    await OpReportDestroy(this.operation.account_api_key, this.operation.operation_id, this.operation.operation_report_id)

    this.#setOperationStatus({ status: OperationStatuses.notReported })
    fieldsToResetAfterTaskUmark.forEach((field) => Vue.delete(this.operation, field))
    this.#setProcessing(false)
  }

  updateOperationMessageCount = (messageCount) => this.#setProperty('total_messages_count', messageCount)
  updateOperationReadAllMessages = () => {
    if (('unread_messages_count' in this.operation)) {
      Vue.delete(this.operation, 'unread_messages_count')
    }
  }

  updateOperationNotifications = ({ onCompleteTaskNotification, onUnCompleteTaskNotification }) => {
    this.#setProperty('mark_complete_notification', onCompleteTaskNotification)
    this.#setProperty('mark_uncomplete_notification', onUnCompleteTaskNotification)
  }

  deleteOperation = async () => {
    this.#setProcessing(true)
    await jobOpScheduleDestroy(this.operation.account_api_key, this.operation.account_job_id, this.operation.operation_id)
    this.#setProcessing(false)
  }

  // UPSERT_REPORT_LIST_ITEM
  upsertReportListItem = async ({ requestParams, clientDate }) => {
    requestParams.shift_id = this.operation.shift_id
    return batchUpsertReportListItem(this.operation.account_api_key, this.operation.operation_id, requestParams)
      .then(async (response) => {
        const newList = response.data.data
        this.#setOperationListItems(newList)
        await this.#markOperationAfterItemListChange(clientDate)
      })
  }

  updateReportListItem = async ({ itemListId, requestParams, clientDate }) => {
    requestParams.shift_id = this.operation.shift_id
    return updateReportListItemApiCall(this.operation.account_api_key, this.operation.operation_id, itemListId, requestParams)
      .then(async (response) => {
        const responseData = response.data
        const listItem = responseData.reported_list_item
        this.#setOperationListItems([listItem])
        await this.#markOperationAfterItemListChange(clientDate)
        return { imageSignature: responseData.image_signature, listItem }
      })
  }

  createReportListItem = async ({ requestParams, clientDate }) => {
    requestParams.shift_id = this.operation.shift_id
    return createReportListItem(this.operation.account_api_key, this.operation.operation_id, requestParams)
      .then(async (response) => {
        const responseData = response.data
        const newListItem = responseData.reported_list_item
        this.#setOperationListItems([newListItem])
        await this.#markOperationAfterItemListChange(clientDate)
        return { imageSignature: responseData.image_signature, listItem: newListItem }
      })
  }

  uploadReportListItemImage = async ({ itemListId, requestParams, imageFile, clientDate, imagesCount, imageId }) =>
    uploadImageToRemoteServer(imageFile, requestParams)
      .catch((error) => {
        const boolValue = imagesCount > 1
        const updateRequestParams = { remove_image_by_id: imageId, reported_item_operation_list_item: { bool_value: boolValue } }
        this.updateReportListItem({ itemListId, requestParams: updateRequestParams, clientDate })
        throw error
      })

  // Private Methods
  #setProcessing = (processing) => (this.operation.processing = processing)

  #setOperationStatus = ({ status, reportId }) => {
    this.operation.pendingStatusChange = true
    const reportDelay = Number.isInteger(this.operation.reportDelay) ? this.operation.reportDelay : defaultReportDelay
    setTimeout(() => {
      this.operation.pendingStatusChange = false
    }, reportDelay)
    this.operation.status = status
    if (!Number.isInteger(reportId)) return

    Vue.set(this.operation, 'operation_report_id', reportId)
  }

  #setOperationReportTime = ({ reportedDate, reportedTime, startTime }) => {
    const { accountCountryCode, accountLocaleCode } = useAccount()
    const countryCode = accountCountryCode.value
    const localeCode = accountLocaleCode.value
    if (isValidISO8601Date(reportedDate)) {
      const reportDateUserFormat = getClientDateFormatted({ fromDate: ISO8601StringToDate(reportedDate), countryCode })
      this.#setProperty('reportedDate', reportDateUserFormat)
    }
    if (isString(reportedTime)) {
      const reportTimeUserFormat = timeStringToUserFormat({ timeString: reportedTime, localeCode })
      this.#setProperty('reportedTime', reportTimeUserFormat)
    }
    if (isString(startTime)) {
      const startTimeUserFormat = timeStringToUserFormat({ timeString: startTime, localeCode })
      this.#setProperty('startTime', startTimeUserFormat)
    }

    this.#findItemOperationList().forEach((item) => this.#addTimeForamtToItemOperationList({ item, countryCode, localeCode }))
  }

  #addTimeForamtToItemOperationList = ({ item, countryCode, localeCode }) => {
    let reportTime = null
    let reportDate = null
    let dateTime = null
    if (item.bool_value === true && isString(item.created_at)) {
      dateTime = new Date(item.created_at)
    }
    if (isDate(dateTime)) {
      reportTime = getClientDateFormatted({ fromDate: dateTime, countryCode })
      reportDate = dateTime.toLocaleTimeString(localeCode, { timeStyle: 'short' })
    }

    Vue.set(item, 'reportTime', reportTime)
    Vue.set(item, 'reportDate', reportDate)
  }

  #setOperationReporter = ({ reporterFirstName, reporterLastName }) => {
    if (!isString(reporterFirstName) || !isString(reporterLastName)) return

    Vue.set(this.operation, 'reporter_first_name', reporterFirstName)
    Vue.set(this.operation, 'reporter_last_name', reporterLastName)
  }

  #setProperty = (property, value) => {
    if (property in this.operation) {
      this.operation[property] = value
    } else {
      Vue.set(this.operation, property, value)
    }
  }

  #findItemOperationList = () => (this.operation.item_operation_list || [])
    .filter((itemOp) => itemOp.item_type !== 'title')

  #findItemOperationListItem = (itemListId) => this.#findItemOperationList().find((item) => item.id === itemListId)

  #setOperationListItems = (newList) => {
    const itemOperationList = this.#findItemOperationList()
    if (!Array.isArray(itemOperationList)) return
    const { accountCountryCode, accountLocaleCode } = useAccount()

    newList.forEach((listItem) => {
      const foundItem = itemOperationList.find((item) => item.id === listItem.item_operation_list_item_id)
      if (!foundItem) return
      Vue.set(foundItem, 'bool_value', listItem.bool_value)
      Vue.set(foundItem, 'string_value', listItem.string_value)
      Vue.set(foundItem, 'report_id', listItem.id)
      Vue.set(foundItem, 'images', listItem.images)
      Vue.set(foundItem, 'int_value', listItem.int_value)
      Vue.set(foundItem, 'created_at', listItem.created_at)
      Vue.set(foundItem, 'reporter_first_name', listItem.reporter_first_name)
      Vue.set(foundItem, 'reporter_last_name', listItem.reporter_last_name)
      this.#addTimeForamtToItemOperationList({ item: foundItem, countryCode: accountCountryCode.value, localeCode: accountLocaleCode.value })
    })
  }

  #markOperationAfterItemListChange = async (clientDate) => {
    const itemOperationList = this.#findItemOperationList()
    const allListIsMarkedAsDone = itemOperationList
      .filter((item) => item.item_type !== 'title' && item.item_type !== 'feedback')
      .every((item) => item.bool_value === true && Number.isInteger(item.report_id))
    if (allListIsMarkedAsDone && this.operation.status !== OperationStatuses.done) {
      if (this.operation.require_image !== true) {
        const clientDateObj = clientDateToDate(clientDate)
        await this.markOperationAsCompleted(clientDateObj)
      }
    }
    if (!allListIsMarkedAsDone && this.operation.status !== OperationStatuses.notReported) {
      await this.cancelOperationReport()
    }
  }

  #setOperationListItemProperty = (itemListId, property, value) => {
    const foundItem = this.#findItemOperationListItem(itemListId)
    if (!isObject(foundItem)) return
    if (property in foundItem) {
      foundItem[property] = value
    } else {
      Vue.set(foundItem, property, value)
    }
  }
}
