<template>
  <v-row align="center" justify="center" class="hq-tasks-empty">
    <v-col cols="12" class="text-center color10 t-600-24">
      {{ emptyPageText }}
      <v-img
        :src="emptyTaskImage" contain max-width="250"
        class="mx-auto"
      />
    </v-col>
  </v-row>
</template>

<script>
import useHQTasksFilter from '@/composables/useHQTasksFilter.js'

import { i18n } from '@/i18n.js'

import { computed } from 'vue'
export default {
  setup () {
    const emptyTaskImage = new URL('@/assets/empty_tasks.png', import.meta.url).href
    const { anyFilterSelected } = useHQTasksFilter()

    const emptyPageText = computed(() => anyFilterSelected.value ? i18n.t('no_result') : i18n.t('hq_tasks_page.no_tasks'))
    return {
      emptyPageText,
      emptyTaskImage
    }
  }
}
</script>
<style lang="scss">
.hq-tasks-empty {
  height: 60vh ;
}
</style>
