<template>
  <tr class="text-center">
    <td v-if="showAccountData">
      <div class="t-700-16">
        {{ accountNameAndDistrict }}
        <v-icon v-if="showUrgentIcon" size="20" color="#FF1744">
          local_fire_department
        </v-icon>
      </div>
    </td>
    <td class="t-500-16 color5">
      <div v-if="showCategory">
        <v-chip color="#111" x-small class="t-500-13 white--text">
          {{ brokenItem.item_category_name }}
        </v-chip>
      </div>
      <div>
        <span>{{ brokenItem.item_name }}</span>
        <span v-if="brokenItem.item_department">
          - {{ brokenItem.item_department }}
        </span>
        <v-icon v-if="showUrgentIcon && !showAccountData" size="20" color="#FF1744">
          local_fire_department
        </v-icon>
      </div>
    </td>
    <td class="py-1">
      <v-row align="center" justify="center" no-gutters>
        <v-col cols="12" class="t-500-13 color5 mb-2">
          {{ brokenItem.description }}
        </v-col>
        <media-preview
          v-if="showMediaPreview"
          :media-urls="mediaPreviewUrls"
          @click.native="mediaClick"
        />
      </v-row>
    </td>
    <td>
      <span v-if="hasAssignees" class="t-500-16 color5">
        <v-tooltip top :disabled="!hasMultipleAssignees">
          <template #activator="{ on }">
            <span v-on="on">
              {{ assignedUserName }}
            </span>
          </template>
          <span v-if="hasMultipleAssignees">
            <div v-for="(assigned_user, index) in brokenItem.assigned_users" :key="index">
              {{ assigned_user.first_name }} {{ assigned_user.last_name }}
            </div>
          </span>
        </v-tooltip>
      </span>
    </td>
    <td class="py-1">
      <span v-if="hasRelatedServiceProviders" class="t-500-16 color5">
        <v-tooltip top :disabled="!hasMultipleRelatedServiceProviders">
          <template #activator="{ on }">
            <span v-on="on">
              {{ serviceProviderName }}
            </span>
          </template>
          <span v-if="hasMultipleRelatedServiceProviders">
            <div v-for="provider in brokenItem.related_providers" :key="provider.id">
              {{ provider.name }}
            </div>
          </span>
        </v-tooltip>
      </span>
    </td>
    <td>
      <span class="t-500-16 color5 pointer" @click="onChatClick">
        <v-badge
          overlap
          color="#EC2328"
          dot
          :value="hasUnreadMessages"
        >
          <v-btn
            text small
            icon class="black--text"
          >
            <v-icon>chat_bubble_outline</v-icon>
          </v-btn>
        </v-badge>

        (<span class="text-decoration-underline">{{ brokenItem.chat_messages_count }}</span>)

      </span>
    </td>
    <td class="t-500-16 color5">
      <div>
        {{ brokenItem.creator_first_name }} {{ brokenItem.creator_last_name }}
      </div>
      <span>
        {{ brokenItem.open_date_user_format }}
      </span>
      <span v-if="hasOpenTime">
        {{ brokenItem.open_time }}
      </span>
      <div v-if="hasNumberOfDaysOpen" class="t-700-13">
        ( {{ $t('broken_item_dialog_dialog.open_days', { days: brokenItem.numberDaysOpen }) }})
      </div>
    </td>
    <td>
      <v-row align="center" justify="start" no-gutters>
        <v-col cols="2" />
        <v-col cols="8">
          <v-menu offset-y :disabled="!allowEdit">
            <template #activator="{ on, attrs }">
              <v-chip
                v-bind="attrs" :color="statusColor" class="t-500-14 full-width"
                v-on="on"
              >
                {{ statusName }}
                <v-icon>keyboard_arrow_down</v-icon>
              </v-chip>
            </template>
            <v-list>
              <v-list-item v-for="status in statusesOptions" :key="status.name" @click="onChangeStatus(status.name)">
                <v-list-item-title>{{ status.translatedName }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
        <v-col cols="2">
          <v-menu offset-y>
            <template #activator="{ on, attrs }">
              <v-btn
                small icon v-bind="attrs"
                v-on="on"
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-if="allowEdit" @click="onEdit">
                <v-icon>edit</v-icon>
                <v-list-item-content class="px-1">
                  {{ $t('edit') }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="onShare">
                <v-icon>share</v-icon>
                <v-list-item-content class="px-1">
                  {{ $t('share') }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="allowDelete" @click="onDelete">
                <v-icon>delete</v-icon>
                <v-list-item-content class="px-1">
                  {{ $t('delete') }}
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
        <v-col cols="2" />
        <v-col cols="8">
          <broken-item-deadline-progress
            v-if="showProgress" class="my-2" :broken-item="brokenItem"
            background-color="rgba(221, 221, 221, 0.60)"
          />
        </v-col>
      </v-row>
    </td>
  </tr>
</template>

<script>
import BrokenItemDeadlineProgress from '@/components/shared/single_broken_item/BrokenItemDeadlineProgress.vue'

import { getAllStatuses, statusColors, statuses } from '@/store/modules/BrokenItem.js'
import { isString } from '@/helpers/Utils.js'
import { i18n } from '@/i18n.js'

import { computed } from 'vue'
export default {
  components: {
    'media-preview': () => import('@/components/shared/MediaPreview.vue'),
    'broken-item-deadline-progress': BrokenItemDeadlineProgress
  },
  props: {
    brokenItem: {
      type: Object,
      required: true
    },
    showAccountData: {
      type: Boolean,
      default: false
    },
    allowEdit: {
      type: Boolean,
      required: true
    },
    allowDelete: {
      type: Boolean,
      required: true
    },
    showCloseOption: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    const closeTranslation = i18n.t('broken_item.close_ticket')
    const statusesOptions = getAllStatuses({ addClosedStatus: props.showCloseOption, closeTranslation })

    // Methods
    const mediaClick = () => emit('media-click', mediaPreviewUrls)
    const onShare = () => emit('share-broken-item', props.brokenItem)
    const onChatClick = () => emit('open-chat', props.brokenItem)
    const onEdit = () => emit('edit-broken-item', props.brokenItem)
    const onDelete = () => emit('delete-broken-item', props.brokenItem)
    const onChangeStatus = (newStatus) => {
      if (newStatus === props.brokenItem.status) return
      emit('change-status', { brokenItem: props.brokenItem, status: newStatus })
    }
    // Computed
    const showAccountName = computed(() => props.showAccountData && isString(props.brokenItem.account_name))
    const showCategory = computed(() => isString(props.brokenItem.item_category_name))
    const showUrgentIcon = computed(() => props.brokenItem.priority === 'high')
    const hasNumberOfDaysOpen = computed(() => Number.isInteger(props.brokenItem.numberDaysOpen))
    const showProgress = computed(() => props.brokenItem.status !== statuses.close && props.brokenItem.deadline_date != null)
    const hasAssignees = computed(() => {
      const assignedUsers = props.brokenItem.assigned_users
      return Array.isArray(assignedUsers) && assignedUsers.length > 0
    })
    const hasMultipleAssignees = computed(() => {
      const assignedUsers = props.brokenItem.assigned_users
      return Array.isArray(assignedUsers) && assignedUsers.length > 1
    })

    const assignedUserName = computed(() => {
      const assignedUser = props.brokenItem.assigned_users[0]
      const name = `${assignedUser.first_name} ${assignedUser.last_name}`
      if (props.brokenItem.assigned_users.length > 1) {
        const assigneesLength = props.brokenItem.assigned_users.length - 1
        return `${name} (+${assigneesLength})`
      }
      return `${assignedUser.first_name} ${assignedUser.last_name}`
    })
    const hasRelatedServiceProviders = computed(() => {
      const relatedServiceProviders = props.brokenItem.related_providers
      return Array.isArray(relatedServiceProviders) && relatedServiceProviders.length > 0
    })
    const hasMultipleRelatedServiceProviders = computed(() =>
      hasRelatedServiceProviders.value && props.brokenItem.related_providers.length > 1)

    const serviceProviderName = computed(() => {
      const providers = props.brokenItem.related_providers
      const serviceProvider = providers[0]
      if (providers.length > 1) {
        const providersLength = providers.length - 1
        return `${serviceProvider.name} (+${providersLength})`
      }
      return serviceProvider.name
    })

    const hasUnreadMessages = computed(() => Number.isInteger(props.brokenItem.unread_messages_count) && props.brokenItem.unread_messages_count > 0)
    const hasOpenTime = computed(() => isString(props.brokenItem.open_time))
    const statusColor = computed(() => statusColors[props.brokenItem.status])
    const accountNameAndDistrict = computed(() => {
      if (!showAccountName.value) return ''
      const result = [props.brokenItem.account_name]
      if (isString(props.brokenItem.district_name)) {
        result.push(props.brokenItem.district_name)
      }
      return result.join(' - ')
    })
    const mediaPreviewUrls = computed(() => {
      const media = (props.brokenItem.images || [])
        .map((image) => ({ url: image.url, type: 'image' }))
      const video = props.brokenItem.attached_video_url
      if (isString(video) && video.length > 0) media.push({ url: video, type: 'video' })
      return media
    })
    const statusName = computed(() => {
      const status = props.brokenItem.status
      return statusesOptions.find(statusRow => statusRow.name === status)?.translatedName
    })

    const showMediaPreview = computed(() => mediaPreviewUrls.value.length > 0)
    return {
      accountNameAndDistrict,
      showCategory,
      showUrgentIcon,
      showMediaPreview,
      mediaPreviewUrls,
      assignedUserName,
      hasAssignees,
      hasNumberOfDaysOpen,
      hasMultipleAssignees,
      hasOpenTime,
      statusName,
      hasUnreadMessages,
      statusesOptions,
      showProgress,
      statusColor,
      hasRelatedServiceProviders,
      hasMultipleRelatedServiceProviders,
      serviceProviderName,
      mediaClick,
      onChangeStatus,
      onChatClick,
      onEdit,
      onDelete,
      onShare
    }
  }
}
</script>
