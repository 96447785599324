
import useAccount from '@/composables/useAccount.js'

import { newBrokenItemModel, getAllStatuses, getAllPriorities } from '@/store/modules/BrokenItem.js'

import { index as CompanyBrokenItemIndex, stats as CompanyBrokenItemStats } from '@/api_client/CompanyBrokenItem.js'
import { update as BrokenItemClientUpdate, destroy as BrokenItemClientDestroy } from '@/api_client/BrokenItem.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'
import { dateToISO8601String, ISO8601StringToDate, getCurrentLocalISODate } from '@/helpers/DateTime.js'
import { deadlineApiFilter } from '@/classes/DeadlineFilter.js'

import { isString, isObject, isDate } from '@/helpers/Utils.js'
import { ref, computed } from 'vue'

const SESSION_FILTER_KEY = 'company_broken_item_filter'
const DEFAULT_PAGE = 1
const DEFAULT_PER_PAGE = 40
const uiModes = { list: 'list', grid: 'grid' }
const defaultPagination = () => ({ page: DEFAULT_PAGE, perPage: DEFAULT_PER_PAGE, total: null, totalPages: null })

const isLoading = ref(false)
const isListLoading = ref(false)
const isListLoadingNextPage = ref(false)
const pagination = ref(defaultPagination())
const brokenItemsList = ref([])

const onlyBrokenItemAssignedToMe = ref(false)
const onlyBrokenItemOpenedByMe = ref(false)
const brokenItemStatusFilter = ref([])
const brokenItemPriorityFilter = ref(null)
const companyDistrictFilter = ref([])
const accountstFilter = ref([])
const deadlineDateFilter = ref(null)
const openDateStartFilter = ref(null)
const openDateEndFilter = ref(null)
const textSearchFilter = ref(null)
const companyProvidersFilter = ref([])
const assignedUsersFilter = ref([])
const stats = ref({})
const uiMode = ref(uiModes.grid)
let currentApiKey = null

export default function useCompanyBrokenItem (apiKey) {
  const availableStatuses = ref(getAllStatuses())
  const availablePriorities = ref(getAllPriorities())
  const { accountLocaleCode } = useAccount()
  const locale = accountLocaleCode.value

  const resetValues = () => {
    isLoading.value = false
    isListLoading.value = false
    isListLoadingNextPage.value = false
    pagination.value = defaultPagination()
    brokenItemsList.value = []
    onlyBrokenItemAssignedToMe.value = false
    onlyBrokenItemOpenedByMe.value = false
    brokenItemStatusFilter.value.splice(0, brokenItemStatusFilter.value.length)
    brokenItemPriorityFilter.value = null
    companyDistrictFilter.value.splice(0, companyDistrictFilter.value.length)
    accountstFilter.value.splice(0, accountstFilter.value.length)
    deadlineDateFilter.value = null
    openDateStartFilter.value = null
    openDateEndFilter.value = null
    textSearchFilter.value = null
    companyProvidersFilter.value.splice(0, companyProvidersFilter.value.length)
    assignedUsersFilter.value.splice(0, assignedUsersFilter.value.length)
    stats.value = {}
  }

  if (isString(apiKey) && currentApiKey !== apiKey) {
    resetValues()
    currentApiKey = apiKey
  }

  const getTotalPages = ({ total, perPage }) => {
    const totalItems = total || 0
    if (totalItems === 0 || totalItems <= perPage) {
      return 0
    }
    return Math.ceil(totalItems / perPage)
  }

  const setPaginationFromResponse = (paginationVal, response) => {
    paginationVal.total = response.total
    paginationVal.page = response.page
    paginationVal.perPage = response.per_page
    paginationVal.totalPages = getTotalPages(paginationVal)
  }
  const addDeadlineDateFilter = (requestParams) => {
    const apiParams = deadlineApiFilter(deadlineDateFilter.value)
    if (apiParams === null) return
    requestParams = Object.assign(requestParams, apiParams)
  }

  const apiFilterParams = () => {
    const filterParams = {}
    if (onlyBrokenItemAssignedToMe.value === true) filterParams.assigned_to_me = true
    if (onlyBrokenItemOpenedByMe.value === true) filterParams.opened_by_me = true
    if (brokenItemStatusFilter.value.length > 0) filterParams.status = brokenItemStatusFilter.value
    if (brokenItemPriorityFilter.value !== null) filterParams.priority = brokenItemPriorityFilter.value
    if (companyDistrictFilter.value.length > 0) filterParams.company_districts_ids = companyDistrictFilter.value
    if (accountstFilter.value.length > 0) filterParams.api_keys = accountstFilter.value
    if (isDate(openDateStartFilter.value)) filterParams.open_date = [dateToISO8601String(openDateStartFilter.value)]
    if (isDate(openDateEndFilter.value) && isDate(openDateStartFilter.value)) filterParams.open_date.push(dateToISO8601String(openDateEndFilter.value))
    if (isString(textSearchFilter.value) && textSearchFilter.value.trim().length > 0) filterParams.text_search = textSearchFilter.value
    if (companyProvidersFilter.value.length > 0) filterParams.company_providers_ids = companyProvidersFilter.value.map((provider) => provider)
    if (assignedUsersFilter.value.length > 0) filterParams.assigned_users_ids = assignedUsersFilter.value
    addDeadlineDateFilter(filterParams)
    return filterParams
  }
  const indexParams = () => {
    const requestParams = apiFilterParams()
    const paginationVal = pagination.value
    requestParams.page = paginationVal.page
    requestParams.per_page = paginationVal.perPage
    return requestParams
  }

  const removeItemFromList = (brokenItemId) => {
    const index = brokenItemsList.value.findIndex((brokenItem) => brokenItem.id === brokenItemId)
    if (index !== -1) brokenItemsList.value.splice(index, 1)
  }

  const saveFilterToLocalCache = () => {
    const currentFilter = apiFilterParams()
    currentFilter.deadLineFilter = deadlineDateFilter.value
    currentFilter.uiMode = uiMode.value
    localStorage.setItem(SESSION_FILTER_KEY, JSON.stringify(currentFilter))
  }

  const loadFilterFromLocalCache = () => {
    const filter = localStorage.getItem(SESSION_FILTER_KEY)
    if (isString(filter) !== true) return
    try {
      return JSON.parse(filter)
    } catch (e) {
    }
  }

  const setFiltersFromCache = () => {
    const filtersCache = loadFilterFromLocalCache()
    if (isObject(filtersCache) !== true) return

    if (Array.isArray(filtersCache.api_keys)) accountstFilter.value = filtersCache.api_keys
    if (filtersCache.assigned_to_me === true) onlyBrokenItemAssignedToMe.value = true
    if (Array.isArray(filtersCache.company_districts_ids)) companyDistrictFilter.value = filtersCache.company_districts_ids
    if (filtersCache.opened_by_me === true) onlyBrokenItemOpenedByMe.value = true
    if (Array.isArray(filtersCache.status)) brokenItemStatusFilter.value = filtersCache.status
    if (isString(filtersCache.priority)) brokenItemPriorityFilter.value = filtersCache.priority
    if (isString(filtersCache.deadLineFilter)) deadlineDateFilter.value = filtersCache.deadLineFilter

    if (Array.isArray(filtersCache.open_date)) openDateStartFilter.value = ISO8601StringToDate(filtersCache.open_date[0])
    if (Array.isArray(filtersCache.open_date) && filtersCache.open_date.length === 2) openDateEndFilter.value = ISO8601StringToDate(filtersCache.open_date[1])
    if (isString(filtersCache.text_search)) textSearchFilter.value = filtersCache.text_search
    if (Array.isArray(filtersCache.company_providers_ids)) companyProvidersFilter.value = filtersCache.company_providers_ids
    if (Array.isArray(filtersCache.assigned_users_ids)) assignedUsersFilter.value = filtersCache.assigned_users_ids
    if (isString(filtersCache.uiMode) && Object.values(uiModes).includes(filtersCache.uiMode)) uiMode.value = filtersCache.uiMode
  }

  const loadStats = () => {
    const requestParams = apiFilterParams()
    requestParams.client_date = getCurrentLocalISODate(new Date())
    return CompanyBrokenItemStats(apiKey, requestParams)
      .then(({ data: { data } }) => (stats.value = data))
  }

  const loadBrokenItems = () => {
    if (pagination.value.page === 1) {
      isListLoading.value = true
    } else {
      isListLoadingNextPage.value = true
    }

    return CompanyBrokenItemIndex(apiKey, indexParams())
      .then(({ data: { data, meta } }) => {
        const newBrokenItems = data.map((brokenItem) => newBrokenItemModel({ src: brokenItem, locale }))
        brokenItemsList.value = brokenItemsList.value.concat(newBrokenItems)

        setPaginationFromResponse(pagination.value, meta)
      })
      .finally(() => {
        isListLoading.value = false
        isListLoadingNextPage.value = false
      })
  }

  // Methods
  const onToggleMode = () => saveFilterToLocalCache()
  const initPageData = ({ apiKey = null } = {}) => {
    resetValues()
    setFiltersFromCache()
    if (isString(apiKey) && apiKey.length > 0) {
      companyDistrictFilter.value = []
      accountstFilter.value = [apiKey]
    }
    return Promise.all([loadBrokenItems(), loadStats()])
  }

  const deleteBrokenItem = (brokenItem) => {
    isLoading.value = true
    return BrokenItemClientDestroy(brokenItem.account_api_key, brokenItem.item_id, brokenItem.id)
      .then(() => {
        removeItemFromList(brokenItem.id)
        return loadStats()
      })
      .catch(errHandler)
      .finally(() => (isLoading.value = false))
  }

  const setBrokenItemStatusToClose = (brokenItem) => {
    isLoading.value = true
    brokenItem.setClosingTime()
    return BrokenItemClientUpdate(brokenItem.account_api_key, brokenItem.item_id,
      brokenItem.id, { broken_item: brokenItem.serialize() })
      .then(() => {
        removeItemFromList(brokenItem.id)
        return loadStats()
      })
      .catch(errHandler)
      .finally(() => (isLoading.value = false))
  }

  const loadNextPage = () => {
    if (pagination.value.page < pagination.value.totalPages) {
      pagination.value.page++
      loadBrokenItems()
    }
  }

  const applyFilters = () => {
    saveFilterToLocalCache()
    pagination.value.page = DEFAULT_PAGE
    brokenItemsList.value = []
    Promise.all([loadBrokenItems(), loadStats()])
  }

  const isGridModeView = computed(() => uiMode.value === uiModes.grid)

  return {
    DEFAULT_PER_PAGE,
    isLoading,
    isListLoading,
    pagination,
    brokenItemsList,
    isListLoadingNextPage,
    onlyBrokenItemAssignedToMe,
    onlyBrokenItemOpenedByMe,
    brokenItemStatusFilter,
    brokenItemPriorityFilter,
    companyDistrictFilter,
    textSearchFilter,
    accountstFilter,
    deadlineDateFilter,
    openDateStartFilter,
    openDateEndFilter,
    companyProvidersFilter,
    assignedUsersFilter,
    availableStatuses,
    availablePriorities,
    stats,
    uiMode,
    uiModes,
    isGridModeView,
    deleteBrokenItem,
    setBrokenItemStatusToClose,
    loadNextPage,
    applyFilters,
    initPageData,
    onToggleMode
  }
}
