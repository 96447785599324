<template>
  <v-textarea
    class="description-text"
    :value="value"
    counter="240"
    :label="$t('devices_new_operation.op_desc')"
    auto-grow
    no-resize
    rows="1"
    @input="update"
  />
</template>
<script>

export default {
  props: {
    value: {
      type: String,
      required: false,
      default: null
    },
    operationType: {
      type: String,
      required: false,
      default: null
    }
  },
  setup (props, { emit }) {
    const update = (newVal) => emit('input', newVal)
    return {
      update
    }
  }
}
</script>
