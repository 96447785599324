<template>
  <v-row align="start" justify="start" no-gutters>
    <v-col v-if="isLoadingTasks" cols="12">
      <v-skeleton-loader
        v-for="i in 3" :key="i" class="mt-3"
        type="list-item-three-line"
      />
    </v-col>
    <v-col v-else-if="isEmptyPage" cols="12">
      <hq-tasks-empty-page />
    </v-col>
    <v-col v-else cols="12">
      <hq-tasks-cross-account-actions :api-key="apiKey" :client-date="clientDate" />
      <v-row
        class="mt-3"
        align="start" justify="start"
      >
        <v-col
          v-for="task in crossAccountTasksList" :key="task.cross_account_operation_id" cols="12"
          class="py-2"
        >
          <single-cross-account-task-layout :api-key="apiKey" :task="task" :client-date="clientDate" />
        </v-col>
      </v-row>
    </v-col>
    <v-col v-if="isLoadingTasksNextPage" cols="12">
      <v-skeleton-loader
        v-for="i in 3" :key="i" class="mt-3"
        type="list-item-three-line"
      />
    </v-col>
    <v-col v-if="hasMoreTasksToLoad" v-intersect.once="onIntersect" cols="12" />
  </v-row>
</template>
<script>

import HqTasksCrossAccountActions from '@/components/hq_tasks/hq_tasks_cross_account/HqTasksCrossAccountActions.vue'
import HqTasksEmptyPage from '@/components/hq_tasks/HqTasksEmptyPage.vue'
import SingleCrossAccountTaskLayout from '@/components/hq_tasks/hq_tasks_cross_account/SingleCrossAccountTaskLayout.vue'
import useHQCrossAccountDailyTasks from '@/composables/useHQCrossAccountDailyTasks.js'

import { dateToISO8601String } from '@/helpers/DateTime.js'

import { watch } from 'vue'
export default {
  components: {
    'single-cross-account-task-layout': SingleCrossAccountTaskLayout,
    'hq-tasks-empty-page': HqTasksEmptyPage,
    'hq-tasks-cross-account-actions': HqTasksCrossAccountActions
  },
  props: {
    apiKey: {
      type: String,
      required: true
    },
    clientDate: {
      type: Date,
      required: true
    }
  },
  setup (props) {
    const {
      isLoadingTasks, isLoadingTasksNextPage, hasMoreTasksToLoad, crossAccountTasksList,
      isEmptyPage, initPageData, loadNextPage, changeDate
    } = useHQCrossAccountDailyTasks(props.apiKey)

    const onIntersect = loadNextPage

    initPageData()

    watch(() => props.clientDate, (newDate, oldDate) => {
      if (dateToISO8601String(newDate) === dateToISO8601String(oldDate)) return

      changeDate(newDate)
    })
    return {
      isLoadingTasks,
      isLoadingTasksNextPage,
      hasMoreTasksToLoad,
      crossAccountTasksList,
      isEmptyPage,
      onIntersect
    }
  }
}
</script>
