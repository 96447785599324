<template>
  <v-col
    cols="10"
    class="chat-message"
    :class="{ 'texts-end mms-auto': !isMessageFromCurrentUser }"
  >
    <v-chip
      :color="isMessageFromCurrentUser ? '#EEFFE0' : 'white'"
      :class="{ 'current-user-message': isMessageFromCurrentUser }"
      dark
      class="pa-4 mb-2 texts-start"
    >
      <v-row no-gutters align="center" class="color20">
        <v-col cols="10" class="texts-start t-500-14" :style="{ color: userColorHash }">
          {{ message.displayName }}
        </v-col>
        <v-col cols="2" class="texts-end">
          <v-btn
            v-if="showDelete" icon
            class="pl-1" color="#8993AF"
            :disabled="message.deleteInProgress"
            :loading="message.deleteInProgress"
            @click="deleteMessage"
          >
            <v-icon class="material-icons-outlined">
              delete
            </v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" class="t-700-16">
          {{ messageContent }}
        </v-col>
        <v-col cols="9" class="t-500-12 color19">
          {{ message.created_at_time }}
        </v-col>
        <v-col cols="3" class="texts-end">
          <v-btn
            v-if="hasTranslation"
            :class="{ 'original-text': !showTranslatedContent }"
            small
            icon @click="onTranslationClick"
          >
            <v-icon color="#0E4AFD">
              translate
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-chip>
  </v-col>
</template>
<script>
import useAuth from '@/composables/useAuth.js'

import { userToColor } from '@/classes/UserToColor.js'
import { isString } from '@/helpers/Utils.js'

import { computed, ref } from 'vue'

export default {
  props: {
    message: {
      type: Object,
      required: true
    }
  },
  setup (props, { emit }) {
    const { currentUser, isAccountAdminOrAbove } = useAuth()

    const showTranslatedContent = ref(isString(props.message.content_translated))
    // Methods
    const deleteMessage = () => emit('delete-message', props.message)
    const onTranslationClick = () => (showTranslatedContent.value = !showTranslatedContent.value)

    // Computed
    const isMessageFromCurrentUser = computed(() => props.message.user_id === currentUser.value.id)
    const hasTranslation = computed(() => isString(props.message.content_translated))
    const showDelete = computed(() => isMessageFromCurrentUser.value || isAccountAdminOrAbove.value)
    const messageContent = computed(() => {
      if (hasTranslation.value !== true) return props.message.content
      return showTranslatedContent.value === true ? props.message.content_translated : props.message.content
    })
    const userColorHash = computed(() => {
      if (isMessageFromCurrentUser.value === true) return 'black'

      const color = userToColor({ firstName: props.message.first_name, lastName: props.message.last_name })
      return isString(color) ? color : 'black'
    })
    return {
      currentUser,
      isMessageFromCurrentUser,
      showDelete,
      userColorHash,
      hasTranslation,
      showTranslatedContent,
      messageContent,
      deleteMessage,
      onTranslationClick
    }
  }
}
</script>
<style lang="scss">
.chat-message {
  .v-chip {
    height: auto;
    white-space: normal;
    border-radius: 24px 0px 24px 24px;
    &.current-user-message {
      border-radius: 0px 24px 24px 24px;
      color: #111;
    }
    .original-text {
      opacity: 0.3;
    }
  }
}
.rtl {
  .chat-message {
    .v-chip {
      border-radius: 0px 24px 24px 24px;
      &.current-user-message {
        border-radius: 24px 0px 24px 24px;
      }
    }
  }
}
</style>
