<template>
  <v-row
    align="center" justify="space-between" no-gutters
    class="pt-2"
  >
    <v-col class="flex-grow-0 flex-shrink-1">
      <v-menu
        v-if="allowEdit" offset-y top
      >
        <template #activator="{ on, attrs }">
          <v-chip
            :style="{ 'border-color': statusColor }"
            v-bind="attrs" small class="status-chip"
            v-on="on"
          >
            {{ statusName }}
            <v-icon>keyboard_arrow_down</v-icon>
          </v-chip>
        </template>
        <v-list :class="{ 'rtl': isRtl, 'ltr': !isRtl }">
          <v-list-item v-for="status in statuses" :key="status.name" @click="onChangeStatus(status.name)">
            <v-list-item-title>{{ status.translatedName }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>
    <v-col class="flex-grow-1 flex-shrink-0 texts-end">
      <share-content
        :absolute="false" :direction="`top`" :share-content="createShareContent"
        class="d-inline-block"
      >
        <template #share>
          <v-chip small class="pointer chip-border">
            {{ $t('share') }}
          </v-chip>
        </template>
      </share-content>
      <v-menu
        v-if="showOptions" offset-y top
        class="d-inline-block pps-4"
      >
        <template #activator="{ on, attrs }">
          <v-chip
            small v-bind="attrs" class="chip-border"
            v-on="on"
          >
            {{ $t('broken_item.options') }}
          </v-chip>
        </template>
        <v-list :class="{ 'rtl': isRtl, 'ltr': !isRtl }">
          <v-list-item v-if="allowEdit" class="px-2" @click="onEdit">
            <v-list-item-content class="px-1">
              {{ $t('edit') }}
            </v-list-item-content>
            <v-icon>edit</v-icon>
          </v-list-item>
          <v-list-item v-if="allowDelete" class="px-2" @click="onDelete">
            <v-list-item-content class="px-1">
              {{ $t('delete') }}
            </v-list-item-content>
            <v-icon>delete</v-icon>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
import { statusSecondColors, getAllStatuses } from '@/store/modules/BrokenItem.js'
import { createShareContent as createBrokenItemShareContent } from '@/classes/BrokenItemShareContent.js'
import { i18n, isRtl } from '@/i18n.js'
import { computed, watch, ref } from 'vue'
export default {
  components: {
    'share-content': () => import('@/components/shared/ShareContent.vue')
  },
  props: {
    brokenItem: {
      type: Object,
      required: true
    },
    allowDelete: {
      type: Boolean,
      required: true
    },
    allowEdit: {
      type: Boolean,
      required: true
    },
    showCloseOption: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    const statuses = ref([])
    const statusName = computed(() => {
      const status = props.brokenItem.status
      return statuses.value.find(statusRow => statusRow.name === status)?.translatedName
    })

    // Methods
    const onEdit = () => emit('edit-broken-item')
    const onDelete = () => emit('delete-broken-item')
    const onChangeStatus = (newStatus) => {
      if (newStatus === props.brokenItem.status) return
      emit('change-status', newStatus)
    }
    const createShareContent = () =>
      createBrokenItemShareContent({ brokenItem: props.brokenItem, apiKey: props.brokenItem.account_api_key })

    // Computed
    const showOptions = computed(() => props.allowDelete || props.allowEdit)
    const statusColor = computed(() => statusSecondColors[props.brokenItem.status])
    watch(() => props.showCloseOption, (isShow) => {
      const closeTranslation = i18n.t('broken_item.close_ticket')
      statuses.value = getAllStatuses({ addClosedStatus: isShow, closeTranslation })
    }, { immediate: true })
    return {
      statuses,
      statusName,
      showOptions,
      isRtl,
      statusColor,
      onChangeStatus,
      createShareContent,
      onEdit,
      onDelete
    }
  }
}
</script>

<style lang="scss">
.single-broken-item {
  .v-speed-dial--bottom {
    bottom: unset;
    right: unset;
  }

  .chip-border {
    border: 1px #011D35 solid;
    background: unset !important;
  }

  .status-chip {
    border: 1.5px solid;
    background-color: #FFF !important;
  }
}
</style>
