import Vue from 'vue'
import { newDeviceOperationModel } from '@/store/modules/DeviceOperation.js'
const filedList = ['id', 'general', 'type', 'name', 'broken_item_stats', 'category_id', 'category_name']
function ObjectModel (src) {
  const srcOrEmpty = src || {}
  const departmentName = (srcOrEmpty.department || {}).name

  Object.assign(this,
    {
      department: { name: departmentName },
      operations: srcOrEmpty.operations || []
    })
  filedList.forEach((field) => {
    this[field] = srcOrEmpty[field]
  })
  if (this.operations.length > 0) {
    this.operations = getOperationsFromApi(this.operations)
  }
}

ObjectModel.prototype.update = function (item) {
  this.updateOperations(item.operations)
  this.broken_item_stats = item.broken_item_stats
}

ObjectModel.prototype.updateOperations = function (newOperations) {
  if (!Array.isArray(newOperations)) return
  const updatedOperations = getOperationsFromApi(newOperations)
  Vue.set(this, 'operations', updatedOperations)
}

ObjectModel.prototype.updateTotalScheduledOperationsPerOperation = function (scheduledOperations) {
  this.operations.forEach((operation) => {
    const totalOperations = scheduledOperations[operation.id] || 0
    operation.updateTotalOperations(totalOperations)
  })
}

ObjectModel.prototype.serialize = function () {
  const creationParams = JSON.parse(JSON.stringify(this))
  const result = filedList.reduce((initValue, currentValue) => {
    initValue[currentValue] = creationParams[currentValue]
    return initValue
  }, {})
  result.department = creationParams.department.name
  delete result.broken_item_stats
  delete result.general
  delete result.category_name
  delete result.category_id
  return result
}

function getOperationsFromApi (operationsFromApi) {
  return operationsFromApi
    .map((operation) => newDeviceOperationModel(operation))
}

function newObjectModel (src) {
  return new ObjectModel(src)
}

export default {
  newObjectModel
}
