<template>
  <v-row justify="space-between" class="stat-chip">
    <v-col class="flex-grow-1 flex-shrink-0">
      <v-avatar
        class="mme-2" :color="tag.color"
        size="24"
      >
        <v-icon
          v-if="showIcon" small color="white"
          class="material-icons-outlined"
        >
          sell
        </v-icon>
        <v-icon
          v-else small color="transparent"
          class="material-icons-outlined no-icon"
        >
          sell
        </v-icon>
      </v-avatar>
      <span class="color20 t-400-14 mme-2">{{ tag.translatedName }}</span>
    </v-col>
    <v-col class="texts-end text-no-wrap flex-grow-0 flex-shrink-1">
      <span class="t-700-16 color1">{{ tag.totalDoneTasks }}</span> /
      <span class="t-700-16 color20">{{ tag.totalTasks }}</span>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    tag: {
      type: Object,
      required: true
    },
    showIcon: {
      type: Boolean,
      default: true
    }
  },
  setup (props) {
    // Computed
    return {

    }
  }
}
</script>
<style lang="scss">
.hq-task-stats {
  .stat-chip {
    height: 45px;
    border-radius: 16px;
    border: 1px solid #EDEDED;
    .no-icon{
      border: 1px solid #DADADA;
    }
  }
}
</style>
