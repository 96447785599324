import useAccount from '@/composables/useAccount.js'
import {
  create as BlogPostCreate,
  update as BlogPostUpdate,
  index as BlogPostIndex,
  destroy as BlogPostDelete
} from '@/api_client/BlogPost.js'
import {
  create as BlogPostLikeCreate, destroy as BlogPostLikeDelete,
  index as BlogPostLikeIndex
} from '@/api_client/BlogPostLike.js'
import { uploadImage as uploadImageToRemoteServer } from '@/general_client/CloudinaryImageUploader.js'
import { uploadFile as uploadFileToRemoteServer } from '@/general_client/FileUploader.js'

import { ISO8601dateToText, ISO8601dateTimeStringToISODate } from '@/helpers/DateTime.js'
import { isString, isObject, isFile } from '@/helpers/Utils.js'

import Vue, { ref, computed } from 'vue'

const DEFAULT_PAGE = 1
const DEFAULT_PER_PAGE = 100
const defaultPagination = () => ({ page: DEFAULT_PAGE, perPage: DEFAULT_PER_PAGE, total: null, totalPages: null })
const defaultFilter = () => ({ tags: [], myPosts: false, myLikes: false })

const postsFeed = ref([])
const postsFeedLoading = ref(false)
const pagination = ref(defaultPagination())
const filters = ref(defaultFilter())

let currentApiKey = null

const formatPosts = (postsArray, isPostComments = false) => {
  const { accountCountryCode } = useAccount()
  const countryCode = accountCountryCode.value
  postsArray.forEach((post) => {
    const postDate = ISO8601dateTimeStringToISODate(post.created_at)
    post.creationDateFormmated = ISO8601dateToText({ fromDate: postDate, countryCode })
    post.isLoadingLike = false
    post.deleteInProgress = false
    post.editInProgress = false
    if (!Number.isInteger(post.likes_count)) post.likes_count = 0
    if (isPostComments === true) return

    if (!Number.isInteger(post.comments_count)) post.comments_count = 0
  })
}
export default function useBlogPost (apiKey) {
  const resetValues = () => {
    postsFeed.value = []
    postsFeedLoading.value = false
    pagination.value = defaultPagination()
    filters.value = defaultFilter()
  }

  if (isString(apiKey) && currentApiKey !== apiKey) {
    resetValues()
    currentApiKey = apiKey
  }

  const blogIndexParams = ({ paginationVal, addFilters = false }) => {
    const requestParams = {
      page: paginationVal.page,
      per_page: paginationVal.perPage
    }
    if (addFilters !== true) return requestParams
    requestParams.filters = {}
    const filtersVal = filters.value
    if (Array.isArray(filtersVal.tags) && filtersVal.tags.length > 0) {
      requestParams.filters.tags = filtersVal.tags
    }
    if (filtersVal.myPosts === true) {
      requestParams.filters.my_posts = true
    }
    if (filtersVal.myLikes === true) {
      requestParams.filters.my_likes = true
    }
    return requestParams
  }

  const getTotalPages = ({ total, perPage }) => {
    const totalItems = total || 0
    if (totalItems === 0 || totalItems <= perPage) {
      return 0
    }
    return Math.ceil(totalItems / perPage)
  }

  const blogPostCreateParams = ({ blogPost, imageFilesCount = null, videoFile = null }) => {
    const blogPostVal = JSON.parse(JSON.stringify(blogPost))
    const params = {
      content: blogPostVal.content,
      parent_id: blogPostVal.parent_id,
      tags: blogPostVal.tags,
      account_api_key: blogPostVal.account_api_key,
      link: blogPostVal.link
    }
    if (Number.isInteger(imageFilesCount) && imageFilesCount > 0) {
      params.with_images = imageFilesCount
    } else if (isFile(videoFile)) {
      params.with_file = videoFile.name
    }
    return { blog_post: params }
  }

  const blogPostUpdateParams = (blogPost) => {
    const blogPostVal = JSON.parse(JSON.stringify(blogPost))
    return {
      blog_post: {
        content: blogPostVal.content,
        tags: blogPostVal.tags,
        link: blogPostVal.link
      }
    }
  }

  const uploadMultipleImages = async ({ imageFiles, signatures }) => {
    const promiseArray = []
    imageFiles.forEach((imageFile, index) => {
      const imageUploadData = signatures[index]
      const uploadRequest = uploadImageToRemoteServer(imageFile, imageUploadData)
      promiseArray.push(uploadRequest)
    })
    return Promise.all(promiseArray)
  }

  const addPostComment = (blogPost, parentId) => {
    const parentPost = postsFeed.value.find((post) => post.id === parentId)
    if (isObject(parentPost) === false) return
    parentPost.comments_count++
    if (!Array.isArray(parentPost.comments)) return

    parentPost.comments.unshift(blogPost)
  }

  const addNewBlogPostToFeed = (blogPost, parentId) => {
    formatPosts([blogPost])
    if (Number.isInteger(parentId)) {
      addPostComment(blogPost, parentId)
    } else {
      postsFeed.value.unshift(blogPost)
    }
  }

  const removeBlogPostFromFeed = ({ post, parentId }) => {
    if (Number.isInteger(parentId)) {
      const parentPost = postsFeed.value.find((postItem) => postItem.id === parentId)
      if (isObject(parentPost) === true && Array.isArray(parentPost.comments)) {
        const commentIndex = parentPost.comments.findIndex((comment) => comment.id === post.id)
        if (commentIndex >= 0) {
          parentPost.comments.splice(commentIndex, 1)
        }
        if (parentPost.comments_count > 0) {
          parentPost.comments_count--
        }
      }
    } else {
      const postIndex = postsFeed.value.findIndex((postItem) => postItem.id === post.id)
      if (postIndex >= 0) {
        postsFeed.value.splice(postIndex, 1)
      }
    }
  }

  const setPaginationFromResponse = (paginationVal, response) => {
    paginationVal.total = response.total
    paginationVal.page = response.page
    paginationVal.perPage = response.per_page
    paginationVal.totalPages = getTotalPages(paginationVal)
  }

  const findPost = (postId, parentId = null) => {
    if (!Number.isInteger(parentId)) return postsFeed.value.find((post) => post.id === postId)

    const parentPost = postsFeed.value.find((post) => post.id === parentId)
    if (isObject(parentPost) === true && Array.isArray(parentPost.comments)) {
      return parentPost.comments.find((comment) => comment.id === postId)
    }
  }

  const updateBlogPostTags = (blogPost, newTags) => {
    blogPost.tags = []
    const newBloagPostTags = Array.isArray(newTags) ? newTags : []
    newBloagPostTags.forEach((tag) => blogPost.tags.push(tag))
  }

  const updateBlogLink = (blogPost, newLink) => {
    Vue.set(blogPost, 'link', JSON.parse(JSON.stringify(newLink || {})))
  }
  // Methods
  const resetFilter = () => (filters.value = defaultFilter())

  const createPost = ({ blogPost, imageFilesCount = null, imageFiles = null, videoFile = null }) =>
    BlogPostCreate(currentApiKey, blogPostCreateParams({ blogPost, imageFilesCount, videoFile }))
      .then(async ({ data: { data } }) => {
        const haveImages = Number.isInteger(imageFilesCount) && imageFilesCount > 0
        if (haveImages === true && imageFiles.length > 0 && data.image_upload_data !== null) {
          await uploadMultipleImages({ imageFiles, signatures: data.image_upload_data })
        } else if (isFile(videoFile) && isObject(data.file_upload_data)) {
          await uploadFileToRemoteServer(videoFile, data.file_upload_data.file_url)
        }
        addNewBlogPostToFeed(data, blogPost.parent_id)
      })

  const updatePost = ({ post, parentId = null }) => {
    const feedPost = findPost(post.id, parentId) || {}
    feedPost.editInProgress = true
    const updateParams = blogPostUpdateParams(post)
    return BlogPostUpdate(currentApiKey, post.id, updateParams)
      .then(() => {
        feedPost.content = post.content
        feedPost.editInProgress = false
        updateBlogPostTags(feedPost, post.tags)
        updateBlogLink(feedPost, post.link)
      })
  }

  const deletePost = ({ post, parentId = null }) => {
    post.deleteInProgress = true
    return BlogPostDelete(currentApiKey, post.id)
      .then(() => removeBlogPostFromFeed({ post, parentId }))
      .finally(() => (post.deleteInProgress = false))
  }
  const likePost = (blogPost) => {
    blogPost.isLoadingLike = true
    return BlogPostLikeCreate(currentApiKey, blogPost.id)
      .then(() => {
        blogPost.liked_by_current_user = true
        blogPost.likes_count++
      })
      .finally(() => (blogPost.isLoadingLike = false))
  }

  const unLikePost = (blogPost) => {
    blogPost.isLoadingLike = true
    return BlogPostLikeDelete(currentApiKey, blogPost.id)
      .then(() => {
        blogPost.liked_by_current_user = false
        if (blogPost.likes_count >= 1) blogPost.likes_count--
      })
      .finally(() => (blogPost.isLoadingLike = false))
  }

  const loadFeed = ({ reset = false } = {}) => {
    if (reset === true) resetValues()
    postsFeedLoading.value = true
    return BlogPostIndex(currentApiKey, blogIndexParams({ paginationVal: pagination.value, addFilters: true }))
      .then(({ data: { data, meta } }) => {
        formatPosts(data)
        postsFeed.value = postsFeed.value.concat(data)
        setPaginationFromResponse(pagination.value, meta)
      })
      .finally(() => (postsFeedLoading.value = false))
  }

  const loadFeedNextPage = () => {
    if (pagination.value.page < pagination.value.totalPages) {
      pagination.value.page++
      loadFeed()
    }
  }

  const loadCommentsFeed = (blogPost) => {
    if (!isObject(blogPost.pagination)) {
      Vue.set(blogPost, 'pagination', defaultPagination())
    }
    const params = blogIndexParams({ paginationVal: blogPost.pagination })
    params.parent_id = blogPost.id
    return BlogPostIndex(currentApiKey, params)
      .then(({ data: { data, meta } }) => {
        formatPosts(data, true)
        setPaginationFromResponse(blogPost.pagination, meta)
        if (!Array.isArray(blogPost.comments)) {
          Vue.set(blogPost, 'comments', [])
        }
        blogPost.comments = blogPost.comments.concat(data)
      })
  }

  const applyFilters = () => {
    postsFeed.value = []
    pagination.value = defaultPagination()
    loadFeed()
  }

  const loadLikesList = async (blogPost) =>
    BlogPostLikeIndex(currentApiKey, blogPost.id, { page: 1, per_page: 100 })

  const newBlogPost = () => {
    return {
      content: null,
      parent_id: null,
      tags: [],
      account_api_key: undefined,
      isLoadingLike: false,
      deleteInProgress: false,
      editInProgress: false,
      link: {}
    }
  }

  // Computed

  const emptyFeed = computed(() => postsFeed.value.length === 0 && postsFeedLoading.value === false)
  return {
    postsFeed,
    postsFeedLoading,
    pagination,
    emptyFeed,
    filters,
    createPost,
    updatePost,
    newBlogPost,
    loadFeed,
    likePost,
    unLikePost,
    loadFeedNextPage,
    deletePost,
    loadCommentsFeed,
    loadLikesList,
    resetFilter,
    applyFilters
  }
}
