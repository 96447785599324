<template>
  <v-select
    hide-no-data
    class="account-search"
    item-value="apiKey"
    item-text="name"
    :value="value"
    :items="accountWithDistrictFilter"
    :append-icon="appendIcon"
    :multiple="multiple"
    :is-loading="isLoading"
    :placeholder="$t('account_selection_select.placeholder')"
    :disabled="disabled"
    :outlined="outlined"
    :dense="dense"
    :menu-props="menuPropsWithClass"
    :clearable="clearable"
    :class="{ 'rtl': isRtl, 'ltr': !isRtl }"
    :no-data-text="$t('no_result')"
    :color="color"
    :background-color="backgroundColor"
    :height="height"
    return-object
    @input="update"
    @change="change"
    @blur="emitBlur"
  >
    <template v-if="isRequired" #label>
      {{ $t('account_selection_select.placeholder') }} <span class="red--text">*</span>
    </template>
    <template v-if="multiple && showSelectAll" #prepend-item>
      <v-list-item ripple @click="toggleSelectAll">
        <v-list-item-content>
          <v-list-item-title>
            {{ $t('select_all') }}
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action class="mx-0">
          <v-icon>
            {{ selectAllIcon }}
          </v-icon>
        </v-list-item-action>
      </v-list-item>
      <v-divider class="mt-2" />
    </template>

    <template #selection="{ index }">
      <div v-if="index === 0" class="v-select__selection v-select__selection--comma">
        <span v-if="multiple">
          {{ $t('account_selection_select.x_accounts_selected', { account_count: value.length }) }}
        </span>
        <span v-else>
          {{ nameForApiKey(value) }}
        </span>
      </div>
    </template>

    <template #item="{ item: account, attrs }">
      <v-row
        v-if="addFaultDeviceCount || addFaultDeviceCountAllStatuses" no-gutters justify="start"
        align="center"
      >
        <v-col :cols="multiple ? 10 : 12" class="font-weight-bold">
          {{ account.name }} ({{ account.faultsCount }})
        </v-col>
        <v-col v-if="multiple" cols="2" class="texts-end">
          <v-icon v-if="attrs.inputValue">
            check_box
          </v-icon>
          <v-icon v-else>
            check_box_outline_blank
          </v-icon>
        </v-col>
      </v-row>
      <v-row
        v-else no-gutters justify="start"
        align="center"
      >
        <v-col :cols="multiple ? 10 : 12" class="font-weight-bold">
          {{ account.name }}
        </v-col>
        <v-col v-if="multiple" cols="2" class="texts-end">
          <v-icon v-if="attrs.inputValue">
            check_box
          </v-icon>
          <v-icon v-else>
            check_box_outline_blank
          </v-icon>
        </v-col>
      </v-row>
    </template>
  </v-select>
</template>

<script>
import { companyTotals as faultyDeviceCompanyTotals } from '@/api_client/AccountFaultyDevice.js'

import useAccount from '@/composables/useAccount.js'

import { isObject, isString } from '@/helpers/Utils.js'

import { isRtl } from '@/i18n.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { ref, computed, watch } from 'vue'

const getApiKey = (account) => isObject(account) ? account.apiKey : account
const EMPTY_DISTRICT_ID = -1

export default {
  props: {
    apiKey: {
      type: String,
      required: true
    },
    value: {
      type: [Array, String, Object],
      default () {
        return []
      }
    },
    menuProps: {
      type: Object,
      required: false,
      default: () => { }
    },
    multiple: {
      type: Boolean,
      default: true
    },
    returnObject: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: true
    },
    districtIds: {
      type: Array,
      default: () => []
    },
    addFaultDeviceCount: {
      type: Boolean,
      default: false
    },
    addFaultDeviceCountAllStatuses: {
      type: Boolean,
      default: false
    },
    clientDate: {
      type: Date,
      required: false,
      default: null
    },
    showSelectAll: {
      type: Boolean,
      required: false,
      default: true
    },
    appendIcon: {
      type: String,
      required: false,
      default: 'search'
    },
    clearable: {
      type: Boolean,
      required: false,
      default: false
    },
    backgroundColor: {
      type: String,
      required: false,
      default: undefined
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false
    },
    color: {
      type: String,
      required: false,
      default: 'black'
    },
    height: {
      type: [Number, String],
      required: false,
      default: undefined
    }
  },
  setup (props, { emit }) {
    const isLoading = ref(true)
    const allAccounts = ref([])
    const { loadAllAccounts } = useAccount()
    const requests = [loadAllAccounts()]
    if (props.addFaultDeviceCount || props.addFaultDeviceCountAllStatuses) {
      const requestParams = {}
      if (props.addFaultDeviceCountAllStatuses) {
        requestParams.allStatuses = true
      }
      requests.push(faultyDeviceCompanyTotals(props.apiKey, requestParams))
    }
    Promise.all(requests).then(async (responses) => {
      const accounts = responses[0]
      const faultsCount = props.addFaultDeviceCount || props.addFaultDeviceCountAllStatuses ? responses[1].data.data : {}
      const allAccountsVal = allAccounts.value
      let currentDistrictId = null
      accounts.forEach((account) => {
        const result = JSON.parse(JSON.stringify(account))
        if (Number.isInteger(result.company_district_id) && result.company_district_id !== currentDistrictId) {
          allAccountsVal.push({ id: result.company_district_id, header: result.district_name })
          currentDistrictId = result.company_district_id
        }
        result.apiKey = account.api_key
        result.faultsCount = faultsCount[account.api_key] || 0
        allAccountsVal.push(result)
      })
    }).catch(errHandler)
      .finally(() => (isLoading.value = false))
    const onChange = (newValue) => {
      if (props.multiple) {
        const nonHeaderValues = newValue.filter((val) => !val.header)
        return props.returnObject ? nonHeaderValues : nonHeaderValues.map(getApiKey)
      }
      return props.returnObject ? newValue : getApiKey(newValue)
    }

    // Methods
    const update = (newValue) => emit('input', onChange(newValue))
    const change = (newValue) => emit('change', onChange(newValue))
    const emitBlur = () => emit('blur')
    const nameForApiKey = (val) => {
      if (props.returnObject) return val.name

      return allAccounts.value.find((account) => account.apiKey === val).name
    }
    const toggleSelectAll = () => {
      if (isAllAccountsSelected.value === true) {
        update([])
      } else {
        update(accountWithDistrictFilter.value)
      }
    }

    // Computed
    const isAllAccountsSelected = computed(() => {
      if (Array.isArray(props.value) === false) return false
      return accountWithDistrictFilter.value.filter((account) => !account.header).length === props.value.filter((account) => !account.header).length
    })
    const somAccountSelected = computed(() => props.value.length > 0)
    const selectAllIcon = computed(() => {
      if (isAllAccountsSelected.value === true) return 'check_box'
      if (somAccountSelected.value === true) return 'indeterminate_check_box'
      return 'check_box_outline_blank'
    })

    const accountWithDistrictFilter = computed(() => {
      if (props.districtIds.length === 0) return allAccounts.value
      return allAccounts.value.filter((account) => {
        let districtId = null
        if ('header' in account) {
          districtId = account.id
        } else {
          districtId = account.company_district_id === null ? EMPTY_DISTRICT_ID : account.company_district_id
        }
        return props.districtIds.includes(districtId)
      })
    })

    const menuPropsWithClass = computed(() => {
      const result = isObject(props.menuProps) ? Object.assign(props.menuProps, {}) : {}
      result.contentClass = isString(result.contentClass) ? result.contentClass : ''
      const addedClass = isRtl.value ? 'rtl' : 'ltr'
      result.contentClass = `${result.contentClass} ${addedClass} account-search`
      return result
    })

    watch(() => props.districtIds, () => {
      if (!Array.isArray(props.value)) return
      if (props.districtIds.length === 0) return

      const filteredAccounts = props.value.filter((account) => {
        let foundDistrictId = null
        if (props.returnObject) {
          foundDistrictId = account.company_district_id
        } else {
          foundDistrictId = allAccounts.value.find((row) => row.apiKey === account).company_district_id
        }
        const districtId = foundDistrictId === null ? EMPTY_DISTRICT_ID : account.company_district_id
        return props.districtIds.includes(districtId)
      })
      if (filteredAccounts.length === props.value.length) return
      update(filteredAccounts)
    })

    return {
      isLoading,
      allAccounts,
      isAllAccountsSelected,
      selectAllIcon,
      accountWithDistrictFilter,
      isRtl,
      menuPropsWithClass,
      update,
      change,
      toggleSelectAll,
      nameForApiKey,
      emitBlur
    }
  }
}
</script>

<style lang="scss">
.account-search {
  div.v-subheader {
    font-weight: 700;
    background-color: #E8E8E8;
  }

  .v-text-field__details {
    display: none;
  }
}

.account-search.v-select.v-select--is-menu-active .v-input__icon--append .v-icon {
  transform: rotate(0);
}
</style>
