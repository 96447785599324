<template>
  <span>
    <confirm-delete ref="deleteDialog" />
    <schedule-new-staff-operation-dialog ref="scheduleNewStaffOperation" />
    <share-content ref="shareDialog" :share-content="createShareContent" show-dialog />
    <single-task-notification ref="singleTaskNotification" />
    <image-carousel-dialog ref="imageCarouselDialog" />
    <chat-dialog
      ref="chatDialog"
      record-type="OperationSchedule"
      @update-message-count="updateMessageCount"
      @mark-read-all="markReadAllChatMessages"
    />
    <report-item-operation-list-dialog
      ref="reportItemOperationListDialog"
      :read-only="tasksDisabled"
      :client-date="clientDateIso"
    />
  </span>
</template>
<script>
import ConfirmDelete from '@/components/shared/ConfirmDelete.vue'
import ScheduleNewStaffOperationDialog from '@/components/operation_schedule_dialog/ScheduleNewStaffOperationDialog.vue'
import ChatDialog from '@/components/chat_dialog/ChatDialog.vue'
import ShareContent from '@/components/shared/ShareContent.vue'
import SingleTaskNotification from '@/components/shared/SingleTaskNotification.vue'
import ImageCarouselDialog from '@/components/shared/ImageCarouselDialog.vue'
import ReportItemOperationListDialog from '@/components/shared/item_operation_list_dialog/ReportItemOperationListDialog.vue'

import useHQCrossAccountDailyTasks from '@/composables/useHQCrossAccountDailyTasks.js'
import { createShareContent as createOperationShareContent } from '@/classes/OperationScheduleShareContent.js'
import {
  EventBus, TASK_EDIT_CLICK, TASK_COPY_CLICK, TASK_DELETE_CLICK, TASK_CHAT_CLICK,
  TASK_SHARE_CLICK, TASK_NOTIFICATION_CLICK, TASK_GUIDANCE_IMAGE_CLICK, TASK_TOGGLE_COMPLETE_CLICK,
  HQ_TASKS_FILTER_CHANGED, TASK_IMAGE_CLICK
} from '@/EventBus.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { isString } from '@/helpers/Utils.js'

import { i18n } from '@/i18n.js'

import { ref, onUnmounted, onMounted } from 'vue'

let shareTask = null

export default {
  components: {
    'confirm-delete': ConfirmDelete,
    'schedule-new-staff-operation-dialog': ScheduleNewStaffOperationDialog,
    'chat-dialog': ChatDialog,
    'share-content': ShareContent,
    'single-task-notification': SingleTaskNotification,
    'image-carousel-dialog': ImageCarouselDialog,
    'report-item-operation-list-dialog': ReportItemOperationListDialog
  },
  props: {
    apiKey: {
      type: String,
      required: true
    },
    clientDate: {
      type: Date,
      required: true
    }
  },
  setup (props) {
    const scheduleNewStaffOperation = ref(null)
    const deleteDialog = ref(null)
    const chatDialog = ref(null)
    const shareDialog = ref(null)
    const singleTaskNotification = ref(null)
    const imageCarouselDialog = ref(null)
    const reportItemOperationListDialog = ref(null)

    const {
      clientDateIso, tasksDisabled,
      deleteCrossAccountOperation, onChildTaskDeleted, findTask, findCrossAccountTask,
      changeTotalReportedOperations, applyFilters
    } = useHQCrossAccountDailyTasks(props.apiKey)

    const onEditClick = (task) => {
      const taskToUpdate = task.isParentTask ? task : findCrossAccountTask(task.operationRef.crossAccountTaskId)
      scheduleNewStaffOperation.value.openCrossAccountUpdate(taskToUpdate, props.apiKey)
    }

    const onCopyClick = (task) => scheduleNewStaffOperation.value.openCrossAccountCopy(task, props.apiKey)
    const onDeleteClick = (task) => task.isParentTask ? deleteCrossAccountTask(task) : deleteChildTask(task)

    const deleteCrossAccountTask = (task) => {
      const title = i18n.t('cross_account_page.delete_cross_operation')
      const content = i18n.t('cross_account_page.delete_cross_operation_detail')
      deleteDialog.value.open({ title, content })
        .then((shouldDelete) => {
          if (!shouldDelete) return
          deleteCrossAccountOperation(task.cross_account_operation_id)
            .then(() => deleteDialog.value?.close())
            .catch(errHandler)
        })
    }

    const deleteChildTask = async (task) => {
      const description = task.operationRef.operation_description || ''
      const deleteDialogParams = { title: i18n.t('job_operation_edit.delete_operation') }
      if (isString(description) && description.trim().length > 0) {
        deleteDialogParams.content = description
      }
      const shouldDelete = await deleteDialog.value.open(deleteDialogParams)
      if (!shouldDelete) return

      await onChildTaskDeleted(task)

      deleteDialog.value.close()
      return true
    }

    const onShareTask = (task) => {
      const taskRef = task.operationRef
      const pdfShareEnabled = task.tasksList().length > 0

      shareTask = JSON.parse(JSON.stringify(taskRef))
      const title = i18n.t('operation_share.title')
      shareDialog.value.openDialog({ title, pdfShareEnabled })
    }

    const onCreateTaskNotification = (task) => {
      const taskRef = task.operationRef
      singleTaskNotification.value.openDialog({ operation: taskRef, apiKey: taskRef.account_api_key })
        .then(({ isUpdated, onCompleteTaskNotification = null, onUnCompleteTaskNotification = null }) => {
          if (isUpdated !== true) return

          task.updateOperationNotifications({ onCompleteTaskNotification, onUnCompleteTaskNotification })
        })
    }

    const showTaskReportedImageDialog = (task) => {
      const deleteDialogParams = { content: i18n.t('tasks_page.delete_task_image') }
      return deleteDialog.value.open(deleteDialogParams)
    }

    const openSubTaskList = (task) => {
      const oldIsReported = task.isReported()
      reportItemOperationListDialog.value.openDialogWithReactiveOperation(task)
        .then((isUpdated) => {
          if (!isUpdated) return
          const newIsReported = task.isReported()
          if (oldIsReported === newIsReported) return
          const offset = newIsReported ? 1 : -1
          const crossAccountTaskId = task.operationRef.crossAccountTaskId
          changeTotalReportedOperations({ crossAccountTaskId, offset })
        })
    }

    const markTaskAsNotComplete = async (task) => {
      const crossAccountTaskId = task.operationRef.crossAccountTaskId
      if (task.requireImage()) {
        const isDeleted = await showTaskReportedImageDialog(task.operationRef)
        if (!isDeleted) return false
        task.cancelOperationReport()
          .then(() => {
            changeTotalReportedOperations({ crossAccountTaskId, offset: -1 })
            deleteDialog.value.close()
          })
      } else {
        task.cancelOperationReport()
          .then(() => changeTotalReportedOperations({ crossAccountTaskId, offset: -1 }))
      }
    }

    const markTaskAsComplete = ({ task, event }) => {
      const crossAccountTaskId = task.operationRef.crossAccountTaskId
      if (task.requireImage()) {
        const validFiles = event.target?.files?.length > 0
        if (!validFiles) return true
        const file = event.target.files[0]
        if (!file) return
        task.uploadImageForOperation({ clientDate: props.clientDate, file })
          .then(() => changeTotalReportedOperations({ crossAccountTaskId, offset: 1 }))
      } else {
        task.markOperationAsCompleted(props.clientDate)
          .then(() => changeTotalReportedOperations({ crossAccountTaskId, offset: 1 }))
      }
    }

    const toggleTaskComplete = ({ task, event }) => {
      const hasSubTasks = task.tasksList().length > 0
      if (hasSubTasks) return openSubTaskList(task)

      if (task.isReported()) {
        markTaskAsNotComplete(task)
      } else {
        markTaskAsComplete({ task, event })
      }
    }

    // Methods
    const showChatDialog = (task) => chatDialog.value.openDialog({
      recordId: task.operationRef.operation_id,
      apiKey: task.operationRef.account_api_key,
      dialogTitle: task.operationRef.operation_description
    })

    const updateMessageCount = ({ recordId, messageCount }) => {
      const task = findTask(recordId)
      if (task !== null) task.updateOperationMessageCount(messageCount)
    }

    const markReadAllChatMessages = (recordId) => {
      const task = findTask(recordId)
      if (task !== null) task.updateOperationReadAllMessages()
    }

    const showTaskGuidanceImages = (task) => {
      const imageUrls = task.guidance_images.map((image) => image.url)
      imageCarouselDialog.value.openDialog({ imageUrls })
    }

    const onImageClick = (task) => {
      const imageUrl = task.operationRef.image_url
      imageCarouselDialog.value.openDialog({ imageUrls: [imageUrl] })
    }

    const createShareContent = (medium) => {
      return createOperationShareContent({
        operation: shareTask,
        currentDate: props.clientDate,
        apiKey: props.apiKey,
        hideShifts: props.hideShifts,
        medium
      })
    }

    onMounted(() => {
      EventBus.on(TASK_EDIT_CLICK, onEditClick)
      EventBus.on(TASK_COPY_CLICK, onCopyClick)
      EventBus.on(TASK_DELETE_CLICK, onDeleteClick)
      EventBus.on(TASK_CHAT_CLICK, showChatDialog)
      EventBus.on(TASK_SHARE_CLICK, onShareTask)
      EventBus.on(TASK_NOTIFICATION_CLICK, onCreateTaskNotification)
      EventBus.on(TASK_IMAGE_CLICK, onImageClick)
      EventBus.on(TASK_GUIDANCE_IMAGE_CLICK, showTaskGuidanceImages)
      EventBus.on(TASK_TOGGLE_COMPLETE_CLICK, toggleTaskComplete)
      EventBus.on(HQ_TASKS_FILTER_CHANGED, applyFilters)
    })

    onUnmounted(() => {
      EventBus.off(TASK_EDIT_CLICK, onEditClick)
      EventBus.off(TASK_COPY_CLICK, onCopyClick)
      EventBus.off(TASK_DELETE_CLICK, onDeleteClick)
      EventBus.off(TASK_CHAT_CLICK, showChatDialog)
      EventBus.off(TASK_SHARE_CLICK, onShareTask)
      EventBus.off(TASK_NOTIFICATION_CLICK, onCreateTaskNotification)
      EventBus.off(TASK_IMAGE_CLICK, onImageClick)
      EventBus.off(TASK_GUIDANCE_IMAGE_CLICK, showTaskGuidanceImages)
      EventBus.off(TASK_TOGGLE_COMPLETE_CLICK, toggleTaskComplete)
      EventBus.off(HQ_TASKS_FILTER_CHANGED, applyFilters)
    })
    return {
      scheduleNewStaffOperation,
      deleteDialog,
      chatDialog,
      shareDialog,
      imageCarouselDialog,
      reportItemOperationListDialog,
      singleTaskNotification,
      clientDateIso,
      tasksDisabled,
      createShareContent,
      updateMessageCount,
      markReadAllChatMessages
    }
  }
}
</script>
