<template>
  <span v-if="showComponent" class="mt-1 task-tags">
    <span v-if="showStartTime" class="color1 t-700-13 d-block d-sm-inline">
      {{ task.operationRef.startTime }}
    </span>
    <v-chip v-if="showHqIcon" outlined class="pps-0">
      <v-avatar
        color="black" class="mme-2"
        size="24"
      >
        <v-icon small color="white">
          home_work
        </v-icon>
      </v-avatar>
      <span class="color20 t-400-14">{{ $t('hq') }}</span>
    </v-chip>
    <v-chip
      v-if="showReportTag" outlined class="pps-0"
      :class="{'mms-1': showHqIcon}"
    >
      <v-avatar
        class="mme-2"
        :color="reportTagColor"
        size="24"
      >
        <v-icon small color="white" class="material-icons-outlined">
          sell
        </v-icon>
      </v-avatar>
      <span class="color20 t-400-14">{{ reportTagName }}</span>
    </v-chip>

    <v-chip v-if="showRelatedItemTag" outlined class="pps-0">
      <v-avatar
        class="mme-2"
        color="#F58F29"
        size="24"
      >
        <v-icon color="white" class="material-icons-outlined">
          build
        </v-icon>
      </v-avatar>
      <span>
        {{ relatedItemText }}
      </span>
    </v-chip>
  </span>
</template>
<script>
import { tagColors, translateReportTag } from '@/store/modules/OperationSchedule.js'
import { isString } from '@/helpers/Utils.js'

import { computed } from 'vue'
export default {
  props: {
    task: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    // Computed
    const opRef = computed(() => props.task.operationRef)
    const showStartTime = computed(() => isString(opRef.value.startTime))
    const showHqIcon = computed(() => opRef.value.staff_operation)
    const showReportTag = computed(() => isString(opRef.value.report_tag))
    const reportTagColor = computed(() => tagColors[opRef.value.report_tag])
    const reportTagName = computed(() => translateReportTag(opRef.value.report_tag))
    const showRelatedItemTag = computed(() => isString(opRef.value.related_item_name))
    const relatedItemText = computed(() => {
      if (showRelatedItemTag.value === false) return ''
      const relatedItemName = opRef.value.related_item_name
      const relatedItemDepartment = opRef.value.related_item_department
      if (!isString(relatedItemDepartment)) return relatedItemName
      return `${relatedItemName} - ${relatedItemDepartment}`
    })

    const showComponent = computed(() => showStartTime.value || showHqIcon.value || showReportTag.value || showRelatedItemTag.value)
    return {
      showHqIcon,
      showReportTag,
      showRelatedItemTag,
      showStartTime,
      reportTagColor,
      reportTagName,
      relatedItemText,
      showComponent
    }
  }
}
</script>
