<template>
  <span
    class="page-filter-fixed"
    :class="{pointer: drawerCollapsed}"
  >
    <v-navigation-drawer
      :mini-variant.sync="drawerCollapsed"
      :right="isRtl"
      permanent
    >
      <v-row align="start" justify="start" class="main-row">
        <v-col cols="12" class="text-center pt-8 t-700-16 color20 pointer">
          <div @click="toggleDrawer">
            <v-badge
              bordered
              :value="anyFilterSelected ? 1 : 0"
              color="red"
              overlap
              dot
            >
              <v-icon color="#111">
                tune
              </v-icon>
            </v-badge>
            <span v-if="!drawerCollapsed" class="pps-1">{{ $t('page_filter.filter_by') }}</span>
          </div>
          <v-expand-x-transition>
            <div v-show="!drawerCollapsed" class="mt-4">
              <slot />
            </div>
          </v-expand-x-transition>
        </v-col>
      </v-row>
      <template #append>
        <v-expand-x-transition>
          <v-col v-if="!drawerCollapsed" cols="12" class="d-flex justify-space-between align-self-end pt-0 pb-3">
            <v-col cols="6">
              <v-btn
                block outlined color="black"
                :disabled="disabled" :loading="isLoading"
                @click="resetFilters"
              >
                {{ $t('reset') }}
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn
                block color="black white--text" :disabled="disabled"
                :loading="isLoading" @click="applyFilters"
              >
                {{ $t('show') }}
              </v-btn>
            </v-col>
          </v-col>
        </v-expand-x-transition>
      </template>
    </v-navigation-drawer>
    <span
      class="close-arrow pointer" :class="{rotated: drawerCollapsed}"
      @click="toggleDrawer"
    >
      <v-icon>
        chevron_right
      </v-icon>
    </span>
  </span>
</template>
<script>

import { isRtl } from '@/i18n'

import { ref } from 'vue'
export default {
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    anyFilterSelected: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup (_, { emit }) {
    const drawerCollapsed = ref(false)
    // Methods

    const resetFilters = () => emit('reset-filters')
    const toggleDrawer = () => (drawerCollapsed.value = !drawerCollapsed.value)
    const applyFilters = () => emit('apply-filters')

    return {
      drawerCollapsed,
      isRtl,
      toggleDrawer,
      applyFilters,
      resetFilters
    }
  }
}
</script>

<style lang="scss">
.page-filter-fixed {
  position: relative;
  .v-navigation-drawer__content, .v-navigation-drawer {
    max-height: calc(100vh - 164px) !important;
  }
  .close-arrow {
      position: absolute;
      left: -22px;
      top: 20px;
      border-radius: 12px 0px 0px 12px;
      z-index: 10;
      background-color: white;
      transition: transform 0.5s ease;
      &:hover {
        transform: scale(1.5);
      }
      &.rotated {
        transform: rotate(180deg);
        border-radius: 0px 12px 12px 0px;
      }
  }
  .main-row {
    max-height: calc(100vh - 164px) !important;
  }
}
.rtl {
  .page-filter-fixed {
    .close-arrow {
      right: -22px;
      left: auto;
      border-radius: 0px 12px 12px 0px;
      &.rotated {
        border-radius: 12px 0px 0px 12px;
      }
    }
  }
}
</style>
