<template>
  <v-select
    :value="value"
    :items="allAccounts"
    :is-loading="isLoading"
    color="black"
    hide-no-data
    height="20"
    :placeholder="$t('account_selection_select.placeholder')"
    item-value="apiKey"
    item-text="name"
    prepend-icon="visibility"
    :menu-props="{ 'contentClass': menuPropsClass }"
    class="blog-account-search pt-0 mt-0"
    :no-data-text="$t('no_result')"
    @input="update"
    @change="change"
  />
</template>
<script>
import useAuth from '@/composables/useAuth.js'
import useAccount from '@/composables/useAccount.js'
import useCompany from '@/composables/useCompany.js'
import { indexForEmployees as accountClientIndexForEmployees } from '@/api_client/AccountClient.js'
import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { i18n, isRtl } from '@/i18n.js'
import { ref, computed } from 'vue'

const loadAccounts = async () => {
  const { apiKey, isCompanyAdminRegionOrAbove } = useAuth()
  if (isCompanyAdminRegionOrAbove.value) {
    const { loadAllAccounts } = useAccount()
    return loadAllAccounts()
  }
  const response = await accountClientIndexForEmployees(apiKey.value)
  return response.data.data
}

export default {
  props: {
    value: {
      type: String,
      required: false,
      default: undefined
    }
  },
  setup (_, { emit }) {
    const isLoading = ref(true)
    const allAccounts = ref([])
    loadAccounts()
      .then((accounts) => {
        const allAccountsVal = allAccounts.value
        let currentDistrictId = null
        accounts.forEach((account) => {
          const result = JSON.parse(JSON.stringify(account))
          if (Number.isInteger(result.company_district_id) && result.company_district_id !== currentDistrictId && result.district_name) {
            allAccountsVal.push({ id: result.company_district_id, header: result.district_name })
            currentDistrictId = result.company_district_id
          }
          result.apiKey = account.api_key
          allAccountsVal.push(result)
        })

        const { apiKey, isCompanyAdminRegionOrAbove } = useAuth()
        if (isCompanyAdminRegionOrAbove.value) {
          const { staffAccountApiKey } = useCompany(apiKey.value)
          allAccountsVal.unshift({ name: i18n.t('blog_post.staff_account'), apiKey: staffAccountApiKey.value })
        }
        if (isCompanyAdminRegionOrAbove.value) {
          allAccountsVal.unshift({ name: i18n.t('blog_post_create_dialog.select_all'), apiKey: null })
        }
      })
      .catch(errHandler)
      .finally(() => (isLoading.value = false))

    // Methods
    const update = (value) => emit('input', value)
    const change = (value) => emit('change', value)

    // Computed
    const menuPropsClass = computed(() => `blog-account-search-menu ${isRtl.value ? 'rtl' : 'ltr'}`)
    return {
      isLoading,
      allAccounts,
      menuPropsClass,
      update,
      change
    }
  }
}
</script>
<style lang="scss">
.blog-account-search-menu {
  div.v-subheader {
    font-weight: 700;
    background-color: #E8E8E8;
  }
}

.blog-account-search {
  background-color: black;
  border-radius: 16px;

  .v-input__icon--prepend {
    .v-icon {
      color: white !important;
    }
  }

  .v-select__selections {
    .v-select__selection {
      color: white;
    }

    input::placeholder {
      color: white;
    }

    &::after {
      content: "*";
      display: inline-block;
      color: red;
    }
  }

  .v-input__append-inner {
    .v-icon {
      color: white !important;
    }
  }

  .v-select__slot {
    padding-top: 12px;
  }

  .v-input__slot::before {
    display: none !important;
  }

  .v-input__slot::after {
    display: none !important;
  }

  .v-text-field__details {
    display: none;
  }
}
</style>
