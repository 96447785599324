<template>
  <v-dialog v-model="dialog" max-width="300">
    <operation-image-overlay
      :show-image-overlay="companyImageRef.showImageOverlay"
      :image-src="companyImageRef.url"
      @cancel-report="removeImage"
      @hide-image="hideImageOverlay"
    />
    <v-card class="company-settings" :class="{ 'rtl': isRtl, 'ltr': !isRtl }">
      <v-card-title class="text-center pb-5 blue-grey lighten-5">
        <span class="headline full-width">
          {{ $t('company_settings.company_name') }}
        </span>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-container>
          <v-row align="start" justify="start" class="pb-3">
            <v-col cols="12">
              <v-text-field
                v-model="companyNameModel"
                single-line
                :rules="[requiredField]"
                :loading="isLoading"
                :disabled="readOnlyMode"
                counter="120"
                :label="$t('company_settings.company_name')"
              />
            </v-col>
            <v-col cols="12">
              <v-img
                v-if="showImagePreview" max-height="150"
                :src="companyImageRef.url"
                @click="previewImageClick"
                @load="onImageLoaded"
                @error="onImageLoadError"
              >
                <template #placeholder>
                  <v-row
                    align="center" justify="center" class="fill-height"
                    no-gutters
                  >
                    <v-progress-circular indeterminate color="primary" />
                  </v-row>
                </template>
              </v-img>
              <label v-else-if="!readOnlyMode" for="store_custom_image" class="pointer">
                <v-icon class="font-weight-black" color="black"> add_a_photo </v-icon>
                <input
                  id="store_custom_image" type="file" accept="image/*"
                  :disabled="companyImageRef.isLoading"
                  @change="uploadImagePreview"
                >
              </label>
            </v-col>
          </v-row>
          <v-row
            v-if="readOnlyMode" no-gutters align="end"
            justify="end" class="pb-5 text-right"
          >
            <v-btn color="blue darken-1" text @click="close">
              {{ $t('close') }}
            </v-btn>
          </v-row>
          <v-row
            v-else no-gutters align="end"
            justify="end" class="pb-5 text-right"
          >
            <v-btn
              color="blue darken-1" text :disabled="isLoading"
              @click="close"
            >
              {{ $t('cancel') }}
            </v-btn>
            <v-btn
              color="blue darken-1" depressed tile
              :loading="isLoading" :disabled="saveDisabled" @click="save"
            >
              {{ $t('save') }}
            </v-btn>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { requiredField } from '@/classes/InputValidators.js'

import { uploadImage as uploadImageToRemoteServer } from '@/general_client/CloudinaryImageUploader.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'

import useCompany from '@/composables/useCompany.js'
import useAuth from '@/composables/useAuth.js'

import { isString, isObject, isFile } from '@/helpers/Utils.js'

import { isRtl } from '@/i18n'
import { computed, ref } from 'vue'
function defaultCompanyImage () {
  return {
    isLoading: false,
    url: '',
    hasImage: false,
    showImageOverlay: false,
    hadImage: false
  }
}

export default {
  setup () {
    const isLoading = ref(false)
    const companyNameModel = ref(null)
    const companyImageRef = ref(defaultCompanyImage())
    let originalUploadedFile = null
    let uploadedImagePreviewUrl = null
    const dialog = ref(false)

    const { apiKey, isUserCompanyAdmin } = useAuth()
    const { companyName, customCompanyImage, updateCompanyData, removeCompanyImage, loadCompanyData } = useCompany(apiKey.value)

    const shouldAddImage = () => companyImageRef.value.hasImage && isFile(originalUploadedFile)
    const shouldRemoveImage = () => !companyImageRef.value.hasImage && companyImageRef.value.hadImage === true

    const releaseImageMemory = () => {
      if (!uploadedImagePreviewUrl) return
      URL.revokeObjectURL(uploadedImagePreviewUrl)
      uploadedImagePreviewUrl = null
    }

    // Methods
    const hideImageOverlay = () => (companyImageRef.value.showImageOverlay = false)
    const previewImageClick = () => {
      if (companyImageRef.value.hasImage !== true) return

      companyImageRef.value.showImageOverlay = true
    }
    const onImageLoaded = () => {
      companyImageRef.value.isLoading = false
      companyImageRef.value.hasImage = true
    }
    const onImageLoadError = () => {
      companyImageRef.value.isLoading = false
      companyImageRef.value.hasImage = false
    }

    const removeImage = () => {
      releaseImageMemory()
      hideImageOverlay()
      companyImageRef.value.url = ''
      companyImageRef.value.hasImage = false
      companyImageRef.value.isLoading = false
      originalUploadedFile = null
    }

    const uploadImagePreview = (event) => {
      const file = event.target.files[0]
      if (!file) return

      releaseImageMemory()
      companyImageRef.value.isLoading = true
      uploadedImagePreviewUrl = URL.createObjectURL(file)
      originalUploadedFile = file
      companyImageRef.value.url = uploadedImagePreviewUrl
    }

    const openDialog = () => {
      dialog.value = true
      companyNameModel.value = companyName.value
      if (isString(customCompanyImage.value) && customCompanyImage.value.length > 0) {
        companyImageRef.value.hadImage = true
        companyImageRef.value.hasImage = true
        companyImageRef.value.url = customCompanyImage.value
      }
    }

    const close = () => {
      dialog.value = false
      isLoading.value = false
      companyImageRef.value = defaultCompanyImage()
    }

    const save = () => {
      isLoading.value = true
      const requestParams = { name: companyNameModel.value, withImage: shouldAddImage() }
      updateCompanyData(requestParams)
        .then(async (responseData) => {
          companyNameModel.value = companyName.value
          if (shouldAddImage() && isObject(responseData.data.image_upload_data)) {
            const imageUploadData = responseData.data.image_upload_data
            await uploadImageToRemoteServer(originalUploadedFile, imageUploadData)
          } else if (shouldRemoveImage()) {
            await removeCompanyImage()
          }
        })
        .catch(errHandler)
        .finally(close)
    }

    // Computed

    const showImagePreview = computed(() => companyImageRef.value.isLoading || companyImageRef.value.hasImage)
    const readOnlyMode = computed(() => !isUserCompanyAdmin.value)
    const saveDisabled = computed(() => {
      if (isLoading.value) return true
      const newCompanyName = companyNameModel.value
      const valid = isString(newCompanyName) && newCompanyName.trim().length > 0
      return !valid
    })

    isLoading.value = true
    loadCompanyData()
      .then(() => (companyNameModel.value = companyName.value))
      .catch(errHandler)
      .finally(() => (isLoading.value = false))

    return {
      isLoading,
      companyNameModel,
      dialog,
      saveDisabled,
      companyImageRef,
      showImagePreview,
      isRtl,
      readOnlyMode,
      openDialog,
      close,
      save,
      requiredField,
      hideImageOverlay,
      removeImage,
      previewImageClick,
      onImageLoaded,
      onImageLoadError,
      uploadImagePreview
    }
  }
}
</script>
<style lang="scss" scoped>
.company-settings {
  input[type="file"] {
    display: none;
  }
}
</style>
