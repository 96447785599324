<template>
  <div>
    <div v-if="showJobDepartment">
      <v-icon class="material-icons-outlined" color="rgba(0, 0, 0, 0.54)">
        pin_drop
      </v-icon>
      <span class="t-500-13 color5">
        {{ task.operationRef.account_job_department_name }}
      </span>
    </div>
    <div v-if="showCreator" class="my-1 t-500-13 color11">
      ({{ $t('account_operations.created_by', {
        firstName: task.operationRef.creator_data.first_name, lastName:
          task.operationRef.creator_data.last_name
      }) }})
    </div>
    <div v-if="showReporter" class="t-500-13">
      <div class="color5 mt-1">
        <v-icon>
          today
        </v-icon>
        {{ task.operationRef.reportedDate }} {{ task.operationRef.reportedTime }}
      </div>
      <v-tooltip top :disabled="!showAdditionalReporters">
        <template #activator="{ on }">
          <span class="color11" v-on="on">
            {{ $t('account_operations.done_by', {
              first_name: task.operationRef.reporter_first_name, last_name:
                task.operationRef.reporter_last_name
            }) }}
            <span v-if="showAdditionalReporters" class="color5">
              +{{ additionalReporters.length }}
            </span>
          </span>
        </template>
        <span v-if="showAdditionalReporters">
          <div v-for="(additionalReporter, index) in additionalReporters" :key="index">
            {{ additionalReporter }}
          </div>
        </span>
      </v-tooltip>
    </div>
  </div>
</template>
<script>

import { isObject, isString } from '@/helpers/Utils.js'

import { computed } from 'vue'
export default {
  props: {
    task: {
      type: Object,
      required: true
    },
    crossAccountView: {
      type: Boolean,
      default: false
    },
    hqView: {
      type: Boolean,
      default: false
    },
    clientDate: {
      type: String,
      required: true
    }
  },
  setup (props) {
    // Computed
    const opRef = computed(() => props.task.operationRef)
    const showJobDepartment = computed(() => props.hqView && isString(opRef.value.account_job_department_name) && opRef.value.account_job_department_name.length > 0)
    const isReported = computed(() => props.task.isReported())
    const showCreator = computed(() => !props.crossAccountView && isObject(opRef.value.creator_data))
    const showReporter = computed(() => isReported.value && isString(opRef.value.reporter_first_name))
    const additionalReporters = computed(() => {
      if (!showReporter.value) return []
      const originalReporter = `${opRef.value.reporter_first_name} ${opRef.value.reporter_last_name}`
      const reporters = new Set()
      props.task.tasksList().forEach((taskListItem) => {
        if (isString(taskListItem.reporter_first_name) && isString(taskListItem.reporter_last_name)) {
          reporters.add(`${taskListItem.reporter_first_name} ${taskListItem.reporter_last_name}`)
        }
      })
      reporters.delete(originalReporter)
      return Array.from(reporters)
    })
    const showAdditionalReporters = computed(() => additionalReporters.value.length > 0)

    return {
      showCreator,
      showReporter,
      showJobDepartment,
      additionalReporters,
      showAdditionalReporters
    }
  }
}
</script>
