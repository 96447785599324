import useHQTasksFilter from '@/composables/useHQTasksFilter.js'

import { dailyStats } from '@/api_client/CompanyOperationSchedule.js'
import { tagColors, translateReportTag } from '@/store/modules/OperationSchedule.js'

import { isDateInTheFuture, isDateIsToday, dateToISO8601String } from '@/helpers/DateTime.js'
import { isString } from '@/helpers/Utils.js'

import { i18n } from '@/i18n.js'

import { ref, computed } from 'vue'

const validReportTags = ['monitor', 'report', 'procedure']
const taskStats = ref({})
const isLoading = ref(false)

let currentApiKey = null

export default function useHQTasksStats (apiKey) {
  const { clientDate, taskModeCrossAccount, buildFiltersForApi } = useHQTasksFilter(apiKey)
  const resetValues = () => {
    taskStats.value = {}
    isLoading.value = false
  }

  if (isString(apiKey) && currentApiKey !== apiKey) {
    resetValues()

    currentApiKey = apiKey
  }

  const baseRequestParams = () => {
    const requestParams = {
      client_date: dateToISO8601String(clientDate.value),
      client_date_is_today: isDateIsToday(clientDate.value),
      client_date_is_future: isDateInTheFuture(clientDate.value)
    }
    const result = Object.assign(requestParams, buildFiltersForApi())
    if (taskModeCrossAccount.value) {
      result.filters.cross_account_operations = true
    }
    return result
  }

  const loadTaskStats = () => {
    isLoading.value = true
    const requestParams = baseRequestParams()
    dailyStats(apiKey, requestParams)
      .then(({ data: { data } }) => (taskStats.value = data))
      .finally(() => (isLoading.value = false))
  }

  const afterTaskMarkComplete = loadTaskStats
  const afterTaskMarkUnComplete = loadTaskStats

  // Computed
  const taskStatsValue = computed(() => taskStats.value)
  const reportTags = computed(() => {
    const totalTasks = taskStats.value.tasks_stats || {}
    const totalDoneTasks = taskStats.value.finished_tasks_stats || {}
    return validReportTags.map((tag) => {
      const key = `tag_${tag}`
      const totalTasksForTag = totalTasks[key] || 0
      const totalDoneTasksForTag = totalDoneTasks[key] || 0
      return {
        totalTasks: totalTasksForTag,
        totalDoneTasks: totalDoneTasksForTag,
        color: tagColors[tag],
        name: tag,
        translatedName: translateReportTag(tag)
      }
    })
  })
  const untaggedTasks = computed(() => {
    const totalTasks = taskStats.value.tasks_stats?.total_untagged_tasks || 0
    const totalDoneTasks = taskStats.value.finished_tasks_stats?.total_untagged_tasks || 0
    return {
      totalTasks,
      totalDoneTasks,
      color: 'white',
      translatedName: i18n.t('untagged')
    }
  })

  const topAccounts = computed(() => taskStats.value.top_accounts_marked || [])
  const bottomAccounts = computed(() => taskStats.value.least_accounts_marked || [])

  return {
    isLoading,
    taskStats: taskStatsValue,
    reportTags,
    untaggedTasks,
    topAccounts,
    bottomAccounts,
    loadTaskStats,
    afterTaskMarkComplete,
    afterTaskMarkUnComplete
  }
}
