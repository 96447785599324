<template>
  <div>
    <confirm-delete ref="deleteDialog" />
    <schedule-new-operation-dialog v-if="isValidApiKey" ref="scheduleNewOperation" :api-key="taskApiKey" />
    <share-content ref="shareDialog" :share-content="createShareContent" show-dialog />
    <single-task-notification ref="singleTaskNotification" />
    <image-carousel-dialog ref="imageCarouselDialog" />
    <chat-dialog
      ref="chatDialog"
      record-type="OperationSchedule"
      @update-message-count="updateMessageCount"
      @mark-read-all="markReadAllChatMessages"
    />
    <report-item-operation-list-dialog
      ref="reportItemOperationListDialog"
      :read-only="tasksDisabled"
      :client-date="clientDateIso"
    />
  </div>
</template>
<script>
import ConfirmDelete from '@/components/shared/ConfirmDelete.vue'
import ScheduleNewOperationDialog from '@/components/operation_schedule_dialog/ScheduleNewOperationDialog.vue'
import ShareContent from '@/components/shared/ShareContent.vue'
import SingleTaskNotification from '@/components/shared/SingleTaskNotification.vue'
import ImageCarouselDialog from '@/components/shared/ImageCarouselDialog.vue'
import ChatDialog from '@/components/chat_dialog/ChatDialog.vue'
import ReportItemOperationListDialog from '@/components/shared/item_operation_list_dialog/ReportItemOperationListDialog.vue'

import useHQDailyTasks from '@/composables/useHQDailyTasks.js'
import useHQTasksStats from '@/composables/useHQTasksStats.js'

import { createShareContent as createOperationShareContent } from '@/classes/OperationScheduleShareContent.js'

import {
  EventBus, TASK_DELETE_CLICK, TASK_COPY_CLICK, TASK_SHARE_CLICK, TASK_EDIT_CLICK,
  TASK_NOTIFICATION_CLICK, TASK_IMAGE_CLICK, TASK_CHAT_CLICK, TASK_GUIDANCE_IMAGE_CLICK,
  TASK_TOGGLE_COMPLETE_CLICK, NEW_CROSS_ACCOUNT_OPERATION, HQ_TASKS_FILTER_CHANGED
} from '@/EventBus.js'

import { isString } from '@/helpers/Utils.js'

import { i18n } from '@/i18n.js'

import { ref, onUnmounted, nextTick, computed } from 'vue'

let shareTask = null

export default {
  components: {
    'confirm-delete': ConfirmDelete,
    'schedule-new-operation-dialog': ScheduleNewOperationDialog,
    'share-content': ShareContent,
    'single-task-notification': SingleTaskNotification,
    'image-carousel-dialog': ImageCarouselDialog,
    'chat-dialog': ChatDialog,
    'report-item-operation-list-dialog': ReportItemOperationListDialog
  },
  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const deleteDialog = ref(null)
    const scheduleNewOperation = ref(null)
    const shareDialog = ref(null)
    const singleTaskNotification = ref(null)
    const imageCarouselDialog = ref(null)
    const chatDialog = ref(null)
    const reportItemOperationListDialog = ref(null)

    const changingApiKey = ref(false)
    const taskApiKey = ref(null)

    const {
      clientDate, tasksDisabled, clientDateIso, findTask, deleteTask, applyFilters, afterTaskComplete, afterTaskUnComplete
    } = useHQDailyTasks(props.apiKey)

    const { afterTaskMarkComplete: updateStatsAfterTaskComplete, afterTaskMarkUnComplete: updateStatsafterTaskUnComplete } = useHQTasksStats(props.apiKey)

    const showTaskReportedImageDialog = () => {
      const deleteDialogParams = { content: i18n.t('tasks_page.delete_task_image') }
      return deleteDialog.value.open(deleteDialogParams)
    }

    const taskMarkCompletedCallback = (task) => {
      updateStatsAfterTaskComplete()
      afterTaskComplete(task)
    }

    const taskMarkUnCompletedCallback = (task) => {
      updateStatsafterTaskUnComplete()
      afterTaskUnComplete(task)
    }
    const openSubTaskList = (task) => {
      const oldIsReported = task.isReported()
      reportItemOperationListDialog.value.openDialogWithReactiveOperation(task)
        .then((isUpdated) => {
          if (!isUpdated) return
          const newIsReported = task.isReported()
          if (oldIsReported === newIsReported) return
          if (newIsReported) {
            taskMarkCompletedCallback(task)
          } else {
            taskMarkUnCompletedCallback(task)
          }
        })
    }

    const markTaskAsNotComplete = async (task) => {
      if (task.requireImage()) {
        const isDeleted = await showTaskReportedImageDialog()
        if (!isDeleted) return false
        task.cancelOperationReport()
          .then(() => {
            taskMarkUnCompletedCallback(task)
            deleteDialog.value.close()
          })
      } else {
        task.cancelOperationReport()
          .then(() => taskMarkUnCompletedCallback(task))
      }
    }

    const markTaskAsComplete = ({ task, event }) => {
      if (task.requireImage()) {
        const validFiles = event.target?.files?.length > 0
        if (!validFiles) return true
        const file = event.target.files[0]
        if (!file) return
        task.uploadImageForOperation({ clientDate: clientDate.value, file })
          .then(() => taskMarkCompletedCallback(task))
      } else {
        task.markOperationAsCompleted(clientDate.value)
          .then(() => taskMarkCompletedCallback(task))
      }
    }

    const showDeleteTaskDialog = (task) => {
      const description = task.operation_description || ''
      const deleteDialogParams = { title: i18n.t('job_operation_edit.delete_operation') }
      if (isString(description) && description.trim().length > 0) {
        deleteDialogParams.content = description
      }
      return deleteDialog.value.open(deleteDialogParams)
    }

    const onDeleteTask = async (task) => {
      const isDeleted = await showDeleteTaskDialog(task.operationRef)
      if (!isDeleted) return false

      await deleteTask(task)

      deleteDialog.value.close()
      return true
    }

    const onCopyTask = async (taskWrapper) => {
      const task = taskWrapper.operationRef
      taskApiKey.value = task.account_api_key
      changingApiKey.value = true
      nextTick(() => {
        changingApiKey.value = false
        nextTick(() => scheduleNewOperation.value.openForCopy({ operation: task }))
      })
    }

    const createShareContent = (medium) => {
      return createOperationShareContent({
        operation: shareTask,
        currentDate: clientDate.value,
        apiKey: props.apiKey,
        hideShifts: props.staffAccount,
        medium
      })
    }

    const onShareTask = (task) => {
      const taskRef = task.operationRef
      const pdfShareEnabled = task.tasksList().length > 0

      shareTask = JSON.parse(JSON.stringify(taskRef))
      const title = i18n.t('operation_share.title')
      shareDialog.value.openDialog({ title, pdfShareEnabled })
    }

    const onEdiTtask = async (taskWrapper) => {
      const task = taskWrapper.operationRef
      taskApiKey.value = task.account_api_key
      changingApiKey.value = true
      nextTick(() => {
        changingApiKey.value = false
        nextTick(() => scheduleNewOperation.value.openForUpdate({ operation: task }))
      })
    }

    const onCreateTaskNotification = (task) => {
      const taskRef = task.operationRef
      singleTaskNotification.value.openDialog({ operation: taskRef, apiKey: taskRef.account_api_key })
        .then(({ isUpdated, onCompleteTaskNotification = null, onUnCompleteTaskNotification = null }) => {
          if (isUpdated !== true) return

          task.updateOperationNotifications({ onCompleteTaskNotification, onUnCompleteTaskNotification })
        })
    }

    const onImageClick = (task) => {
      const imageUrl = task.operationRef.image_url
      imageCarouselDialog.value.openDialog({ imageUrls: [imageUrl] })
    }

    const showChatDialog = (task) => chatDialog.value.openDialog({
      recordId: task.operationRef.operation_id,
      apiKey: task.operationRef.account_api_key,
      dialogTitle: task.operationRef.operation_description
    })

    const updateMessageCount = ({ recordId, messageCount }) => {
      const task = findTask(recordId)
      if (task !== null) task.updateOperationMessageCount(messageCount)
    }

    const markReadAllChatMessages = (recordId) => {
      const task = findTask(recordId)
      if (task !== null) task.updateOperationReadAllMessages()
    }

    const showTaskGuidanceImages = (task) => {
      const imageUrls = task.operationRef.guidance_images.map((image) => image.url)
      imageCarouselDialog.value.openDialog({ imageUrls })
    }

    const toggleTaskComplete = ({ task, event }) => {
      const hasSubTasks = task.tasksList().length > 0
      if (hasSubTasks) return openSubTaskList(task)

      if (task.isReported()) {
        markTaskAsNotComplete(task)
      } else {
        markTaskAsComplete({ task, event })
      }
    }

    const onNewTask = applyFilters
    const onFilterChanged = applyFilters

    // Computed
    const isValidApiKey = computed(() => !changingApiKey.value && taskApiKey.value !== null)

    EventBus.on(NEW_CROSS_ACCOUNT_OPERATION, onNewTask)
    EventBus.on(TASK_DELETE_CLICK, onDeleteTask)
    EventBus.on(TASK_COPY_CLICK, onCopyTask)
    EventBus.on(TASK_SHARE_CLICK, onShareTask)
    EventBus.on(TASK_EDIT_CLICK, onEdiTtask)
    EventBus.on(TASK_NOTIFICATION_CLICK, onCreateTaskNotification)
    EventBus.on(TASK_IMAGE_CLICK, onImageClick)
    EventBus.on(TASK_CHAT_CLICK, showChatDialog)
    EventBus.on(TASK_GUIDANCE_IMAGE_CLICK, showTaskGuidanceImages)
    EventBus.on(TASK_TOGGLE_COMPLETE_CLICK, toggleTaskComplete)
    EventBus.on(HQ_TASKS_FILTER_CHANGED, onFilterChanged)

    onUnmounted(() => {
      EventBus.off(NEW_CROSS_ACCOUNT_OPERATION, onNewTask)
      EventBus.off(TASK_DELETE_CLICK, onDeleteTask)
      EventBus.off(TASK_COPY_CLICK, onCopyTask)
      EventBus.off(TASK_SHARE_CLICK, onShareTask)
      EventBus.off(TASK_EDIT_CLICK, onEdiTtask)
      EventBus.off(TASK_NOTIFICATION_CLICK, onCreateTaskNotification)
      EventBus.off(TASK_IMAGE_CLICK, onImageClick)
      EventBus.off(TASK_CHAT_CLICK, showChatDialog)
      EventBus.off(TASK_GUIDANCE_IMAGE_CLICK, showTaskGuidanceImages)
      EventBus.off(TASK_TOGGLE_COMPLETE_CLICK, toggleTaskComplete)
      EventBus.off(HQ_TASKS_FILTER_CHANGED, onFilterChanged)
    })

    return {
      deleteDialog,
      scheduleNewOperation,
      shareDialog,
      singleTaskNotification,
      imageCarouselDialog,
      chatDialog,
      reportItemOperationListDialog,
      clientDateIso,
      changingApiKey,
      taskApiKey,
      isValidApiKey,
      tasksDisabled,
      createShareContent,
      updateMessageCount,
      markReadAllChatMessages
    }
  }
}
</script>
