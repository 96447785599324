<template>
  <div class="faults-page full-height">
    <v-tabs
      v-model="selectedTab" class="mt-3 sticky elevation-2" slider-color="black"
      color="white"
      :class="{ 'rtl': isRtl, 'ltr': !isRtl }" @change="tabChange"
    >
      <v-tab href="#brokenItems">
        {{ $t('account_fault_devices.faulty_device_tab') }}
      </v-tab>
      <v-tab v-if="showBrokenItemHistory" href="#brokenItemsHistory">
        {{ $t('account_fault_devices.faulty_device_history_tab') }}
      </v-tab>
      <v-spacer />
      <span v-if="!$vuetify.breakpoint.xsOnly" class="t-500-14 mme-8 black--text d-flex align-center">
        {{ currentTranslatedDay }}
        {{ formmatedDate }}
      </span>
    </v-tabs>
    <v-tabs-items v-model="selectedTab" touchless class="tab-content">
      <v-tab-item value="brokenItems">
        <broken-items-layout />
      </v-tab-item>
      <v-tab-item v-if="showBrokenItemHistory" value="brokenItemsHistory" class="full-height">
        <items-layout />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import BrokenItemsLayout from '@/components/broken_items/BrokenItemsLayout.vue'
import ItemsLayout from '@/components/items/ItemsLayout.vue'

import useFeatures from '@/composables/useFeatures.js'
import useAuth from '@/composables/useAuth.js'
import useAccount from '@/composables/useAccount.js'

import { translateDayFromDate, getClientDateFormatted } from '@/helpers/DateTime.js'

import { isString } from '@/helpers/Utils.js'
import { loadTab, saveTab } from '@/helpers/TabPersist.js'

import { useRoute } from 'vue-router/composables'

import { i18n, isRtl } from '@/i18n.js'
import { computed, ref } from 'vue'

const TABS = {
  brokenItems: 'brokenItems',
  brokenItemsHistory: 'brokenItemsHistory'
}

export default {
  components: {
    'broken-items-layout': BrokenItemsLayout,
    'items-layout': ItemsLayout
  },
  setup () {
    const route = useRoute()
    const tab = route.params.tab
    const selectedTab = ref(loadTab({ defaultTab: TABS.brokenItems }))
    if (isString(tab) && Object.values(TABS).includes(tab)) {
      selectedTab.value = tab
    }
    const currentDate = ref(new Date())
    const accountFaultyDevice = ref(null)
    const accountFaultyDeviceReports = ref(null)
    const hasBrokenItemsHistoryReadFeature = ref(false)
    const { apiKey, isAccountAdminOrAbove } = useAuth()
    const { hasFeature, loadFeatures } = useFeatures(apiKey.value)

    const setHasFeatures = async () => {
      await loadFeatures()
      hasBrokenItemsHistoryReadFeature.value = hasFeature('broken_item_history_read')
    }

    // Methods
    const tabChange = () => {
      saveTab({ currentTab: selectedTab.value })
    }

    // Computed

    const currentTranslatedDay = computed(() => i18n.t('currnent_day', { dayString: translateDayFromDate(currentDate.value) }))
    const showBrokenItemHistory = computed(() => {
      if (isAccountAdminOrAbove.value === true) return true
      return hasBrokenItemsHistoryReadFeature.value
    })
    const formmatedDate = computed(() => {
      const { accountCountryCode } = useAccount()
      const countryCode = accountCountryCode.value
      return getClientDateFormatted({ countryCode, fromDate: currentDate.value })
    })

    if (isAccountAdminOrAbove.value !== true) setHasFeatures()

    return {
      selectedTab,
      accountFaultyDevice,
      accountFaultyDeviceReports,
      currentTranslatedDay,
      formmatedDate,
      showBrokenItemHistory,
      apiKey,
      isRtl,
      tabChange
    }
  }
}
</script>

<style lang="scss">
.faults-page {
  .top-part-image {
    background-image: url(@/assets/fault_cover.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 250px;
  }

  .v-expansion-panel-header.small-header {
    height: 42px;
    min-height: 42px;
  }

  .tab-content {
    height: 100%;
    margin-top: 49px;
  }

  @media only screen and (max-width: 960px) {
    .sticky {
      top: 56px !important;
    }
  }

  @media only screen and (min-width: 600px) {
    padding-bottom: 30px;
  }

  @media only screen and (max-width: 600px) {
    .tab-content {
      margin-top: 0px;
    }
  }

  .sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 60px;
    z-index: 5;
  }
}
</style>
