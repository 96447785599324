<template>
  <v-row v-if="account.tasksData.isLoadingTasks">
    <v-col cols="12">
      <v-skeleton-loader
        v-for="i in 3" :key="i" class="mt-3"
        type="list-item-three-line"
      />
    </v-col>
  </v-row>
  <v-row
    v-else
    class="single-account-tasks-list"
    align="start"
    justify="start"
    no-gutters
  >
    <v-col
      v-for="task in tasksListForAccount" :key="task.operation_id" cols="12"
      class="mb-2"
    >
      <div v-if="task.jobHeader" class="t-700-17 color5 mms-4 mt-4">
        <v-icon class="material-icons-outlined" color="rgba(0, 0, 0, 0.54)">
          how_to_reg
        </v-icon>
        {{ task.jobName }} <span v-if="task.showJobDepartment"><v-divider vertical class="job-divider mx-1" /> {{ task.jobDepartmentName }}</span>
        <v-divider vertical class="job-divider mx-1" /> {{ task.jobShift }}
      </div>
      <single-task-layout
        v-else
        :client-date="clientDateIso"
        :task="task"
        :task-enabled-options="TASK_OPTIONS"
        :disabled="tasksDisabled"
        hq-view
      />
    </v-col>
    <v-col v-if="account.tasksData.isLoadingTasksNextPage" cols="12">
      <v-skeleton-loader
        v-for="i in 3" :key="i" class="mt-3"
        type="list-item-three-line"
      />
    </v-col>
    <v-col v-if="hasMoreTasksToLoad" v-intersect.once="onIntersect" cols="12" />
  </v-row>
</template>
<script>
import SingleTaskLayout from '@/components/tasks/tasks_list/single_task/SingleTaskLayout.vue'

import useHQDailyTasks from '@/composables/useHQDailyTasks.js'
import { translateShift } from '@/store/modules/OrganizationJob.js'
import { isString } from '@/helpers/Utils.js'

import { computed } from 'vue'

const TASK_OPTIONS = {
  edit: true,
  delete: true,
  share: true,
  notification: true,
  copy: true
}
export default {
  components: {
    'single-task-layout': SingleTaskLayout
  },
  props: {
    apiKey: {
      type: String,
      required: true
    },
    account: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const { clientDateIso, tasksDisabled, loadSingleAccountTasks, loadSingleAccountTasksNextPage } = useHQDailyTasks(props.apiKey)

    // Methods

    const onIntersect = () => {
      if (hasMoreTasksToLoad.value) {
        loadSingleAccountTasksNextPage(props.account.api_key)
      }
    }
    // Computed
    const tasksListForAccount = computed(() => {
      const result = []

      let currentJobId = -1
      props.account.tasksData.tasksList.forEach(task => {
        if (task.operationRef.account_job_id !== currentJobId) {
          currentJobId = task.operationRef.account_job_id
          const opRef = task.operationRef
          const showJobDepartment = isString(opRef.account_job_department_name) && opRef.account_job_department_name.length > 0
          result.push({
            jobHeader: true,
            id: `${currentJobId}_job_task_${task.operation_id}`,
            jobName: opRef.account_job_name,
            jobShift: translateShift(opRef.account_job_shift),
            showJobDepartment,
            jobDepartmentName: opRef.account_job_department_name
          })
        }
        result.push(task)
      })
      return result
    })

    const hasMoreTasksToLoad = computed(() => {
      const taskData = props.account.tasksData
      if (taskData.isLoadingTasks || taskData.isLoadingTasksNextPage) return false
      return taskData.tasksList.length < props.account.tasks_count
    })

    if (props.account.tasksData.tasksList.length === 0 && props.account.tasks_count > 0) {
      loadSingleAccountTasks(props.account.api_key)
    }

    return {
      TASK_OPTIONS,
      tasksListForAccount,
      clientDateIso,
      tasksDisabled,
      hasMoreTasksToLoad,
      onIntersect
    }
  }
}
</script>
<style lang="scss">
.single-account-tasks-list {
  .single-task {
    border-radius: 16px;
    box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.06), 0px 2px 6px 0px rgba(0, 0, 0, 0.04), 0px 0px 1px 0px rgba(0, 0, 0, 0.04);
  }
  .job-divider {
    height: 20px;
    background-color: #C9C9C9;
  }
}
</style>
