<template>
  <v-row
    v-if="show"
    align="start" :justify="showAvgStars ? 'space-between' : 'end'" no-gutters
    class="mt-2"
  >
    <v-col v-if="showAvgStars" class="flex-grow-1 flex-shrink-0">
      <reported-item-list-star-avg :items-list="task.operationRef.item_operation_list" />
    </v-col>
    <v-col v-if="isExpanded" class="flex-grow-0 flex-shrink-1 d-flex align-start">
      <v-icon
        v-if="showGuidanceImages"
        color="#0000008a"
        class="pointer material-icons-outlined"
        @click="onGuidanceImageClick"
      >
        image
      </v-icon>
      <v-icon
        v-if="showGuidanceFile"
        class="mms-3 pointer"
        color="#0000008a"
        @click="onGuidanceFileClick"
      >
        attach_file
      </v-icon>
    </v-col>
  </v-row>
</template>
<script>
import ReportedItemListAvarage from '@/components/tasks/tasks_list/single_task/ReportedItemListAvarage.vue'
import { itemTypes } from '@/models/ItemOperationListItemTypes.js'

import { isString } from '@/helpers/Utils.js'

import { EventBus, TASK_GUIDANCE_IMAGE_CLICK } from '@/EventBus.js'

import { computed } from 'vue'
export default {
  components: {
    'reported-item-list-star-avg': ReportedItemListAvarage
  },
  props: {
    task: {
      type: Object,
      required: true
    },
    crossAccountView: {
      type: Boolean,
      default: false
    },
    isExpanded: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    // Methods
    const onGuidanceImageClick = () => EventBus.emit(TASK_GUIDANCE_IMAGE_CLICK, props.task)
    const onGuidanceFileClick = () => window.open(props.task.operationRef.guidance_file, '_blank').focus()

    // Computed
    const showAvgStars = computed(() => (props.isExpanded || props.crossAccountView) && props.task.tasksList().some((item) => item.item_type === itemTypes.rating))
    const showGuidanceImages = computed(() => {
      const guidanceImages = props.task.operationRef.guidance_images
      return Array.isArray(guidanceImages) && guidanceImages.length > 0
    })
    const showGuidanceFile = computed(() => {
      const guidanceFileUrl = props.task.operationRef.guidance_file
      return isString(guidanceFileUrl) && guidanceFileUrl.length > 0
    })
    const show = computed(() => showGuidanceImages.value || showGuidanceFile.value || showAvgStars.value)
    return {
      showGuidanceImages,
      showGuidanceFile,
      showAvgStars,
      show,
      onGuidanceImageClick,
      onGuidanceFileClick
    }
  }
}

</script>
