<template>
  <v-app light :class="{ 'he-lang': isHebrow }">
    <add-to-home-screen v-if="showPWAInstallPropmt" />
    <app-version-check />
    <live-notifications />
    <install-app-prompt v-if="didMount" />
    <router-view v-if="isLoaded" />
  </v-app>
</template>

<script>
import AddToHomeScreen from '@/components/install_prompt/InstallPromptLayout.vue'
import AppVersionCheck from '@/components/AppVersionCheck.vue'
import LiveNotifications from '@/components/layout/LiveNotifications.vue'

import { initLanguage } from '@/composables/useLanguage.js'
import { i18n } from '@/i18n.js'

import { EventBus, USER_CONNECTED } from '@/EventBus.js'

import useAuth, { isAuthenticated } from '@/composables/useAuth.js'

import { getPageQueryParamsObject } from '@/helpers/Url.js'
import { isObject, isString } from '@/helpers/Utils.js'
import { loadStyleFromApiKey } from '@/classes/SiteCustomStyleLoader.js'
import { onMounted as webSocketProviderOnMount } from '@/classes/WebSocketProvider.js'
import Client from '@/api_client/client.js'
import useCompany from '@/composables/useCompany.js'

import { ref, onMounted, computed } from 'vue'
import { useRoute } from 'vue-router/composables'

const API_KEY_CHANGED_FROM_URL = 'API_KEY_CHANGED_FROM_URL'

const apiKeyLoadedFromUrl = () => sessionStorage.getItem(API_KEY_CHANGED_FROM_URL) !== null
const getNewApiKeyFromUrl = () => {
  const queryParams = getPageQueryParamsObject()
  if (!isObject(queryParams)) return null
  const newApiKey = queryParams.api_key
  return isString(newApiKey) && newApiKey.trim().length > 0 ? newApiKey : null
}

export default {
  name: 'App',
  components: {
    'add-to-home-screen': AddToHomeScreen,
    'app-version-check': AppVersionCheck,
    'live-notifications': LiveNotifications,
    'install-app-prompt': () => import('@/components/InstallAppPrompt.vue')
  },
  setup () {
    const router = useRoute()
    const didMount = ref(false)
    const isLoaded = ref(false)
    const { tryLoginUser } = useAuth()
    tryLoginUser()

    const addClientInterceptors = () => {
      Client.interceptors.response.use(undefined, (err) => {
        return new Promise((resolve, reject) => {
          if (err.response && err.response.status === 401 && router.name !== 'verify_user') {
            const { loginError } = useAuth()
            loginError()
          }
          return reject(err)
        })
      })
    }

    const onAppLoad = async () => {
      addClientInterceptors()
      webSocketProviderOnMount()
      initLanguage()
      if (!isAuthenticated.value) return

      const { setApiKey, apiKey } = useAuth()
      EventBus.emit(USER_CONNECTED)

      const newApiKey = getNewApiKeyFromUrl()
      if (newApiKey !== null && newApiKey !== apiKey.value && !apiKeyLoadedFromUrl()) {
        sessionStorage.setItem(API_KEY_CHANGED_FROM_URL, true)
        setApiKey(newApiKey)
        return location.reload()
      }
      const { initIsMultipleMemberships } = useCompany(apiKey.value)
      initIsMultipleMemberships()
      loadStyleFromApiKey(apiKey.value)
      const { loadLoginData, afterLogin } = useAuth()
      return Promise.all([loadLoginData(), afterLogin()])
    }

    // Computed
    const showPWAInstallPropmt = computed(() => router.name === 'login')
    const isHebrow = computed(() => i18n.locale === 'he')
    onMounted(() => (didMount.value = true))
    onAppLoad()
      .finally(() => (isLoaded.value = true))
    return {
      didMount,
      showPWAInstallPropmt,
      isLoaded,
      isHebrow
    }
  }
}
</script>
<style  lang="scss">
#app {
  background-color: #F4F6F8;
  font-family: 'Inter';
}
#app.he-lang {
  font-family: 'Assistant' !important;
}
</style>
