<template>
  <v-combobox
    :value="value"
    :items="items"
    hide-selected
    :loading="isLoading"
    :search-input.sync="search"
    :label="labelValue"
    :menu-props="menuPropsWithClass"
    multiple
    persistent-hint
    clearable
    class="tag-select"
    small-chips
    :background-color="backgroundColor"
    outlined
    :disabled="disabled"
    :dense="dense"
    @input="onInput"
    @change="onChange"
  >
    <template #selection="{ item, selected }">
      <v-chip
        :input-value="selected"
        label
        color="#3E8BFE"
        small
      >
        <span class="pr-2 white--text">
          {{ item }}
        </span>
        <v-icon
          small
          @click="deleteTag(item)"
        >
          remove_circle
        </v-icon>
      </v-chip>
    </template>
    <template #item="{ item }">
      <v-chip
        label
        color="#3E8BFE"
        small
      >
        <span class="pr-2 white--text">
          {{ item }}
        </span>
        <v-icon
          small
        >
          label
        </v-icon>
      </v-chip>
    </template>
  </v-combobox>
</template>
<script>

import useTagChipSearch from '@/components/shared/tag_chips_select/useTagChipSearch.js'
import { isString, isObject } from '@/helpers/Utils.js'

import { isRtl, i18n } from '@/i18n.js'

import { ref, watch, computed } from 'vue'
export default {
  props: {
    value: {
      type: Array,
      required: false,
      default: () => []
    },
    maxTags: {
      type: Number,
      required: false,
      default: null
    },
    context: {
      type: String,
      required: true
    },
    menuProps: {
      type: Object,
      required: false,
      default: () => ({ closeOnContentClick: true })
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    apiKey: {
      type: String,
      required: true
    },
    backgroundColor: {
      type: String,
      required: false,
      default: null
    },
    dense: {
      type: Boolean,
      required: false,
      default: true
    },
    label: {
      type: String,
      required: false,
      default: null
    }
  },
  setup (props, { emit }) {
    const isLoading = ref(false)
    const search = ref('')

    const { loadTags, tagsForContext, changeApiKey } = useTagChipSearch(props.apiKey)
    const items = ref(tagsForContext(props.context))

    const innerLoadTags = (searchTerm) => {
      isLoading.value = true
      loadTags({ context: props.context, searchTerm })
        .then((newTags) => (items.value = newTags))
        .finally(() => (isLoading.value = false))
    }

    // Methods

    const deleteTag = (tag) => {
      const index = props.value.indexOf(tag)
      if (index > -1) {
        const newValue = props.value.slice()
        newValue.splice(index, 1)
        onInput(newValue)
      }
    }

    const onInput = (newValue) => emit('input', newValue)
    const onChange = (newValue) => {
      if (props.maxTags === null || !Number.isInteger(props.maxTags)) return emit('change', newValue)
      if (newValue.length > props.maxTags) {
        return onInput(newValue.slice(0, props.maxTags))
      } else {
        emit('change', newValue)
      }
    }

    // Computed
    const menuPropsWithClass = computed(() => {
      const result = isObject(props.menuProps) ? Object.assign(props.menuProps, {}) : {}
      result.contentClass = isString(result.contentClass) ? result.contentClass : ''
      const addedClass = isRtl.value ? 'rtl' : 'ltr'
      result.contentClass = `${result.contentClass} ${addedClass} tag-select`
      return result
    })

    const labelValue = computed(() => isString(props.label) ? props.label : i18n.t('tags_select.placeholder'))

    watch(() => search.value, (newSearch, oldSearch) => {
      if (newSearch === oldSearch) return
      innerLoadTags(newSearch)
    })

    watch(() => props.apiKey, (newValue) => {
      if (!isString(newValue)) return
      changeApiKey(newValue)
      innerLoadTags()
    })
    return {
      isLoading,
      items,
      search,
      labelValue,
      menuPropsWithClass,
      onInput,
      deleteTag,
      onChange
    }
  }
}
</script>
<style lang="scss">
.tag-select {
  fieldset {
    legend {
      display: none;
    }
  }
}
</style>
