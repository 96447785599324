import Client from '@/api_client/client.js'

const indexOperationsByDay = (apiKey, params) => {
  const COMPANY_INDEX_OPERATIONS_BY_DAY_PATH = `/accounts/${apiKey}/company/operation_schedules/index_operation_by_day`
  return Client.post(COMPANY_INDEX_OPERATIONS_BY_DAY_PATH, params)
}

const dailyStats = (apiKey, params) => {
  const COMPANY_DAILY_STATS_PATH = `/accounts/${apiKey}/company/operation_schedules/daily_stats`
  return Client.put(COMPANY_DAILY_STATS_PATH, params)
}

const accountTotals = (apiKey, params) => {
  const COMPANY_TASKS_ACCOUNT_TOTALS_PATH = `/accounts/${apiKey}/company/operation_schedules/account_task_totals`
  return Client.put(COMPANY_TASKS_ACCOUNT_TOTALS_PATH, params)
}

export {
  indexOperationsByDay,
  dailyStats,
  accountTotals
}
