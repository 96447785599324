const colors = [
  '#E74856', '#B5CD20', '#DC1072', '#75AA26', '#01BCF2',
  '#842A37', '#4EBA9A', '#2A7D29', '#F496C0', '#AD6203',
  '#CF500E', '#3E8BFE', '#C92EB9', '#FF8B00', '#8964B8'
]

const stringToHash = (str) => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  return Math.abs(hash)
}

export const userToColor = ({ firstName = '', lastName = '' }) => {
  const fullName = `${firstName}${lastName}`
  const hash = stringToHash(fullName)
  return colors[hash % colors.length]
}
