<template>
  <v-autocomplete
    :value="value"
    :items="departmentsWithHeaders"
    :search-input.sync="search"
    :disabled="disabled"
    :class="outerClassValue"
    autocomplete="off"
    clearable
    item-text="name"
    :height="height"
    :menu-props="{ contentClass: contentClass }"
    item-value="id"
    multiple
    :loading="isLoading"
    :placeholder="$t('account_department_select.placeholder')"
    @change="emitChange"
    @click:clear="clear"
    @input="update"
  >
    <template #item="{ item: department, attrs }">
      <v-list-item-action class="mme-4">
        <v-icon v-if="attrs.inputValue">
          check_box
        </v-icon>
        <v-icon v-else>
          check_box_outline_blank
        </v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>
          <div class="font-weight-bold">
            {{ department.name }}
            <span v-if="showAccountName" class="black--text t-500-13">
              ({{ department.account_name }})
            </span>
          </div>
        </v-list-item-title>
      </v-list-item-content>
    </template>
    <template #selection="{ index }">
      <div v-if="index === 0" class="v-select__selection v-select__selection--comma">
        {{ $t('account_department_select.x_selected', { count: value.length }) }}
      </div>
    </template>
  </v-autocomplete>
</template>
<script>
import { companySearch as companyDepartmentClientSearch } from '@/api_client/AccountDepartment.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'
import { isString } from '@/helpers/Utils.js'

import { i18n, isRtl } from '@/i18n.js'

import { ref, computed, watch } from 'vue'

const EMPTY_DEPARTMENT_ID = 'empty_department'
export default {
  props: {
    apiKey: {
      type: String,
      required: true
    },
    value: {
      type: Array,
      required: false,
      default: () => {
        return []
      }
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    height: {
      type: [Number, String],
      required: false,
      default: undefined
    },
    accountApiKeys: {
      type: [Array, String],
      required: false,
      default: () => []
    },
    outerClass: {
      type: String,
      required: false,
      default: ''
    },
    addEmptyDepartment: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup (props, { emit }) {
    const isLoading = ref(false)
    const companyDepartments = ref([])
    const search = ref(null)

    // Methods
    const clear = () => emit('click:clear')
    const update = (newValue) => emit('input', newValue)

    const emitChange = (newValue) => {
      if (newValue === null) return
      search.value = null
      emit('change', newValue)
    }

    const loadCompanyDepartments = () => {
      isLoading.value = true
      return companyDepartmentClientSearch({
        apiKey: props.apiKey,
        term: search.value,
        accountApiKeys: props.accountApiKeys
      })
        .then((response) => (companyDepartments.value = response.data))
        .catch(errHandler)
        .finally(() => (isLoading.value = false))
    }

    // Computed
    const showAccountName = computed(() => isString(search.value) && search.value.length > 0)
    const contentClass = computed(() => `company-department-select ${(isRtl.value ? 'rtl' : 'ltr')}`)
    const departmentsWithHeaders = computed(() => {
      const result = []
      if (companyDepartments.value.length === 0) return result
      const companyDepartmentsVal = companyDepartments.value
      let currentApiKey = null
      companyDepartmentsVal.forEach((department) => {
        if (department.account_api_key !== currentApiKey) {
          result.push({ header: department.account_name })
          currentApiKey = department.account_api_key
        }
        result.push(department)
      })
      if (!props.addEmptyDepartment) return result

      return [{ id: EMPTY_DEPARTMENT_ID, name: i18n.t('total_tasks_by_department.empty_department') }, ...result]
    })

    const outerClassValue = computed(() => {
      const outerClass = props.outerClass
      const classRes = isString(outerClass) && outerClass.length > 0
        ? `company-department-select ${outerClass}`
        : 'company-department-select'
      return classRes
    })

    loadCompanyDepartments()

    watch(() => search.value, (query) => {
      if (isLoading.value === true) return
      if (isString(query) && companyDepartments.value.some((job) => job.name === query)) return

      loadCompanyDepartments()
    })
    watch(() => props.accountApiKeys, () => {
      loadCompanyDepartments()
      const newValue = props.value.filter((job) => props.accountApiKeys.includes(job.account_api_key))
      update(newValue)
    }, { deep: true })
    return {
      isLoading,
      search,
      departmentsWithHeaders,
      showAccountName,
      contentClass,
      outerClassValue,
      clear,
      emitChange,
      update
    }
  }
}
</script>
<style lang="scss">
.company-department-select {
  div.v-subheader {
    background-color: #E8E8E8;
    font-weight: 700;
    height: 30px;
  }
}
</style>
