
import { isObject } from '@/helpers/Utils.js'
const filedList = ['id', 'user_id', 'content', 'content_translated', 'created_at_date', 'created_at_time', 'created_at_date_raw']

function RecordMessage (src) {
  const srcOrEmpty = src || { }
  filedList.forEach((field) => {
    this[field] = srcOrEmpty[field]
  })
  if (isObject(srcOrEmpty.user)) {
    this.first_name = srcOrEmpty.user.first_name
    this.last_name = srcOrEmpty.user.last_name
  }
  this.displayName = `${this.first_name} ${this.last_name}`
  this.deleteInProgress = false
}

RecordMessage.prototype.serialize = function () {
  const creationParams = JSON.parse(JSON.stringify(this))
  const result = filedList.reduce((initValue, currentValue) => {
    initValue[currentValue] = creationParams[currentValue]
    return initValue
  }, {})
  delete result.deleteInProgress
  delete result.created_at_date_raw
  return result
}

function newRecordMessageModel (src) {
  return new RecordMessage(src)
}

export {
  newRecordMessageModel
}
