
import { deleteJob as JobClientDelete, index as JobClientIndex } from '@/api_client/AccountJob.js'

import { newJobModel } from '@/store/modules/OrganizationJob.js'

import { getDayString } from '@/helpers/DateTime.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { isString } from '@/helpers/Utils.js'

import { ref, computed } from 'vue'

const DEFAULT_PER_PAGE = 60
const DEFAULT_PAGE = 1

const defaultPagination = () => ({ page: DEFAULT_PAGE, per_page: DEFAULT_PER_PAGE, total: 0, totalPages: 0 })
const defaultFilters = () => ({ accountJobs: [], accountDepartments: [], shifts: [] })
const isLoading = ref(false)
const isLoadingNextPage = ref(false)
const currentDay = ref(getDayString())
const pagination = ref(defaultPagination())
const jobList = ref([])
const filters = ref(defaultFilters())
let currentApiKey = null

export default function useAccountJob (apiKey) {
  const resetValues = () => {
    isLoading.value = false
    isLoadingNextPage.value = false
    pagination.value = defaultPagination()
    jobList.value.splice(0, jobList.value.length)
    filters.value = defaultFilters()
  }

  if (isString(apiKey) && currentApiKey !== apiKey) {
    resetValues()
    currentApiKey = apiKey
  }

  const setPaginationFromResponse = (responseMeta) => {
    pagination.value = Object.assign({}, pagination.value, responseMeta)
    const total = pagination.value.total || 0
    if (total === 0 || total <= pagination.value.per_page) {
      pagination.value.totalPages = 0
    } else {
      pagination.value.totalPages = Math.ceil(total / pagination.value.per_page)
    }
  }

  // Methods

  const accountJobIndexParams = () => {
    const indexParams = { page: pagination.value.page, per_page: pagination.value.per_page, filters: {} }
    const filtersVal = filters.value
    if (Array.isArray(filtersVal.accountJobs) && filtersVal.accountJobs.length > 0) {
      indexParams.filters.job_ids = filtersVal.accountJobs.map((job) => job.id)
    }
    if (Array.isArray(filtersVal.accountDepartments) && filtersVal.accountDepartments.length > 0) {
      indexParams.filters.department_ids = filtersVal.accountDepartments.map((department) => department.id)
    }
    if (Array.isArray(filtersVal.shifts) && filtersVal.shifts.length > 0) {
      indexParams.filters.shifts = filtersVal.shifts
    }
    return indexParams
  }

  const resetAndLoad = () => {
    pagination.value.page = 1
    jobList.value.splice(0, jobList.value.length)
    loadTablePage()
  }

  const loadTablePage = ({ reset = false } = {}) => {
    if (reset) {
      pagination.value = defaultPagination()
      jobList.value.splice(0, jobList.value.length)
    }
    if (pagination.value.page > 1) {
      isLoadingNextPage.value = true
    } else {
      isLoading.value = true
    }
    const indexParams = accountJobIndexParams()
    return JobClientIndex(apiKey, indexParams)
      .then((response) => {
        response.data.data.forEach((item) => {
          const job = newJobModel(item)
          jobList.value.push(job)
        })
        setPaginationFromResponse(response.data.meta)
      })
      .catch(errHandler)
      .finally(() => {
        isLoading.value = false
        isLoadingNextPage.value = false
      })
  }

  const deleteJob = (jobId) => {
    return JobClientDelete(apiKey, jobId)
      .then(() => {
        pagination.value.page = 1
        resetAndLoad()
      })
      .catch(errHandler)
  }
  const showLoadMorePages = computed(() => pagination.value.total > jobList.value.length)

  const loadNextPage = () => {
    if (!showLoadMorePages.value || isLoadingNextPage.value) return
    pagination.value.page += 1
    return loadTablePage()
  }

  const resetFilters = () => (filters.value = defaultFilters())

  const applyFilters = resetAndLoad

  return {
    isLoading,
    pagination,
    jobList,
    filters,
    currentDay,
    showLoadMorePages,
    resetFilters,
    loadTablePage,
    deleteJob,
    loadNextPage,
    resetAndLoad,
    applyFilters
  }
}
