<template>
  <div v-if="!apiKeyChanging">
    <confirm-delete ref="deleteDialog" />
    <schedule-new-operation-dialog ref="scheduleNewOperation" :api-key="apiKey" />
    <schedule-new-staff-operation-dialog ref="scheduleNewStaffOperation" />
    <single-task-notification ref="singleTaskNotification" />
    <share-content ref="shareDialog" :share-content="createShareContent" show-dialog />
    <image-carousel-dialog ref="imageCarouselDialog" />
    <mark-out-of-shift-dialog ref="outOfShiftTime" />
    <chat-dialog
      ref="chatDialog"
      record-type="OperationSchedule"
      @update-message-count="updateMessageCount"
      @mark-read-all="markReadAllChatMessages"
    />
    <report-item-operation-list-dialog
      ref="reportItemOperationListDialog"
      :read-only="tasksDisabled"
      :client-date="clientDateIso"
    />
  </div>
</template>

<script>
import ConfirmDelete from '@/components/shared/ConfirmDelete.vue'
import ScheduleNewOperationDialog from '@/components/operation_schedule_dialog/ScheduleNewOperationDialog.vue'
import ScheduleNewStaffOperationDialog from '@/components/operation_schedule_dialog/ScheduleNewStaffOperationDialog.vue'
import SingleTaskNotification from '@/components/shared/SingleTaskNotification.vue'
import ImageCarouselDialog from '@/components/shared/ImageCarouselDialog.vue'
import ChatDialog from '@/components/chat_dialog/ChatDialog.vue'
import ShareContent from '@/components/shared/ShareContent.vue'
import MarkOutOfShiftDialog from '@/components/shared/MarkOutOfShiftDialog.vue'
import ReportItemOperationListDialog from '@/components/shared/item_operation_list_dialog/ReportItemOperationListDialog.vue'

import { getShiftDateTimes } from '@/store/modules/WeeklySchedule.js'

import { createShareContent as createOperationShareContent } from '@/classes/OperationScheduleShareContent.js'

import useDailyJobs from '@/composables/useDailyJobs.js'

import {
  EventBus, NEW_ACCOUNT_OPERATION, TASK_DELETE_CLICK,
  TASK_COPY_CLICK, TASK_SHARE_CLICK, TASK_EDIT_CLICK, TASK_NOTIFICATION_CLICK, TASK_IMAGE_CLICK,
  TASK_CHAT_CLICK, TASK_GUIDANCE_IMAGE_CLICK, TASK_TOGGLE_COMPLETE_PREVENTED_CLICK, TASK_TOGGLE_COMPLETE_CLICK
} from '@/EventBus.js'

import { isString, isObject } from '@/helpers/Utils.js'

import { i18n } from '@/i18n.js'

import { ref, onUnmounted, watch, nextTick } from 'vue'

let shareTask = null

export default {
  components: {
    'confirm-delete': ConfirmDelete,
    'schedule-new-operation-dialog': ScheduleNewOperationDialog,
    'schedule-new-staff-operation-dialog': ScheduleNewStaffOperationDialog,
    'single-task-notification': SingleTaskNotification,
    'image-carousel-dialog': ImageCarouselDialog,
    'chat-dialog': ChatDialog,
    'share-content': ShareContent,
    'mark-out-of-shift-dialog': MarkOutOfShiftDialog,
    'report-item-operation-list-dialog': ReportItemOperationListDialog
  },
  props: {
    apiKey: {
      type: String,
      required: true
    },
    staffAccount: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    const deleteDialog = ref(null)
    const scheduleNewOperation = ref(null)
    const scheduleNewStaffOperation = ref(null)
    const singleTaskNotification = ref(null)
    const shareDialog = ref(null)
    const imageCarouselDialog = ref(null)
    const chatDialog = ref(null)
    const reportItemOperationListDialog = ref(null)
    const outOfShiftTime = ref(null)
    const apiKeyChanging = ref(false)

    const {
      deleteTask, reloadJobsAndTasks, findJob, findTask,
      afterTaskMarkComplete, afterTaskMarkUnComplete, tasksDisabled, clientDateIso, jobsInShift, clientDate
    } = useDailyJobs(props.apiKey)

    const showDeleteTaskDialog = (task) => {
      const description = task.operation_description || ''
      const deleteDialogParams = { title: i18n.t('job_operation_edit.delete_operation') }
      if (isString(description) && description.trim().length > 0) {
        deleteDialogParams.content = description
      }
      return deleteDialog.value.open(deleteDialogParams)
    }

    const showTaskReportedImageDialog = (task) => {
      const deleteDialogParams = { content: i18n.t('tasks_page.delete_task_image') }
      return deleteDialog.value.open(deleteDialogParams)
    }

    const showEditOperationDialog = ({ task }) =>
      scheduleNewOperation.value.openForUpdate({ operation: task })

    const showStaffAccountEditOperationDialog = ({ task, shiftPartName }) => {
      const jobId = task.account_job_id
      const hasItemList = Array.isArray(task.item_operation_list)
      return scheduleNewStaffOperation.value.openStaffAccountUpdate({ operation: task, jobId, shiftPartName, hasItemList })
    }

    const jobShiftTimes = (jobId) => {
      const activeJob = findJob(jobId)
      if (!activeJob) return false

      return getShiftDateTimes({
        startTime: activeJob.shift_start_time,
        endTime: activeJob.shift_start_time,
        shiftSpanTwoDays: activeJob.shift_is_two_days,
        date: clientDate.value
      })
    }

    const showIsCorrectShiftDialog = (jobId) => {
      const jobShiftTime = jobShiftTimes(jobId)
      if (!isObject(jobShiftTime)) return

      const activeJob = findJob(jobId)
      const currentTime = new Date()
      const isBeforeShiftTime = currentTime.getTime() < jobShiftTime.startTimeDate.getTime()
      const isAfterShiftTime = currentTime.getTime() > jobShiftTime.endTimeDate.getTime()
      const { shiftStartTimeUserFormat: startTime, shiftEndTimeUserFormat: endTime } = activeJob
      const nextRoute = { isPreviousDay: isBeforeShiftTime, hidePassShift: isAfterShiftTime }
      const dialogData = {
        startTime,
        endTime,
        nextRoute,
        startTimeDate: jobShiftTime.startTimeDate
      }

      outOfShiftTime.value.openDialog(dialogData)
        .then(({ passShift } = {}) => {
          jobsInShift.add(jobId)
          if (isBeforeShiftTime && passShift === true) {
            const newDate = new Date(clientDate.value)
            newDate.setDate(newDate.getDate() - 1)
            emit('change-date', newDate)
          }
        })
    }

    const openSubTaskList = (task) => {
      const oldIsReported = task.isReported()
      reportItemOperationListDialog.value.openDialogWithReactiveOperation(task)
        .then((isUpdated) => {
          if (!isUpdated) return
          const newIsReported = task.isReported()
          if (oldIsReported === newIsReported) return
          if (newIsReported) {
            afterTaskMarkComplete(task)
          } else {
            afterTaskMarkUnComplete(task)
          }
        })
    }

    const markTaskAsComplete = ({ task, event }) => {
      const hasSubTasks = task.tasksList().length > 0
      if (hasSubTasks) return openSubTaskList(task)

      if (task.requireImage()) {
        const validFiles = event.target?.files?.length > 0
        if (!validFiles) return true
        const file = event.target.files[0]
        if (!file) return
        task.uploadImageForOperation({ clientDate: clientDate.value, file })
          .then(() => afterTaskMarkComplete(task))
      } else {
        task.markOperationAsCompleted(clientDate.value)
          .then(() => afterTaskMarkComplete(task))
      }
    }

    const markTaskAsNotComplete = async (task) => {
      const hasSubTasks = task.tasksList().length > 0
      if (hasSubTasks) return openSubTaskList(task)

      if (task.requireImage()) {
        const isDeleted = await showTaskReportedImageDialog(task.operationRef)
        if (!isDeleted) return false
        task.cancelOperationReport()
          .then(() => {
            afterTaskMarkUnComplete(task)
            deleteDialog.value.close()
          })
      } else {
        task.cancelOperationReport()
          .then(() => afterTaskMarkUnComplete(task))
      }
    }

    const onNewOperation = ({ jobId }) => reloadJobsAndTasks(jobId)

    // Methods
    const onDeleteTask = async (task) => {
      const isDeleted = await showDeleteTaskDialog(task.operationRef)
      if (!isDeleted) return false

      await deleteTask(task)

      deleteDialog.value.close()
      return true
    }

    const onEdiTtask = async (taskWrapper) => {
      const task = taskWrapper.operationRef
      const shiftPartName = task.shift_part
      if (props.staffAccount) return showStaffAccountEditOperationDialog({ task, shiftPartName })
      return showEditOperationDialog({ task })
    }

    const onCopyTask = async (taskWrapper) => {
      const task = taskWrapper.operationRef
      if (props.staffAccount) {
        scheduleNewStaffOperation.value.openStaffAccountCopy({ operation: task })
      } else {
        scheduleNewOperation.value.openForCopy({ operation: task })
      }
    }

    const onCreateTaskNotification = (task) => {
      const taskRef = task.operationRef
      singleTaskNotification.value.openDialog({ operation: taskRef, apiKey: taskRef.account_api_key })
        .then(({ isUpdated, onCompleteTaskNotification = null, onUnCompleteTaskNotification = null }) => {
          if (isUpdated !== true) return

          task.updateOperationNotifications({ onCompleteTaskNotification, onUnCompleteTaskNotification })
        })
    }
    const onShareTask = (task) => {
      const taskRef = task.operationRef
      const pdfShareEnabled = task.tasksList().length > 0
      const jobFound = findJob(taskRef.account_job_id)

      shareTask = JSON.parse(JSON.stringify(taskRef))
      shareTask.account_job_department_name = jobFound?.department_name
      const title = i18n.t('operation_share.title')
      shareDialog.value.openDialog({ title, pdfShareEnabled })
    }

    const showTaskGuidanceImages = (task) => {
      const imageUrls = task.operationRef.guidance_images.map((image) => image.url)
      imageCarouselDialog.value.openDialog({ imageUrls })
    }

    const createShareContent = (medium) => {
      return createOperationShareContent({
        operation: shareTask,
        currentDate: clientDate.value,
        apiKey: props.apiKey,
        hideShifts: props.staffAccount,
        medium
      })
    }
    const showChatDialog = (task) => chatDialog.value.openDialog({
      recordId: task.operationRef.operation_id,
      apiKey: task.operationRef.account_api_key,
      dialogTitle: task.operationRef.operation_description
    })

    const updateMessageCount = ({ recordId, messageCount }) => {
      const task = findTask(recordId)
      if (task !== null) task.updateOperationMessageCount(messageCount)
    }

    const markReadAllChatMessages = (recordId) => {
      const task = findTask(recordId)
      if (task !== null) task.updateOperationReadAllMessages()
    }

    const toggleTaskComplete = ({ task, event }) => {
      if (task.isReported()) {
        markTaskAsNotComplete(task)
      } else {
        markTaskAsComplete({ task, event })
      }
    }

    const toggleTaskPrevented = (task) => {
      const jobId = task.operationRef.account_job_id
      return showIsCorrectShiftDialog(jobId)
    }

    const onImageClick = (task) => {
      const imageUrl = task.operationRef.image_url
      imageCarouselDialog.value.openDialog({ imageUrls: [imageUrl] })
    }

    EventBus.on(NEW_ACCOUNT_OPERATION, onNewOperation)

    EventBus.on(TASK_DELETE_CLICK, onDeleteTask)
    EventBus.on(TASK_COPY_CLICK, onCopyTask)
    EventBus.on(TASK_SHARE_CLICK, onShareTask)
    EventBus.on(TASK_EDIT_CLICK, onEdiTtask)
    EventBus.on(TASK_NOTIFICATION_CLICK, onCreateTaskNotification)
    EventBus.on(TASK_IMAGE_CLICK, onImageClick)
    EventBus.on(TASK_CHAT_CLICK, showChatDialog)
    EventBus.on(TASK_GUIDANCE_IMAGE_CLICK, showTaskGuidanceImages)
    EventBus.on(TASK_TOGGLE_COMPLETE_PREVENTED_CLICK, toggleTaskPrevented)
    EventBus.on(TASK_TOGGLE_COMPLETE_CLICK, toggleTaskComplete)

    onUnmounted(() => {
      EventBus.off(NEW_ACCOUNT_OPERATION, onNewOperation)

      EventBus.off(TASK_DELETE_CLICK, onDeleteTask)
      EventBus.off(TASK_COPY_CLICK, onCopyTask)
      EventBus.off(TASK_SHARE_CLICK, onShareTask)
      EventBus.off(TASK_EDIT_CLICK, onEdiTtask)
      EventBus.off(TASK_NOTIFICATION_CLICK, onCreateTaskNotification)
      EventBus.off(TASK_IMAGE_CLICK, onImageClick)
      EventBus.off(TASK_CHAT_CLICK, showChatDialog)
      EventBus.off(TASK_GUIDANCE_IMAGE_CLICK, showTaskGuidanceImages)
      EventBus.off(TASK_TOGGLE_COMPLETE_PREVENTED_CLICK, toggleTaskPrevented)
      EventBus.off(TASK_TOGGLE_COMPLETE_CLICK, toggleTaskComplete)
    })

    watch(() => props.apiKey, () => {
      apiKeyChanging.value = true
      nextTick(() => (apiKeyChanging.value = false))
    })
    return {
      deleteDialog,
      scheduleNewOperation,
      scheduleNewStaffOperation,
      singleTaskNotification,
      shareDialog,
      imageCarouselDialog,
      chatDialog,
      apiKeyChanging,
      outOfShiftTime,
      reportItemOperationListDialog,
      tasksDisabled,
      clientDateIso,
      createShareContent,
      updateMessageCount,
      markReadAllChatMessages
    }
  }
}
</script>
