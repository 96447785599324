<template>
  <v-dialog
    v-model="dialog" max-width="400" persistent
    content-class="rounded-xl"
  >
    <v-card>
      <v-card-title class="text-center">
        <v-row align="center" justify="center" no-gutters>
          <v-col cols="6" class="text-center">
            <v-img :src="clockImage" height="200" width="200" />
          </v-col>
          <v-col v-if="isPreviousDay" cols="12">
            <span class="t-600-24 color20">
              {{ $t('out_of_shift_action.shift_not_start') }}
            </span>
            <div class="t-400-18 color5">
              {{ $t('out_of_shift_action.whice_shift') }}
            </div>
          </v-col>
          <v-col v-else cols="12">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div class="full-width text-subtitle-1" v-html="title" />
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="popup-card">
        <v-row align="start" justify="center">
          <v-col cols="12">
            <v-btn
              v-if="hidePassShift !== true" depressed
              color="black" block class="mb-3 white--text"
              @click="passShift"
            >
              {{ passShiftText }}
            </v-btn>
            <v-btn
              depressed color="black" class="white--text"
              block @click="closeDialog"
            >
              {{ dismissText }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { isObject } from '@/helpers/Utils.js'
import { translateDayFromDate } from '@/helpers/DateTime.js'

import { i18n } from '@/i18n.js'

import { useRouter } from 'vue-router/composables'

import { computed, ref } from 'vue'

export default {
  setup () {
    const router = useRouter()
    let nextRoute = null
    let resolveFunc = null
    const clockImage = new URL('@/assets/clock.png', import.meta.url).href
    const dialog = ref(false)
    const startTime = ref('')
    const endTime = ref('')
    const isPreviousDay = ref(null)
    const defaultRoute = ref(null)
    const hidePassShift = ref(null)
    const startTimeDate = ref(null)

    // Methods

    const closeDialog = (result) => {
      dialog.value = false
      return resolveFunc(result)
    }
    const passShift = () => {
      if (isObject(nextRoute.route)) {
        router.push(nextRoute.route)
        location.reload()
        return
      }
      return closeDialog({ passShift: true })
    }

    const openDialog = ({ nextRoute: nextRouteVal, startTime: startTimeVal, endTime: endTimeVal, startTimeDate: startTimeDateVal }) => {
      dialog.value = true
      nextRoute = nextRouteVal
      isPreviousDay.value = nextRoute.isPreviousDay
      defaultRoute.value = nextRoute.defaultRoute
      hidePassShift.value = nextRoute.hidePassShift
      startTime.value = startTimeVal
      endTime.value = endTimeVal
      startTimeDate.value = startTimeDateVal
      return new Promise((resolve) => {
        resolveFunc = resolve
      })
    }

    // Computed
    const title = computed(() => {
      if (isPreviousDay.value === true) {
        return i18n.t('out_of_shift_action.title_before_start', { startTime: startTime.value, startDay: translateDayFromDate(startTimeDate.value) })
      }
      return i18n.t('out_of_shift_action.title')
    })

    const passShiftText = computed(() => {
      if (defaultRoute.value === true) {
        return i18n.t('out_of_shift_action.return_work_panel')
      }
      return i18n.t('out_of_shift_action.pass_shift')
    })

    const dismissText = computed(() => {
      if (isPreviousDay.value !== true) return i18n.t('out_of_shift_action.dismiss')

      return `${translateDayFromDate(startTimeDate.value)} ${startTime.value}-${endTime.value}`
    })
    return {
      clockImage,
      dialog,
      startTime,
      endTime,
      isPreviousDay,
      defaultRoute,
      hidePassShift,
      title,
      passShiftText,
      openDialog,
      closeDialog,
      passShift,
      dismissText
    }
  }
}
</script>
