<template>
  <v-row no-gutters class="pt-4">
    <template v-for="({ rowType, value, id }) in chatMessagesWithHeaders">
      <chat-message
        v-if="rowType === rowTypes.message"
        :id="`message-${id}`"
        :key="id"
        :message="value"
        @delete-message="deleteMessage"
      />
      <v-col
        v-else-if="rowType === rowTypes.date" :key="`date_${id}`" cols="12"
        class="text-center date-row"
      >
        <v-chip
          :ripple="false"
          color="white"
          class="t-400-14 "
        >
          {{ value }}
        </v-chip>
      </v-col>
    </template>
  </v-row>
</template>
<script>
import ChatMessage from '@/components/chat_dialog/ChatMessage.vue'

import useAccount from '@/composables/useAccount.js'

import { ISO8601dateToText, ISO8601dateTimeStringToISODate } from '@/helpers/DateTime.js'

import { computed } from 'vue'
const rowTypes = { date: 1, message: 2 }
export default {
  components: {
    'chat-message': ChatMessage
  },
  props: {
    messageList: {
      type: Array,
      required: true
    }
  },
  setup (props, { emit }) {
    // Methods
    const deleteMessage = (message) => emit('delete-message', message)

    // Computed
    const chatMessagesWithHeaders = computed(() => {
      const chatMessaeList = props.messageList
      if (chatMessaeList.length === 0) return chatMessaeList

      const result = []

      const { accountCountryCode } = useAccount()
      const countryCode = accountCountryCode.value

      let currentDate = ISO8601dateTimeStringToISODate(chatMessaeList[0].created_at_date_raw)
      let currentDateFormmated = ISO8601dateToText({ fromDate: currentDate, countryCode })
      result.push({ rowType: rowTypes.date, value: currentDateFormmated, id: currentDate })
      chatMessaeList.forEach((message) => {
        const isoDate = ISO8601dateTimeStringToISODate(message.created_at_date_raw)
        if (isoDate !== currentDate) {
          currentDate = isoDate
          currentDateFormmated = ISO8601dateToText({ fromDate: currentDate, countryCode })
          result.push({ rowType: rowTypes.date, value: currentDateFormmated, id: currentDate })
        }
        result.push({ rowType: rowTypes.message, value: message, id: message.id })
      })

      return result
    })
    return {
      rowTypes,
      chatMessagesWithHeaders,
      deleteMessage
    }
  }
}
</script>
