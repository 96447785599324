import { isString, isObject } from '@/helpers/Utils.js'
import { isValidISO8601Date, ISO8601StringToDate } from '@/helpers/DateTime.js'

import { EventBus, HQ_TASKS_FILTER_CHANGED, TASKS_FILTER_CHANGED } from '@/EventBus.js'

import { ref, computed } from 'vue'

const SESSION_FILTER_KEY = 'account-hq-tasks-filters-session'
const taskModes = {
  accounts: 'accounts',
  crossAccount: 'crossAccount',
  singleAccount: 'singleAccount'
}

const defaultTaskMode = taskModes.accounts

const defaultFilters = () => ({
  taskMode: defaultTaskMode,
  companyDistricts: [],
  accounts: [],
  status: '',
  tags: [],
  myTasks: false,
  alertedTasks: false,
  accountJobs: [],
  accountDepartmentIds: [],
  crossAccountTasks: false
})

const lastAppliedTaskMode = ref('all')
const filters = ref(defaultFilters())
const clientDate = ref(new Date())
const lastAppliedApiKeyForSingleAccount = ref(null)

let currentApiKey = null

const getSavedFilterFromSession = () => {
  const filter = localStorage.getItem(SESSION_FILTER_KEY)
  if (isString(filter) !== true) return
  try {
    return JSON.parse(filter)
  } catch (e) {
  }
}

const loadFiltersFromSession = () => {
  const filtersFromSession = getSavedFilterFromSession()
  if (!isObject(filtersFromSession)) return

  const defaultFilter = defaultFilters()
  for (const key in defaultFilter) {
    if (key in filtersFromSession) {
      filters.value[key] = filtersFromSession[key]
    }
  }
  if (!('taskMode' in filtersFromSession)) return

  if (filtersFromSession.taskMode === taskModes.singleAccount && filtersFromSession.accounts.length === 1) {
    lastAppliedTaskMode.value = filtersFromSession.taskMode
    lastAppliedApiKeyForSingleAccount.value = filtersFromSession.accounts[0]
  } else if (filtersFromSession.taskMode !== taskModes.singleAccount) {
    lastAppliedTaskMode.value = filtersFromSession.taskMode
  }
}

export default function useHQTasksFilter (apiKey) {
  const resetValues = () => {
    filters.value = defaultFilters()
    lastAppliedTaskMode.value = defaultTaskMode
    clientDate.value = new Date()
  }

  if (isString(apiKey) && currentApiKey !== apiKey) {
    resetValues()

    currentApiKey = apiKey
  }

  const buildFiltersForApi = () => {
    const result = { filters: {} }
    const filtersVal = filters.value
    if (filtersVal.companyDistricts.length > 0) {
      result.company_districts_ids = filtersVal.companyDistricts
    }
    if (filtersVal.accounts.length > 0 && !taskModeCrossAccount.value) {
      result.api_keys = filtersVal.accounts
    }
    if (filtersVal.myTasks === true) {
      result.filters.my_operations = true
    }
    if (taskModeCrossAccount.value) {
      if (filtersVal.accounts.length > 0) {
        result.filters.account_api_keys = filtersVal.accounts
      }
    } else {
      if (isString(filtersVal.status) && filtersVal.status.length > 0) {
        result.filters.status = filtersVal.status
      }
      if (filtersVal.tags.length > 0) {
        result.filters.report_tags = filtersVal.tags
      }
      if (filtersVal.alertedTasks === true) {
        result.filters.my_alerted_operations = true
      }
      if (filtersVal.crossAccountTasks === true) {
        result.filters.cross_account_operations = true
      }
      if (filtersVal.accountJobs.length > 0) {
        result.filters.account_job_ids = filtersVal.accountJobs.map((job) => job.id)
      }
      if (filtersVal.accountDepartmentIds.length > 0) {
        result.filters.account_department_ids = filtersVal.accountDepartmentIds
      }
    }

    return JSON.parse(JSON.stringify(result))
  }

  const resetFilters = () => {
    filters.value = defaultFilters()
    applyFilters()
  }

  const applyFilters = () => {
    let apiKeyChanged = false

    if (filters.value.taskMode === taskModes.singleAccount) {
      const newApiKey = filters.value.accounts[0]
      if (lastAppliedTaskMode.value === taskModes.singleAccount && lastAppliedApiKeyForSingleAccount.value !== newApiKey) {
        apiKeyChanged = true
      }
      lastAppliedApiKeyForSingleAccount.value = newApiKey
    }
    lastAppliedTaskMode.value = filters.value.taskMode
    saveFilter()

    if (taskModeSingleAccount.value) {
      if (apiKeyChanged) return
      EventBus.emit(TASKS_FILTER_CHANGED, buildFiltersForApi())
    } else {
      EventBus.emit(HQ_TASKS_FILTER_CHANGED)
    }
  }

  const changeDate = async (newDate) => {
    clientDate.value = newDate
    applyFilters()
  }

  const saveFilter = () => localStorage.setItem(SESSION_FILTER_KEY, JSON.stringify(filters.value))

  const loadFilters = ({ clientDate: clientDateVal, apiKey: apiKeyVal = null, taskMode: taskModeVal = null } = {}) => {
    filters.value = defaultFilters()
    if (clientDateVal !== null) {
      clientDate.value = clientDateVal
    }
    if (apiKeyVal === null && taskModeVal === null) {
      loadFiltersFromSession()
      return
    }

    filters.value = defaultFilters()
    if (isString(apiKeyVal)) {
      filters.value.accounts = [apiKeyVal]
      lastAppliedApiKeyForSingleAccount.value = apiKeyVal
    }
    if (isString(taskModeVal) && Object.values(taskModes).includes(taskModeVal)) {
      filters.value.taskMode = taskModeVal
      lastAppliedTaskMode.value = taskModeVal
    }
    if (isValidISO8601Date(clientDateVal)) {
      clientDate.value = ISO8601StringToDate(clientDateVal)
    }
  }

  // Computed

  const taskModeCrossAccount = computed(() => lastAppliedTaskMode.value === taskModes.crossAccount)
  const taskModeAccounts = computed(() => lastAppliedTaskMode.value === taskModes.accounts)
  const taskModeSingleAccount = computed(() => lastAppliedTaskMode.value === taskModes.singleAccount)
  const clientDateComp = computed(() => clientDate.value)

  const anyFilterSelected = computed(() => {
    const filtersVal = filters.value
    if (filtersVal.companyDistricts.length > 0 ||
      filtersVal.myTasks === true ||
      filtersVal.accounts.length > 0) return true

    if (taskModeCrossAccount.value) return false

    return (isString(filtersVal.status) && filtersVal.status.length > 0) ||
      filtersVal.tags.length > 0 ||
      filtersVal.alertedTasks === true ||
      filtersVal.accountJobs.length > 0
  })
  return {
    taskModes,
    filters,
    taskModeCrossAccount,
    taskModeAccounts,
    taskModeSingleAccount,
    lastAppliedApiKeyForSingleAccount,
    anyFilterSelected,
    clientDate: clientDateComp,
    loadFilters,
    changeDate,
    buildFiltersForApi,
    resetFilters,
    applyFilters
  }
}
